import React from 'react';
import classNames from 'classnames';
import { LayoutItem } from '@core/triggers/service';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import Button from '@components/UIkit/Button';
import styles from './styles.module.scss';

interface ILinkInBio {
  layout: LayoutItem[];
  className?: string;
}

const { Text, Paragraph } = Typography;

const BioImage: React.FC<{ type: string; image_url: string }> = ({
  image_url,
}) => {
  return (
    <div
      className={styles.image}
      style={{ backgroundImage: `url(${image_url})` }}
    />
  );
};

const BioHeader: React.FC<{ type: string; text: string }> = ({ text }) => {
  return (
    <Paragraph size={2} className={styles.title}>
      {text}
    </Paragraph>
  );
};

const BioText: React.FC<{ type: string; text: string }> = ({ text }) => {
  return (
    <Text size={2} className={styles.text}>
      {text}
    </Text>
  );
};

const BioButton: React.FC<{
  index: number;
  type: string;
  text: string;
  url: string;
  image_url?: string;
}> = ({ index, text, url, image_url }) => {
  return (
    <Button
      id={`button-link-in-bio-${index}`}
      target
      type="default"
      to={url}
      size="large"
      className={styles.button}
    >
      {image_url && (
        <div className={styles.buttonImage}>
          <img src={image_url} />
        </div>
      )}

      {text}
    </Button>
  );
};

const LinkInBio: React.FC<ILinkInBio> = ({ layout, className }) => {
  return (
    <Space
      fullWidth
      direction="vertical"
      justify="middle"
      align="middle"
      gap={20}
      className={classNames(styles.wrapper, className)}
    >
      {layout &&
        layout.map((item, index) => {
          if (item.type === 'image') {
            return <BioImage {...item} key={index} />;
          }

          if (item.type === 'header') {
            return <BioHeader {...item} key={index} />;
          }

          if (item.type === 'text') {
            return <BioText {...item} key={index} />;
          }

          if (item.type === 'link-button') {
            return <BioButton {...item} key={index} index={index} />;
          }
        })}

      <Text size={3} className={styles.footer}>
        Powered by{' '}
        <a href="/" target="_blank" className={styles.footerLink}>
          Murl.gg
        </a>
      </Text>
    </Space>
  );
};

export default LinkInBio;
