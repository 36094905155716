import instance from '@core/api';

export type TScenarioType =
  | 'not-active'
  | 'free-open-url'
  | 'open-url'
  | 'free-link-in-bio'
  | 'link-in-bio'
  | 'file-share'
  | 'video-player';

export type TTriggerType = 'ShortLink' | 'QrCode';

export type LayoutItem =
  | { type: 'image'; image_url: string }
  | { type: 'header'; text: string }
  | { type: 'text'; text: string }
  | { type: 'link-button'; text: string; url: string; image_url?: string };

export type ITriggerCommonPayload = {
  type: TScenarioType;
  original_url?: string;
  layout?: LayoutItem[];
};

export interface ITriggerCommonRequest {
  is_private_request: boolean;
  trigger_type: TTriggerType;
  trigger_name: string;
  payload: ITriggerCommonPayload;
}

export interface ITriggerInterface {
  id: string;
  type: 'qr-code' | 'short-url';
  key: string;
  name: string;
  created_at: number;
  updated_at: number;
  qr_code_data: string;
  short_key: string;
  scenario_payload: ITriggerCommonPayload;
  interaction_count: number;
}

// Upload file

export interface IUploadFileRequest {
  fileType: 'images' | 'videos';
  file: File;
}

export interface IUploadFileResponse {
  fileId: string;
}

export async function fetchUploadFile(
  payload: IUploadFileRequest
): Promise<IUploadFileResponse> {
  const formData = new FormData();
  formData.append('file', payload.file);

  const response = await instance.post<string>(
    `public-file/${payload.fileType}/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
      timeout: 15000,
    }
  );

  return {
    fileId: response.data,
  };
}

// Create trigger

export type ICreateTriggerRequest = ITriggerCommonRequest;

export interface ICreateTriggerResponse {
  payload: {
    trigger_id?: string;
    short_link?: string;
    qr_code_data?: string;
    type?: string;
  };
}

export async function fetchCreateTrigger(
  payload: ICreateTriggerRequest
): Promise<ICreateTriggerResponse> {
  const { is_private_request, trigger_type, ..._payload } = payload;

  return await instance.post(
    is_private_request
      ? `trigger/create/${trigger_type}`
      : `trigger/create-free/${trigger_type}`,
    _payload
  );
}

// Update trigger

export interface IUpdateTriggerRequest extends ITriggerCommonRequest {
  id: string;
}

export type IUpdateTriggerResponse = ICreateTriggerResponse;

export async function fetchUpdateTrigger(
  payload: IUpdateTriggerRequest
): Promise<IUpdateTriggerResponse> {
  return await instance.post(`trigger/update-scenario/${payload.id}`, payload);
}

// Delete trigger

export interface IDeleteTriggerRequest {
  id: string;
}

export type IDeleteTriggerResponse = string;

export async function fetchDeleteTrigger(
  payload: IDeleteTriggerRequest
): Promise<IDeleteTriggerResponse> {
  return await instance.delete(`trigger/delete/${payload.id}`);
}

// Get Triggers All

export type IFetchGetTriggersAllResponse = ITriggerInterface[];

export async function fetchGetTriggersAll(): Promise<IFetchGetTriggersAllResponse> {
  return await instance.get(`trigger/all`);
}

// Get Trigger by Id

export interface IFetchGetTriggerByIdRequest {
  id: string;
}

export type IFetchGetTriggerByIdResponse = ITriggerInterface;

export async function fetchGetTriggerById(
  payload: IFetchGetTriggerByIdRequest
): Promise<IFetchGetTriggerByIdResponse> {
  return await instance.get(`trigger/${payload.id}`);
}
