import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Icon from '@components/UIkit/Icon';
import { TModalSize } from '@common/types';
import styles from './styles.module.scss';

interface ModalProps {
  children: ReactNode;
  size: TModalSize;
  onClose: () => void;
}

const popup = document.getElementById('popup');

const ModalPortal: React.FC<ModalProps> = ({ children, size, onClose }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const onCloseModal = useCallback(() => {
    setIsActive(false);
    setTimeout(onClose, 300);
  }, [onClose]);

  useEffect(() => {
    setIsActive(true);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onCloseModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCloseModal]);

  useEffect(() => {
    setIsActive(true);
  }, []);

  if (popup) {
    return ReactDOM.createPortal(
      <div
        className={classNames(styles.wrapper, { [styles.active]: isActive })}
      >
        <div
          ref={modalRef}
          className={classNames(styles.modal, styles[size], {
            [styles.active]: isActive,
          })}
        >
          <Icon
            id="icon-close-modal"
            size="small"
            className={styles.closeIcon}
            name="Close"
            onClick={onCloseModal}
          />

          {children}
        </div>
      </div>,
      popup
    );
  }

  return null;
};

export default React.memo(ModalPortal);
