import React from 'react';
import classNames from 'classnames';
import Space from '@UIkit/Space';
import Typography from '@UIkit/Typography';
import styles from './switchbutton.module.scss';

interface ISwitchButton {
  id: string;
  disabled?: boolean;
  value: boolean;
  text?: string;
  align?: 'right' | 'left';
  onChange: (value: boolean) => void;
}

const { Text } = Typography;

const SwitchButton: React.FC<ISwitchButton> = ({
  id,
  disabled = false,
  value,
  align = 'right',
  text,
  onChange,
}) => {
  return (
    <Space
      className={classNames(styles.switchGroup, {
        [styles.disabled]: disabled,
      })}
      align="middle"
      gap={10}
    >
      {align === 'left' && (
        <label className={styles.switch}>
          <input
            id={id}
            type="checkbox"
            checked={value}
            onChange={(e) => onChange(e.currentTarget.checked)}
          />
          <span className={styles.slider}></span>
        </label>
      )}

      {text && (
        <Text size={2} font="Raleway">
          {text}
        </Text>
      )}

      {align === 'right' && (
        <label className={styles.switch}>
          <input
            id={id}
            type="checkbox"
            checked={value}
            onChange={(e) => onChange(e.currentTarget.checked)}
          />
          <span className={styles.slider}></span>
        </label>
      )}
    </Space>
  );
};

export default React.memo(SwitchButton);
