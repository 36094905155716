import React from 'react';
import { Element } from 'react-scroll';
import Headline from '@components/Headline';
import { IFAQ } from '@common/types';
import FAQItem from '@components/FAQItem';
import Space from '@components/UIkit/Space';
import styles from './styles.module.scss';

const FAQdata: IFAQ[] = [
  {
    title: 'What is Murl.gg?',
    text: (
      <>
        Murl.gg is an innovative platform for creating dynamic QR codes with
        limitless possibilities.
      </>
    ),
  },
  {
    title: 'How can I use Murl.gg?',
    text: (
      <>
        Our service allows users to easily and quickly create QR codes that can
        be adapted to various needs.
      </>
    ),
  },
  {
    title: 'What is the length of the short link?',
    text: (
      <>
        The default length of the short link is 7 characters. When choosing a
        tariff plan, starting from Regular, users get the privilege to use short
        links with a length of only 6 characters. This allows for creating more
        compact and memorable links to enhance your experience using our
        service.
      </>
    ),
  },
  {
    title: 'How do I get started with Murl.gg?',
    text: (
      <>
        Start with the free tariff and discover the world of possibilities
        offered by Murl.gg.
      </>
    ),
  },
  {
    title: 'What features are available on paid tariffs?',
    text: (
      <>
        After choosing the optimal tariff plan, you can customize the actions of
        the QR code according to your preferences: from simple link redirection
        to file downloads and opening payment pages.
      </>
    ),
  },
  {
    title: "What's included in paid tariffs?",
    text: (
      <>
        Paid tariffs offer additional features like analytics and click-through
        statistics.
      </>
    ),
  },
  {
    title: 'How can I contact support?',
    text: (
      <>
        If you have any questions or issues, please contact our support team at
        {
          <>
            <a
              href="mailto:support@murl.gg?subject=Support Murl.gg"
              target="_blank"
            >
              support@murl.gg
            </a>
            .
            <br />
            <br />
          </>
        }
        We also have a Telegram channel where you can ask questions:
        {
          <a href="https://t.me/murl_gg" target="_blank">
            https://t.me/murl_gg
          </a>
        }
        .
      </>
    ),
  },
];

const FAQ: React.FC = () => {
  return (
    <Element name="homeFAQ">
      <div className={styles.faq}>
        <Headline
          className={styles.title}
          title="Frequently asked question"
          description="Explore common queries about Murl and its benefits"
          align="left"
        />

        <Space direction="vertical" className={styles.body} gap={20}>
          {FAQdata.map((f, index) => (
            <FAQItem data={f} key={f.title + index} />
          ))}
        </Space>
      </div>
    </Element>
  );
};

export default React.memo(FAQ);
