import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { IPlan } from '@common/types';
import ModalSubscriptionLimitation from '@components/Modals/ModalSubscriptionLimitation';
import { useModal } from '@hooks/useModal';
import {
  fetchBuyFreeSubscriptionStart,
  fetchBuySubscriptionStart,
} from '@core/payment/actionCreators';
import Button from '@components/UIkit/Button';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import ModalConfirm from '@components/Modals/ModalConfirm';
import List from '@components/UIkit/List';
import styles from './shortenplan.module.scss';

interface IShortenPlan {
  data: IPlan;
  myTriggersLimit: number;
  isCurrentTier;
}

const { Paragraph, Text } = Typography;

const ShortenPlan: React.FC<IShortenPlan> = ({
  data,
  myTriggersLimit,
  isCurrentTier,
}) => {
  const { showModal } = useModal();
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const childRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = () => {
    setActive(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
  };

  const onPlanSelect = useCallback(() => {
    if (myTriggersLimit > data.triggers) {
      showModal(<ModalSubscriptionLimitation />, 'medium');
    } else {
      if (data.id === 'Free') {
        showModal(
          <ModalConfirm
            message="Do you really want to switch to the free subscription?"
            onSuccess={() => dispatch(fetchBuyFreeSubscriptionStart())}
          />,
          'small'
        );
      } else {
        dispatch(
          fetchBuySubscriptionStart(
            { months: 1, tier: data?.id || 'Free' },
            (pay_page) => window.location.replace(pay_page)
          )
        );
      }
    }
  }, [dispatch, myTriggersLimit]);

  useEffect(() => {
    const element = childRef.current;

    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  return (
    <Space
      direction="vertical"
      ref={childRef}
      gap={40}
      className={classNames(styles.plan, {
        [styles.active]: active,
      })}
    >
      <Space direction="vertical" gap={5}>
        <Paragraph>{data.name}</Paragraph>

        <Text size={2} className={styles.description}>
          {data.description}
        </Text>
      </Space>

      <List
        wrapperClassName={styles.list}
        items={data.props.slice(0, -3)}
        icon="Eclipse"
        iconColor="#BDBDBD"
      />

      <Button
        id={`button-shorten-plan-${data.name.toLowerCase()}-select`}
        onClick={() => onPlanSelect()}
        disabled={data.id === 'Free' && isCurrentTier}
      >
        {data.action}
      </Button>

      <Space align="start" gap={10} className={styles.price}>
        <Paragraph size={2} className={styles.currency}>
          $
        </Paragraph>
        <Text font="Inter" className={styles.text}>
          {data.price}
        </Text>
      </Space>
    </Space>
  );
};

export default ShortenPlan;
