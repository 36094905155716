import React, { useMemo } from 'react';
import classNames from 'classnames';
import LineChart from '@components/LineChart';
import DonutChart from '@components/DonutChart';
import { GetInteractionResponse } from '@core/interaction/service';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import Nodata from '../Nodata';
import styles from '../../dashboard.module.scss';

const { Text } = Typography;

interface ChartsProps {
  className?: string;
  interactionData: GetInteractionResponse;
  isQRCode: boolean;
}

const { Paragraph } = Typography;

const Charts: React.FC<ChartsProps> = ({
  className,
  interactionData,
  isQRCode = true,
}) => {
  const totalScansData = useMemo(() => {
    const keysData = Object.keys(interactionData?.total);
    const currentTimeStamp = new Date().getTime();

    return keysData
      .map((key, index) => {
        const datetime = new Date(
          currentTimeStamp - index * 24 * 60 * 60 * 1000
        );

        const formattedDate = datetime.toLocaleDateString(undefined, {
          day: '2-digit',
          month: '2-digit',
        });

        return {
          name: formattedDate,
          value: `${interactionData?.total[key as unknown as number]}`,
        };
      })
      .reverse();
  }, [interactionData]);

  const totalUniqueScansData = useMemo(() => {
    const keysData = Object.keys(interactionData?.uniq_devices);
    const currentTimeStamp = new Date().getTime();

    return keysData
      .map((key, index) => {
        const datetime = new Date(
          currentTimeStamp - index * 24 * 60 * 60 * 1000
        );

        const formattedDate = datetime.toLocaleDateString(undefined, {
          day: '2-digit',
          month: '2-digit',
        });

        return {
          name: formattedDate,
          value: `${interactionData?.uniq_devices[key as unknown as number]}`,
        };
      })
      .reverse();
  }, [interactionData]);

  const totalUniqueIPScansData = useMemo(() => {
    const keysData = Object.keys(interactionData?.uniq_ip_address);
    const currentTimeStamp = new Date().getTime();

    return keysData
      .map((key, index) => {
        const datetime = new Date(
          currentTimeStamp - index * 24 * 60 * 60 * 1000
        );

        const formattedDate = datetime.toLocaleDateString(undefined, {
          day: '2-digit',
          month: '2-digit',
        });

        return {
          name: formattedDate,
          value: `${
            interactionData?.uniq_ip_address[key as unknown as number]
          }`,
        };
      })
      .reverse();
  }, [interactionData]);

  const totalCountrieScansData = useMemo(() => {
    const keysData = Object.keys(interactionData?.country);

    return keysData
      .map((key) => {
        return {
          name: key,
          value: interactionData?.country[key as unknown as number],
        };
      })
      .reverse();
  }, [interactionData]);

  const totalOSScansData = useMemo(() => {
    const keysData = Object.keys(interactionData?.os_name);

    return keysData
      .map((key) => {
        return {
          name: key,
          value: interactionData?.os_name[key as unknown as number],
        };
      })
      .reverse();
  }, [interactionData]);

  const totalBrowsersScansData = useMemo(() => {
    const keysData = Object.keys(interactionData?.browser);

    return keysData
      .map((key) => {
        return {
          name: key,
          value: interactionData?.browser[key as unknown as number],
        };
      })
      .reverse();
  }, [interactionData]);

  return (
    <div className={classNames(styles.charts, className)}>
      <Space
        className={classNames(styles.row, styles.mobileReverse)}
        justify="between"
        gap={40}
        fullWidth
      >
        <Space className={styles.col} direction="vertical" gap={20} fullWidth>
          <Paragraph size={2}>{isQRCode ? 'Scans' : 'Visits'}</Paragraph>

          <Space className={styles.chart} direction="vertical" fullWidth>
            {totalScansData.length ? (
              <LineChart
                color="#00ff75"
                data={totalScansData}
                tooltipName={isQRCode ? 'Scans' : 'Visits'}
              />
            ) : (
              <Nodata />
            )}
          </Space>
        </Space>

        <Space
          className={classNames(styles.col, styles['col-3'])}
          direction="vertical"
          gap={20}
          fullWidth
        >
          <Paragraph size={2}>Statistic</Paragraph>

          <Space className={styles.chart} fullWidth>
            <Space className={styles.statistic} direction="vertical" fullWidth>
              {Object.entries({
                Interactions: interactionData.total_interactions,
                'unique browsers': interactionData.total_uniq_browser,
                'unique countries': interactionData.total_uniq_countries,
                'unique devices': interactionData.total_uniq_devices,
                'unique IPs': interactionData.total_uniq_ip,
                'unique OS': interactionData.total_uniq_os,
              }).map(([key, value]) => (
                <Space
                  className={styles.statistic_row}
                  justify="between"
                  fullWidth
                  key={key}
                >
                  <Text size={2} className={styles.statistic_key}>
                    Total {key}
                  </Text>
                  <Text font="Inter" size={2}>
                    {value}
                  </Text>
                </Space>
              ))}
            </Space>
          </Space>
        </Space>
      </Space>

      <Space className={styles.row} justify="between" gap={40} fullWidth>
        <Space className={styles.col} direction="vertical" gap={20} fullWidth>
          <Paragraph size={2}>Unique IP addressess</Paragraph>

          <Space className={styles.chart} direction="vertical" fullWidth>
            {totalUniqueIPScansData.length ? (
              <LineChart
                color="#ff6b00"
                data={totalUniqueIPScansData}
                tooltipName={isQRCode ? 'Scans' : 'Visits'}
              />
            ) : (
              <Nodata />
            )}
          </Space>
        </Space>

        <Space className={styles.col} direction="vertical" gap={20} fullWidth>
          <Paragraph size={2}>Unique clients</Paragraph>

          <Space className={styles.chart} direction="vertical" fullWidth>
            {totalUniqueScansData.length ? (
              <LineChart
                color="#9747ff"
                data={totalUniqueScansData}
                tooltipName={isQRCode ? 'Scans' : 'Visits'}
              />
            ) : (
              <Nodata />
            )}
          </Space>
        </Space>
      </Space>

      <Space className={styles.row} justify="between" gap={40} fullWidth>
        <Space
          className={classNames(styles.col, styles['col-3'])}
          direction="vertical"
          gap={20}
          fullWidth
        >
          <Paragraph size={2}>
            {isQRCode ? 'Scans' : 'Visits'} by countries
          </Paragraph>

          <Space className={styles.chart} direction="vertical" fullWidth>
            {totalCountrieScansData.length ? (
              <DonutChart data={totalCountrieScansData} />
            ) : (
              <Nodata />
            )}
          </Space>
        </Space>

        <Space
          className={classNames(styles.col, styles['col-3'])}
          direction="vertical"
          gap={20}
          fullWidth
        >
          <Paragraph size={2}>
            {isQRCode ? 'Scans' : 'Visits'} by operating system
          </Paragraph>

          <Space className={styles.chart} direction="vertical" fullWidth>
            {totalOSScansData.length ? (
              <DonutChart data={totalOSScansData} />
            ) : (
              <Nodata />
            )}
          </Space>
        </Space>

        <Space
          className={classNames(styles.col, styles['col-3'])}
          direction="vertical"
          gap={20}
          fullWidth
        >
          <Paragraph size={2}>
            {isQRCode ? 'Scans' : 'Visits'} by browsers
          </Paragraph>

          <Space className={styles.chart} direction="vertical" fullWidth>
            {totalBrowsersScansData.length ? (
              <DonutChart data={totalBrowsersScansData} />
            ) : (
              <Nodata />
            )}
          </Space>
        </Space>
      </Space>
    </div>
  );
};

export default React.memo(Charts);
