import { createTransform, Transform } from 'redux-persist';
import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = 'murl-encrypt-secret-key';

const encryptor: Transform<unknown, string> = createTransform(
  (inboundState) => {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(inboundState),
      ENCRYPTION_KEY
    ).toString();
    return ciphertext;
  },

  (outboundState) => {
    const bytes = CryptoJS.AES.decrypt(outboundState, ENCRYPTION_KEY);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(plaintext);
  }
);

const encrypt = (text: string): string => {
  const encrypted = CryptoJS.AES.encrypt(text, ENCRYPTION_KEY).toString();
  return encodeURIComponent(encrypted) || '';
};

const decrypt = (encryptedText: string): string => {
  const decrypted = decodeURIComponent(encryptedText);
  const bytes = CryptoJS.AES.decrypt(decrypted, ENCRYPTION_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export { encryptor, encrypt, decrypt };
