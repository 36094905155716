import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface IPhoneWrapper {
  children: ReactNode;
}

const PhoneWrapper: React.FC<IPhoneWrapper> = ({ children }) => {
  return (
    <div className={styles.phoneWrapper}>
      <div className={styles.phoneScreen}>
        <div className={styles.phoneHeader}></div>

        <div className={styles.phoneLayout}>{children}</div>
      </div>
    </div>
  );
};

export default PhoneWrapper;
