import React from 'react';
import routing from '@routing/path';
import Headline from '@components/Headline';
import { NavbarProvider, useNavbar } from '@hooks/useNavBar';
import Container from '@components/UIkit/Container';
import Space from '@components/UIkit/Space';
import { TNavItem } from '@common/types';
import TriggerForm from '@containers/TriggerCreate/components/TriggerForm';
import { TTriggerType } from '@core/triggers/service';

interface ITriggerCreateProps {
  isEdit?: boolean;
  trigger_type: TTriggerType;
}

const TriggerCreateContainer: React.FC<ITriggerCreateProps> = ({
  isEdit,
  trigger_type,
}) => {
  const navItems: TNavItem[] = [
    {
      icon: 'Link',
      text: 'Short link',
      to: `${routing.public.create.root}/${routing.public.create.children.link}`,
    },
    {
      icon: 'QRCode',
      text: 'QR Code',
      to: `${routing.public.create.root}/${routing.public.create.children.qrCode}`,
    },
  ];

  return (
    <NavbarProvider id="navbar-create-trigger" items={navItems} hidden={isEdit}>
      <TriggerCreate trigger_type={trigger_type} isEdit={isEdit} />
    </NavbarProvider>
  );
};

const TriggerCreate: React.FC<ITriggerCreateProps> = ({
  trigger_type,
  isEdit,
}) => {
  const { NavBarNode } = useNavbar();

  return (
    <Container>
      <Space direction="vertical" gap={40} align="middle">
        <Headline
          title={
            trigger_type === 'QrCode'
              ? 'Create your QR right now!'
              : 'Shorten your link right now!'
          }
          description="Quick and easy: make your links more convenient!"
        />

        <Space direction="vertical" fullWidth>
          {NavBarNode}

          <TriggerForm trigger_type={trigger_type} isEdit={isEdit} />
        </Space>
      </Space>
    </Container>
  );
};

export default React.memo(TriggerCreateContainer);
