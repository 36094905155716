import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { copyLinkToClipboard, formatDateTime } from '@common/helpers';
import useQRCode from '@components/QRCode';
import MurlSuccess from '@assets/images/RedirectPages/MurlSuccess.svg';
import { ITriggerInterface, TScenarioType } from '@core/triggers/service';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import Icon from '@components/UIkit/Icon';
import { useModal } from '@hooks/useModal';
import routing from '@routing/path';
import ModalDelete from '@components/Modals/ModalDelete';
import { encrypt } from '@core/encryptor';
import { DynamicIconsColors } from '@common/types';
import styles from './styles.module.scss';

interface IDescription {
  name: TScenarioType;
  text: string;
  color: DynamicIconsColors;
}

interface ICollectionCardFull {
  data: ITriggerInterface;
}

const { Paragraph, Text } = Typography;

const CollectionCardFull: React.FC<ICollectionCardFull> = ({ data }) => {
  const { QRCode } = useQRCode(data.qr_code_data);

  const navigate = useNavigate();

  const { showModal } = useModal();

  const description = useMemo<IDescription>(() => {
    const typeMappings: Record<TScenarioType, IDescription> = {
      'not-active': {
        name: 'not-active',
        text: 'Not active',
        color: '#BDBDBD',
      },
      'free-open-url': {
        name: 'free-open-url',
        text: 'Redirect to url with Ad',
        color: '#00FF75',
      },
      'open-url': {
        name: 'open-url',
        text: 'Redirect to url',
        color: '#00FF75',
      },
      'free-link-in-bio': {
        name: 'link-in-bio',
        text: 'Link in Bio',
        color: '#8F6CF3',
      },
      'link-in-bio': {
        name: 'link-in-bio',
        text: 'Link in Bio',
        color: '#8F6CF3',
      },
      'file-share': {
        name: 'file-share',
        text: 'Redirect to downloading file',
        color: '#FF6B00',
      },
      'video-player': {
        name: 'video-player',
        text: 'Page with video player',
        color: '#9747FF',
      },
    };

    const defaultMapping: IDescription = {
      name: 'not-active',
      text: 'Not active',
      color: '#BDBDBD',
    };

    return typeMappings[data.scenario_payload.type] || defaultMapping;
  }, [data.scenario_payload]);

  const onEdit = useCallback(() => {
    const key = data.type === 'short-url' ? 'link' : 'qrCode';

    navigate(
      `${routing.private.edit.root}/${routing.private.edit.children[key]}/${data.id}`
    );
  }, [navigate, data.id]);

  const onDelete = useCallback(() => {
    showModal(<ModalDelete id={data.id} type={data.type} />, 'medium');
  }, [data.id]);

  const onDownload = useCallback(() => {
    navigate(
      `${routing.public.generator}/${encrypt(
        `${document.location.origin}/${data.qr_code_data}` || ''
      )}`
    );
  }, [navigate, data.qr_code_data]);

  return (
    <>
      <Space
        className={classNames(
          styles.collectionCard,
          styles[data.scenario_payload.type]
        )}
        fullWidth
        gap={20}
        align="normal"
      >
        <Space
          justify="between"
          align="middle"
          className={styles.trigger_head}
          hiddenOnDesktop
          fullWidth
        >
          <Paragraph size={1} className={styles.trigger_name}>
            {data.name}
          </Paragraph>

          <Space hiddenOnDesktop>
            <Icon
              id="icon-cardfull-edit"
              size="small"
              name="Edit"
              color={description.color}
              className={styles.trigger_actions_btn}
              onClick={onEdit}
            />

            {data.type === 'qr-code' && (
              <Icon
                id="icon-cardfull-download"
                size="small"
                name="Download"
                color={description.color}
                className={styles.trigger_actions_btn}
                onClick={onDownload}
              />
            )}

            <Icon
              id="icon-cardfull-delete"
              size="small"
              name="Trash"
              color={description.color}
              className={styles.trigger_actions_btn}
              onClick={onDelete}
            />
          </Space>
        </Space>

        <Space direction="vertical">
          <div className={styles.image}>
            {data.type === 'qr-code' ? (
              QRCode
            ) : (
              <img src={MurlSuccess} alt="MurlSuccess" />
            )}
          </div>
        </Space>

        <Space
          direction="vertical"
          justify="start"
          className={styles.trigger_details}
          hiddenOnMobile
        >
          <Paragraph size={1} className={styles.trigger_name}>
            {data.name}
          </Paragraph>

          <Paragraph size={2} className={styles.trigger_description}>
            {description.name}
          </Paragraph>

          <Space align="middle" gap={10}>
            <Icon size="medium" name="ArrowSubDown" color={description.color} />

            <Text size={2} className={styles.trigger_type}>
              {description.text}
            </Text>
          </Space>
        </Space>

        <Space
          direction="vertical"
          className={styles.trigger_details}
          justify="end"
          hiddenOnMobile
        >
          <Space align="middle" gap={10}>
            <Icon size="medium" name="Calendar" color="#BDBDBD" />

            <Text size={2} className={styles.trigger_date}>
              Created at: {formatDateTime(data.created_at)}
            </Text>
          </Space>

          <Space align="middle" gap={10}>
            <Icon size="medium" name="Calendar" color="#BDBDBD" />

            <Text size={2} className={styles.trigger_date}>
              Updated at: {formatDateTime(data.updated_at)}
            </Text>
          </Space>

          {(data.short_key || data.qr_code_data) && (
            <Space align="middle" gap={10}>
              <Icon
                id="icon-cardfull-copy"
                size="medium"
                name="Copy"
                color={description.color}
                onClick={() =>
                  copyLinkToClipboard(
                    `${document.location.origin}/${
                      data.short_key || data.qr_code_data
                    }`
                  )
                }
              />

              <Text size={2}>
                {document.location.origin}/{data.short_key || data.qr_code_data}
              </Text>
            </Space>
          )}

          {data.scenario_payload.original_url && (
            <Space align="middle" gap={10}>
              <Icon
                id="icon-cardfull-copy-full"
                size="medium"
                name="Copy"
                color={description.color}
                onClick={() =>
                  copyLinkToClipboard(data.scenario_payload.original_url || '')
                }
              />

              <Text size={2}>{data.scenario_payload.original_url}</Text>
            </Space>
          )}
        </Space>

        <Space
          direction="vertical"
          justify="start"
          className={styles.trigger_details}
          hiddenOnDesktop
        >
          <Paragraph size={2} className={styles.trigger_description}>
            {description.name}
          </Paragraph>

          <Space align="middle" gap={10}>
            <Icon size="medium" name="ArrowSubDown" color={description.color} />

            <Text size={2} className={styles.trigger_type}>
              {description.text}
            </Text>
          </Space>
        </Space>

        <Space
          direction="vertical"
          justify="start"
          className={styles.trigger_details}
          hiddenOnDesktop
          fullWidth
        >
          <Space align="middle" gap={10}>
            <Icon size="medium" name="Calendar" color="#BDBDBD" />

            <Text size={2} className={styles.trigger_date}>
              Created at: {formatDateTime(data.created_at)}
            </Text>
          </Space>

          <Space align="middle" gap={10}>
            <Icon size="medium" name="Calendar" color="#BDBDBD" />

            <Text size={2} className={styles.trigger_date}>
              Updated at: {formatDateTime(data.updated_at)}
            </Text>
          </Space>

          {(data.short_key || data.qr_code_data) && (
            <Space align="middle" gap={10}>
              <Icon
                id="icon-cardfull-copy"
                size="medium"
                name="Copy"
                color={description.color}
                onClick={() =>
                  copyLinkToClipboard(
                    `${document.location.origin}/${
                      data.short_key || data.qr_code_data
                    }`
                  )
                }
              />

              <Text size={2}>
                {document.location.origin}/{data.short_key || data.qr_code_data}
              </Text>
            </Space>
          )}

          {data.scenario_payload.original_url && (
            <Space align="middle" gap={10}>
              <Icon
                id="icon-cardfull-copy-full"
                size="medium"
                name="Copy"
                color={description.color}
                onClick={() =>
                  copyLinkToClipboard(data.scenario_payload.original_url || '')
                }
              />

              <Text size={2}>{data.scenario_payload.original_url}</Text>
            </Space>
          )}
        </Space>

        <Space
          direction="vertical"
          align="middle"
          className={styles.trigger_count}
        >
          <Text
            font="Inter"
            size={1}
            className={styles.trigger_interaction_count}
          >
            {data.interaction_count}
          </Text>

          <Text size={2} className={styles.count_title}>
            Total {data.type === 'qr-code' ? 'scans' : 'opens'}
          </Text>

          <Space hiddenOnMobile>
            <Icon
              id="icon-cardfull-edit"
              size="small"
              name="Edit"
              color={description.color}
              className={styles.trigger_actions_btn}
              onClick={onEdit}
            />

            {data.type === 'qr-code' && (
              <Icon
                id="icon-cardfull-download"
                size="small"
                name="Download"
                color={description.color}
                className={styles.trigger_actions_btn}
                onClick={onDownload}
              />
            )}

            <Icon
              id="icon-cardfull-delete"
              size="small"
              name="Trash"
              color={description.color}
              className={styles.trigger_actions_btn}
              onClick={onDelete}
            />
          </Space>
        </Space>
      </Space>
    </>
  );
};

export default React.memo(CollectionCardFull);
