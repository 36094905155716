import React from 'react';
import classNames from 'classnames';
import Space from '@UIkit/Space';
import Typography from '@UIkit/Typography';
import { TIcons } from '@common/types';
import styles from './radiogroup.module.scss';
import Icon from '../Icon';

interface IRadioOption {
  id: string;
  label: string;
  icon?: TIcons;
}

interface IRadioGroup {
  disabled?: boolean;
  options: IRadioOption[];
  selectedOption: string;
  onChange: (value: string) => void;
  align?: 'vertical' | 'horizontal';
}

const { Text } = Typography;

const RadioGroup: React.FC<IRadioGroup> = ({
  disabled = false,
  options,
  selectedOption,
  onChange,
  align = 'vertical',
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Space
      className={classNames(styles.radioGroup, { [styles.disabled]: disabled })}
      direction={align}
      gap={10}
    >
      {options.map((option) => (
        <label
          key={option.id}
          className={classNames(styles.radioLabel, {
            [styles.active]: selectedOption === option.id,
          })}
        >
          <input
            type="radio"
            id={option.id}
            value={option.id}
            checked={selectedOption === option.id}
            onChange={handleChange}
            className={styles.radioInput}
          />
          {option?.icon ? (
            <Icon name={option.icon} />
          ) : (
            <span className={styles.radioCustom}></span>
          )}

          <Text size={2}> {option.label}</Text>
        </label>
      ))}
    </Space>
  );
};

export default RadioGroup;
