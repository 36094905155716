import { put, call, all, takeLatest } from 'redux-saga/effects';
import { toastMessages } from '@common/constants';
import { errorHandler, successHandler } from '@common/helpers';
import { ResponseError } from '@common/types';
import { fetchSubscribe } from './service';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

function* onFetchSubscribeStart({
  payload,
  onSuccess,
}: actionTypes.FetchSubscribeStart) {
  try {
    yield call(fetchSubscribe, payload);
    yield put(actionCreators.fetchSubsrcibeSuccess());
    onSuccess();
    successHandler(toastMessages.subscription_completed_successfully);
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchSubscribeFailure());
  }
}

export default function* subscribeSaga() {
  yield all([
    takeLatest(actionTypes.FETCH_SUBSCRIBE_START, onFetchSubscribeStart),
  ]);
}
