import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Field, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import configSelector from '@configSelector';
import Notify from '@components/UIkit/Notify';
import {
  ITriggerCommonRequest,
  ICreateTriggerRequest,
  ICreateTriggerResponse,
  ITriggerInterface,
  TTriggerType,
  TScenarioType,
} from '@core/triggers/service';
import Button from '@components/UIkit/Button';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import CreateForm from '@components/CreateForm';
import {
  fetchCreateTriggerStart,
  fetchGetTriggerByIdStart,
  fetchUpdateTriggerStart,
} from '@core/triggers/actionCreators';
import routing from '@routing/path';
import SwitchButton from '@components/UIkit/SwitchButton';
import RadioGroup from '@components/UIkit/RadioGroup';
import { useModal } from '@hooks/useModal';
import ModalAccessToProfile from '@components/Modals/ModalAccessToProfile';
import Separator from '@components/UIkit/Separator';
import { Input } from '@components/FormControl';
import ModalAuthorizationPrompt from '@components/Modals/ModalAuthorizationPrompt';
import ModalLimitReached from '@components/Modals/ModalLimitReached';
import FormOpenUrl from '../FormOpenUrl';
import FormLinkInBio from '../FormLinkInBio';
import TriggerCreated from '../TriggerCreated';
import styles from '../../styles.module.scss';

interface ICreateTriggerContainerProps {
  trigger_type: TTriggerType;
  isEdit?: boolean;
}

interface ICreateTriggerProps {
  isEdit?: boolean;
  dataToEdit?: ITriggerInterface | null;
  loader: boolean;
  data: {
    trigger_type: TTriggerType;
    data: ICreateTriggerResponse;
  } | null;
  onSubmit: (
    data: ITriggerCommonRequest,
    helpers?: FormikHelpers<ITriggerCommonRequest>
  ) => void;
  onGetSuccessToProfile: () => void;
  myTriggersLimitLeft: number;
  isAuth: boolean;
  trigger_type: TTriggerType;
}

const { Paragraph } = Typography;

const CreateTriggerContainer: React.FC<ICreateTriggerContainerProps> = ({
  trigger_type,
  isEdit,
}) => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showModal, closeModal } = useModal();

  const [data, setData] = useState<{
    trigger_type: TTriggerType;
    data: ICreateTriggerResponse;
  } | null>(null);

  useEffect(() => {
    if (id) {
      dispatch(fetchGetTriggerByIdStart({ id }));
    }
  }, [id]);

  const isAuth = useSelector(configSelector.isAuth);
  const myTriggersLimitLeft = useSelector(configSelector.myTriggersLimitLeft);

  const { loader } = useSelector(configSelector.triggers);
  const dataToEdit = useSelector(configSelector.triggerById);

  useEffect(() => {
    setData(null);
  }, [trigger_type]);

  const onGetSuccessToProfile = useCallback(() => {
    showModal(<ModalAccessToProfile />, 'medium');
  }, []);

  const onSubmit = useCallback(
    (data: ICreateTriggerRequest) => {
      ReactGA.event({
        category: 'create_trigger',
        action: 'call_create_trigger_form',
      });
      const callbackRoute =
        data.trigger_type === 'QrCode'
          ? `${routing.private.collection.root}/${routing.private.collection.children.qrCode}`
          : `${routing.private.collection.root}/${routing.private.collection.children.link}`;

      const onSuccess = () => {
        ReactGA.event({
          category: 'create_trigger',
          action: 'submit_create_trigger_form',
        });
        closeModal();
        dispatch(
          isEdit
            ? fetchUpdateTriggerStart({ id, ...data }, () =>
                navigate(callbackRoute)
              )
            : fetchCreateTriggerStart(
                data,
                (d) => {
                  setData({ trigger_type, data: d });

                  if (data.is_private_request) {
                    navigate(callbackRoute);
                  }
                },
                () => showModal(<ModalLimitReached />, 'medium')
              )
        );
      };

      if (!data.is_private_request) {
        showModal(<ModalAuthorizationPrompt onNext={onSuccess} />, 'medium');
      } else {
        onSuccess();
      }
    },
    [isAuth, trigger_type, isEdit, id]
  );

  return (
    <CreateTrigger
      isEdit={isEdit}
      dataToEdit={dataToEdit.data}
      onGetSuccessToProfile={onGetSuccessToProfile}
      loader={loader && dataToEdit.loader}
      onSubmit={onSubmit}
      data={data}
      myTriggersLimitLeft={myTriggersLimitLeft}
      isAuth={Boolean(isAuth)}
      trigger_type={trigger_type}
    />
  );
};

const CreateTrigger: React.FC<ICreateTriggerProps> = ({
  isEdit = false,
  dataToEdit,
  loader,
  onSubmit,
  onGetSuccessToProfile,
  data,
  myTriggersLimitLeft,
  isAuth,
  trigger_type,
}) => {
  const payloadValidationSchema = (type) => {
    switch (type) {
      case 'free-open-url':
        return Yup.object().shape({
          original_url: Yup.string()
            .url('Invalid URL')
            .required('Original URL is required'),
        });

      case 'open-url':
        return Yup.object().shape({
          original_url: Yup.string()
            .url('Invalid URL')
            .required('Original URL is required'),
        });

      case 'link-in-bio':
        return Yup.object().shape({
          type: Yup.string().required(),
          layout: Yup.array()
            .of(
              Yup.object().shape({
                type: Yup.string().required(),
                image_url: Yup.string().when('type', {
                  is: 'image',
                  then: (schema) =>
                    schema.url('Invalid URL').required('Image URL is required'),
                }),
                text: Yup.string().when('type', {
                  is: (val) => ['header', 'text', 'link-button'].includes(val),
                  then: (schema) => schema.required('Text is required'),
                }),
                url: Yup.string().when('type', {
                  is: 'link-button',
                  then: (schema) =>
                    schema.url('Invalid URL').required('URL is required'),
                }),
              })
            )
            .required('Layout is required'),
        });

      default:
        return Yup.object();
    }
  };

  const validationSchema = Yup.object().shape({
    is_private_request: Yup.bool().required(),
    trigger_name: Yup.string().when('is_private_request', {
      is: true,
      then: (schema) => schema.required('The "Title" field is required'),
    }),
    payload: Yup.object()
      .shape({
        type: Yup.string().required(),
      })
      .test('payload-validation', 'Invalid payload', function (value) {
        const { type } = value || {};
        const schema = payloadValidationSchema(type);
        return schema.isValidSync(value);
      }),
  });

  const getInitialPayload = (type, dataToEdit) => {
    switch (type) {
      case 'open-url':
        return {
          type: 'open-url' as TScenarioType,
          original_url: isEdit
            ? dataToEdit?.scenario_payload.original_url || ''
            : '',
        };
      case 'link-in-bio':
        return {
          type: 'link-in-bio' as TScenarioType,
          layout: isEdit ? dataToEdit?.scenario_payload.layout || [] : [],
        };
      default:
        return { type };
    }
  };

  const initialValues: ITriggerCommonRequest = {
    is_private_request: isAuth,
    trigger_type: trigger_type,
    trigger_name: isEdit ? dataToEdit?.name || '' : '',
    payload: getInitialPayload(
      isEdit ? dataToEdit?.scenario_payload.type || 'open-url' : 'open-url',
      dataToEdit
    ),
  };

  const getActionText = (
    isEdit: boolean,
    trigger_type: TTriggerType
  ): string => {
    const action = isEdit ? 'Save' : 'Create';
    const target = trigger_type === 'ShortLink' ? 'short link' : 'QR Code';
    return `${action} ${target}`;
  };

  const getTitle = (isEdit: boolean, trigger_type: TTriggerType): string => {
    if (trigger_type === 'ShortLink') {
      return isEdit ? 'Edit a shortened link' : 'Shorten a long link';
    } else {
      return isEdit ? 'Edit QR Code' : 'Create QR Code';
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isValid, setFieldValue, values }) => (
        <CreateForm handleSubmit={handleSubmit} name={'linkCreation'}>
          <Space direction="vertical" gap={20}>
            <Paragraph>{getTitle(isEdit, trigger_type)}</Paragraph>

            <SwitchButton
              id="input-create-trigger-form-stats-enabled"
              disabled={isEdit || isAuth}
              value={values.is_private_request}
              onChange={(value) =>
                isAuth
                  ? setFieldValue('is_private_request', value)
                  : onGetSuccessToProfile()
              }
              text={'Statistics and editability'}
              align="left"
            />

            <RadioGroup
              align="horizontal"
              options={[
                { label: 'Open url', id: 'open-url', icon: 'Globe' },
                {
                  label: 'Link in Bio',
                  id: 'link-in-bio',
                  icon: 'ProfileSquare',
                },
              ]}
              selectedOption={values.payload.type}
              onChange={(value) => setFieldValue('payload.type', value)}
            />

            <Separator padding={10} />

            {values.is_private_request && (
              <Field
                id="input-create-trigger-form-trigger-name"
                name={'trigger_name'}
                placeholder={'Name'}
                title={'Title'}
                component={Input}
              />
            )}

            {values.payload.type === 'open-url' && <FormOpenUrl />}
            {values.payload.type === 'link-in-bio' && <FormLinkInBio />}

            {data && !values.is_private_request && <TriggerCreated {...data} />}

            {!isEdit && (
              <Notify
                text={
                  values.is_private_request
                    ? `You can create ${myTriggersLimitLeft} more QR codes or links with your current subscription`
                    : 'End your link with words that make in unique'
                }
                icon="Annotation"
              />
            )}

            <Button
              id="button-create-trigger-form-action"
              className={styles.submit_btn}
              submit
              disabled={!isValid || loader}
            >
              {getActionText(isEdit, trigger_type)}
            </Button>
          </Space>
        </CreateForm>
      )}
    </Formik>
  );
};

export default React.memo(CreateTriggerContainer);
