import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { TNavItem } from '@common/types';
import NavBar from '@components/UIkit/NavBar';

interface NavbarProviderProps {
  id: string;
  items: TNavItem[];
  suffix?: ReactNode;
  onSelect?: (item: TNavItem) => void;
  hidden?: boolean;
  children: ReactNode;
}

interface NavbarContextProps {
  activeItem: TNavItem;
  setActiveItem: Dispatch<SetStateAction<TNavItem>>;
  NavBarNode: JSX.Element;
}

const NavbarContext = createContext<NavbarContextProps | undefined>(undefined);

export const NavbarProvider = ({
  id,
  items,
  onSelect,
  suffix,
  hidden,
  children,
}: NavbarProviderProps) => {
  const [activeItem, setActiveItem] = useState(items[0]);

  const NavBarNode: JSX.Element = hidden ? (
    <></>
  ) : (
    <NavBar id={id} items={items} onSelect={onSelect} suffix={suffix} />
  );

  return (
    <NavbarContext.Provider value={{ activeItem, setActiveItem, NavBarNode }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbar = () => {
  const context = useContext(NavbarContext);

  if (!context) {
    throw new Error('useNavbar must be used within NavbarProvider');
  }

  return context;
};
