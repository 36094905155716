import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ResponseError } from '@common/types';
import { errorHandler } from '@common/helpers';
import { GetMyViewResponse } from '@common/types';
import { fetchGetMyView } from './service';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

function* onGetMyViewStart() {
  try {
    const { data }: { data: GetMyViewResponse } = yield call(fetchGetMyView);
    yield put(actionCreators.fetchGetMyViewSuccess(data));
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchGetMyViewFailure());
  }
}

export default function* profileSaga() {
  yield all([
    takeLatest(actionTypes.FETCH_GET_MY_VIEW_START, onGetMyViewStart),
  ]);
}
