import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { scroller, animateScroll } from 'react-scroll';
import routing from '@routing/path';
import configSelector from '@configSelector';
import Button from '@components/UIkit/Button';
import Typography from '@components/UIkit/Typography';
import Container from '@components/UIkit/Container';
import Space from '@components/UIkit/Space';
import Icon from '@components/UIkit/Icon';
import MobileMenu from '@components/MobileMenu';
import styles from './styles.module.scss';

interface IHeaderProps {
  isAuth: boolean;
  isAdmin: boolean;
}

const { Text } = Typography;

const AppHeaderContainer: React.FC = () => {
  const isAuth = useSelector(configSelector.isAuth);
  const isAdmin = useSelector(configSelector.isAdmin);

  return <AppHeader isAuth={isAuth} isAdmin={isAdmin} />;
};

const AppHeader: React.FC<IHeaderProps> = ({ isAuth, isAdmin }) => {
  return (
    <header className={styles.header}>
      <Container>
        <Space align="middle" justify="between" fullHeight>
          <NavLink
            to={routing.public.home}
            onClick={() => animateScroll.scrollTo(0)}
            id="link-header-logotype"
          >
            <Space gap={10} align="middle" className={styles.logotype}>
              <Icon name="MurlWhite" size="medium" /> Murl
            </Space>
          </NavLink>

          <Space gap={40} align="middle" justify="end" className={styles.right}>
            {isAdmin && (
              <Text
                size={2}
                to={`${routing.private.admin.root}/${routing.private.admin.children.users}`}
              >
                Admin
              </Text>
            )}

            {document.location.hostname !== 'murl.gg' && (
              <Text size={2} to={routing.public.test}>
                Test
              </Text>
            )}

            {isAuth ? (
              <>
                <Text
                  size={2}
                  to={routing.public.home}
                  onClick={() => {
                    setTimeout(() => animateScroll.scrollTo(0), 0);
                  }}
                >
                  Home
                </Text>

                <Text
                  size={2}
                  to={`${routing.private.collection.root}/${routing.private.collection.children.link}`}
                >
                  Links
                </Text>

                <Text
                  size={2}
                  to={`${routing.private.collection.root}/${routing.private.collection.children.qrCode}`}
                >
                  QR Codes
                </Text>

                <Text
                  size={2}
                  to={`${routing.private.profile.root}/${routing.private.profile.children.plans}`}
                >
                  Profile
                </Text>
              </>
            ) : (
              <>
                <Text
                  size={2}
                  to={routing.public.home}
                  onClick={() => {
                    setTimeout(() => animateScroll.scrollTo(0), 0);
                  }}
                >
                  Home
                </Text>
                <Text
                  size={2}
                  to={routing.public.home}
                  onClick={() => {
                    setTimeout(
                      () =>
                        scroller.scrollTo('homeProducts', {
                          duration: 1000,
                          smooth: true,
                          offset: -50,
                        }),
                      0
                    );
                  }}
                >
                  Products
                </Text>
                <Text
                  size={2}
                  to={routing.public.home}
                  onClick={() => {
                    setTimeout(
                      () =>
                        scroller.scrollTo('homePricing', {
                          duration: 1000,
                          smooth: true,
                          offset: -50,
                        }),
                      0
                    );
                  }}
                >
                  Pricing
                </Text>
                <Space>
                  <Text
                    size={2}
                    to={routing.public.home}
                    onClick={() => {
                      setTimeout(
                        () =>
                          scroller.scrollTo('homeFAQ', {
                            duration: 1000,
                            smooth: true,
                            offset: -50,
                          }),
                        100
                      );
                    }}
                  >
                    Whats
                  </Text>

                  <Text size={3} className={styles.newNotify}>
                    new
                  </Text>
                </Space>
              </>
            )}

            {!isAuth && (
              <Button
                id="button-header-sign-in"
                className={styles.signin}
                to={routing.public.signin}
              >
                Sign In
              </Button>
            )}
          </Space>

          <MobileMenu />
        </Space>
      </Container>
    </header>
  );
};

export default React.memo(AppHeaderContainer);
