import {
  ICreateTriggerRequest,
  ICreateTriggerResponse,
  IDeleteTriggerRequest,
  IDeleteTriggerResponse,
  IFetchGetTriggerByIdRequest,
  IFetchGetTriggerByIdResponse,
  IFetchGetTriggersAllResponse,
  IUpdateTriggerRequest,
  IUpdateTriggerResponse,
  IUploadFileRequest,
  IUploadFileResponse,
} from './service';

// Upload file

export const FETCH_UPLOAD_FILE_START =
  'triggersActionTypes/FETCH_UPLOAD_FILE_START';
export interface FetchUploadFileStart {
  type: typeof FETCH_UPLOAD_FILE_START;
  payload: IUploadFileRequest;
  onSuccess?: (data: IUploadFileResponse) => void;
}

export const FETCH_UPLOAD_FILE_SUCCESS =
  'triggersActionTypes/FETCH_UPLOAD_FILE_SUCCESS';
export interface FetchUploadFileSuccess {
  type: typeof FETCH_UPLOAD_FILE_SUCCESS;
  payload: IUploadFileResponse;
}

export const FETCH_UPLOAD_FILE_FAILURE =
  'triggersActionTypes/FETCH_UPLOAD_FILE_FAILURE';
export interface FetchUploadFileFailure {
  type: typeof FETCH_UPLOAD_FILE_FAILURE;
}

// Create Trigger

export const FETCH_CREATE_TRIGGER_START =
  'triggersActionTypes/FETCH_CREATE_TRIGGER_START';
export interface FetchCreateTriggerStart {
  type: typeof FETCH_CREATE_TRIGGER_START;
  payload: ICreateTriggerRequest;
  onSuccess?: (data: ICreateTriggerResponse) => void;
  onFailure?: () => void;
}

export const FETCH_CREATE_TRIGGER_SUCCESS =
  'triggersActionTypes/FETCH_CREATE_TRIGGER_SUCCESS';
export interface FetchCreateTriggerSuccess {
  type: typeof FETCH_CREATE_TRIGGER_SUCCESS;
  payload: ICreateTriggerResponse;
}

export const FETCH_CREATE_TRIGGER_FAILURE =
  'triggersActionTypes/FETCH_CREATE_TRIGGER_FAILURE';
export interface FetchCreateTriggerFailure {
  type: typeof FETCH_CREATE_TRIGGER_FAILURE;
}

// Update Trigger

export const FETCH_UPDATE_TRIGGER_START =
  'triggersActionTypes/FETCH_UPDATE_TRIGGER_START';
export interface FetchUpdateTriggerStart {
  type: typeof FETCH_UPDATE_TRIGGER_START;
  payload: IUpdateTriggerRequest;
  onSuccess: () => void;
}

export const FETCH_UPDATE_TRIGGER_SUCCESS =
  'triggersActionTypes/FETCH_UPDATE_TRIGGER_SUCCESS';
export interface FetchUpdateTriggerSuccess {
  type: typeof FETCH_UPDATE_TRIGGER_SUCCESS;
  payload: IUpdateTriggerResponse;
}

export const FETCH_UPDATE_TRIGGER_FAILURE =
  'triggersActionTypes/FETCH_UPDATE_TRIGGER_FAILURE';
export interface FetchUpdateTriggerFailure {
  type: typeof FETCH_UPDATE_TRIGGER_FAILURE;
}

// Delete Trigger

export const FETCH_DELETE_TRIGGER_START =
  'triggersActionTypes/FETCH_DELETE_TRIGGER_START';
export interface FetchDeleteTriggerStart {
  type: typeof FETCH_DELETE_TRIGGER_START;
  payload: IDeleteTriggerRequest;
  onSuccess: () => void;
}

export const FETCH_DELETE_TRIGGER_SUCCESS =
  'triggersActionTypes/FETCH_DELETE_TRIGGER_SUCCESS';
export interface FetchDeleteTriggerSuccess {
  type: typeof FETCH_DELETE_TRIGGER_SUCCESS;
  payload: IDeleteTriggerResponse;
}

export const FETCH_DELETE_TRIGGER_FAILURE =
  'triggersActionTypes/FETCH_DELETE_TRIGGER_FAILURE';
export interface FetchDeleteTriggerFailure {
  type: typeof FETCH_DELETE_TRIGGER_FAILURE;
}

// Get Triggers All

export const FETCH_GET_TRIGGERS_ALL_START =
  'triggersActionTypes/FETCH_GET_TRIGGERS_ALL_START';
export interface FetchGetTriggersAllStart {
  type: typeof FETCH_GET_TRIGGERS_ALL_START;
}

export const FETCH_GET_TRIGGERS_ALL_SUCCESS =
  'triggersActionTypes/FETCH_GET_TRIGGERS_ALL_SUCCESS';
export interface FetchGetTriggersAllSuccess {
  type: typeof FETCH_GET_TRIGGERS_ALL_SUCCESS;
  payload: IFetchGetTriggersAllResponse;
}

export const FETCH_GET_TRIGGERS_ALL_FAILURE =
  'triggersActionTypes/FETCH_GET_TRIGGERS_ALL_FAILURE';
export interface FetchGetTriggersAllFailure {
  type: typeof FETCH_GET_TRIGGERS_ALL_FAILURE;
}

// Get Trigger by Id

export const FETCH_GET_TRIGGER_BY_ID_START =
  'triggersActionTypes/FETCH_GET_TRIGGER_BY_ID_START';
export interface FetchGetTriggerByIdStart {
  type: typeof FETCH_GET_TRIGGER_BY_ID_START;
  payload: IFetchGetTriggerByIdRequest;
}

export const FETCH_GET_TRIGGER_BY_ID_SUCCESS =
  'triggersActionTypes/FETCH_GET_TRIGGER_BY_ID_SUCCESS';
export interface FetchGetTriggerByIdSuccess {
  type: typeof FETCH_GET_TRIGGER_BY_ID_SUCCESS;
  payload: IFetchGetTriggerByIdResponse;
}

export const FETCH_GET_TRIGGER_BY_ID_FAILURE =
  'triggersActionTypes/FETCH_GET_TRIGGER_BY_ID_FAILURE';
export interface FetchGetTriggerByIdFailure {
  type: typeof FETCH_GET_TRIGGER_BY_ID_FAILURE;
}

export type LinksAction =
  | FetchUploadFileStart
  | FetchUploadFileSuccess
  | FetchUploadFileFailure
  //
  | FetchCreateTriggerStart
  | FetchCreateTriggerSuccess
  | FetchCreateTriggerFailure
  //
  | FetchUpdateTriggerStart
  | FetchUpdateTriggerSuccess
  | FetchUpdateTriggerFailure
  //
  | FetchDeleteTriggerStart
  | FetchDeleteTriggerSuccess
  | FetchDeleteTriggerFailure
  //
  | FetchGetTriggersAllStart
  | FetchGetTriggersAllSuccess
  | FetchGetTriggersAllFailure
  //
  | FetchGetTriggerByIdStart
  | FetchGetTriggerByIdSuccess
  | FetchGetTriggerByIdFailure;
