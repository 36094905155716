import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ResponseError } from '@common/types';
import { errorHandler } from '@common/helpers';
import { GetInteractionResponse, fetchGetInteraction } from './service';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

function* onFetchGetInteractionStart({
  payload,
}: actionTypes.FetchGetInteractionStart) {
  try {
    const { data }: { data: GetInteractionResponse } = yield call(
      fetchGetInteraction,
      payload
    );
    yield put(actionCreators.fetchGetInteractionSuccess(data));
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchGetInteractionFailure());
  }
}

export default function* interactionSaga() {
  yield all([
    takeLatest(
      actionTypes.FETCH_GET_INTERACTION_START,
      onFetchGetInteractionStart
    ),
  ]);
}
