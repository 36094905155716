import * as actions from './actionTypes';
import {
  ICreateTriggerRequest,
  ICreateTriggerResponse,
  IDeleteTriggerRequest,
  IDeleteTriggerResponse,
  IFetchGetTriggerByIdRequest,
  IFetchGetTriggerByIdResponse,
  IFetchGetTriggersAllResponse,
  IUpdateTriggerRequest,
  IUpdateTriggerResponse,
  IUploadFileRequest,
  IUploadFileResponse,
} from './service';

// Upload File

export function fetchUploadFileStart(
  payload: IUploadFileRequest,
  onSuccess?: (data: IUploadFileResponse) => void
): actions.FetchUploadFileStart {
  return {
    type: actions.FETCH_UPLOAD_FILE_START,
    payload,
    onSuccess,
  };
}

export function fetchUploadFileSuccess(
  payload: IUploadFileResponse
): actions.FetchUploadFileSuccess {
  return {
    type: actions.FETCH_UPLOAD_FILE_SUCCESS,
    payload,
  };
}

export function fetchUploadFileFailure(): actions.FetchUploadFileFailure {
  return {
    type: actions.FETCH_UPLOAD_FILE_FAILURE,
  };
}

// Create Trigger

export function fetchCreateTriggerStart(
  payload: ICreateTriggerRequest,
  onSuccess?: (data: ICreateTriggerResponse) => void,
  onFailure?: () => void
): actions.FetchCreateTriggerStart {
  return {
    type: actions.FETCH_CREATE_TRIGGER_START,
    payload,
    onSuccess,
    onFailure,
  };
}

export function fetchCreateTriggerSuccess(
  payload: ICreateTriggerResponse
): actions.FetchCreateTriggerSuccess {
  return {
    type: actions.FETCH_CREATE_TRIGGER_SUCCESS,
    payload,
  };
}

export function fetchCreateTriggerFailure(): actions.FetchCreateTriggerFailure {
  return {
    type: actions.FETCH_CREATE_TRIGGER_FAILURE,
  };
}

// Update Trigger

export function fetchUpdateTriggerStart(
  payload: IUpdateTriggerRequest,
  onSuccess: () => void
): actions.FetchUpdateTriggerStart {
  return {
    type: actions.FETCH_UPDATE_TRIGGER_START,
    payload,
    onSuccess,
  };
}

export function fetchUpdateTriggerSuccess(
  payload: IUpdateTriggerResponse
): actions.FetchUpdateTriggerSuccess {
  return {
    type: actions.FETCH_UPDATE_TRIGGER_SUCCESS,
    payload,
  };
}

export function fetchUpdateTriggerFailure(): actions.FetchUpdateTriggerFailure {
  return {
    type: actions.FETCH_UPDATE_TRIGGER_FAILURE,
  };
}

// Delete Trigger

export function fetchDeleteTriggerStart(
  payload: IDeleteTriggerRequest,
  onSuccess: () => void
): actions.FetchDeleteTriggerStart {
  return {
    type: actions.FETCH_DELETE_TRIGGER_START,
    payload,
    onSuccess,
  };
}

export function fetchDeleteTriggerSuccess(
  payload: IDeleteTriggerResponse
): actions.FetchDeleteTriggerSuccess {
  return {
    type: actions.FETCH_DELETE_TRIGGER_SUCCESS,
    payload,
  };
}

export function fetchDeleteTriggerFailure(): actions.FetchDeleteTriggerFailure {
  return {
    type: actions.FETCH_DELETE_TRIGGER_FAILURE,
  };
}

// Get Triggers All

export function fetchGetTriggersAllStart(): actions.FetchGetTriggersAllStart {
  return {
    type: actions.FETCH_GET_TRIGGERS_ALL_START,
  };
}

export function fetchGetTriggersAllSuccess(
  payload: IFetchGetTriggersAllResponse
): actions.FetchGetTriggersAllSuccess {
  return {
    type: actions.FETCH_GET_TRIGGERS_ALL_SUCCESS,
    payload,
  };
}

export function fetchGetTriggersAllFailure(): actions.FetchGetTriggersAllFailure {
  return {
    type: actions.FETCH_GET_TRIGGERS_ALL_FAILURE,
  };
}

// Get Trigger by Id

export function fetchGetTriggerByIdStart(
  payload: IFetchGetTriggerByIdRequest
): actions.FetchGetTriggerByIdStart {
  return {
    type: actions.FETCH_GET_TRIGGER_BY_ID_START,
    payload,
  };
}

export function fetchGetTriggerByIdSuccess(
  payload: IFetchGetTriggerByIdResponse
): actions.FetchGetTriggerByIdSuccess {
  return {
    type: actions.FETCH_GET_TRIGGER_BY_ID_SUCCESS,
    payload,
  };
}

export function fetchGetTriggerByIdFailure(): actions.FetchGetTriggerByIdFailure {
  return {
    type: actions.FETCH_GET_TRIGGER_BY_ID_FAILURE,
  };
}
