import React from 'react';
import Container from '@components/UIkit/Container';
import Space from '@components/UIkit/Space';
import TestButton from './TestButton';
import TestContainer from './TestContainer';
import TestGrid from './TestGrid';
import TestIcon from './TestIcon';
import TestList from './TestList';
import TestMenu from './TestMenu';
import TestNavBar from './TestNavBar';
import TestNotify from './TestNotify';
import TestSeparator from './TestSeparator';
import TestSpace from './TestSpace';
import TestStatistic from './TestStatistic';
import TestTypography from './TestTypography';
import TestModals from './TestModals';
import TestInputs from './TestInputs';
import TestRadioGroup from './TestRadioGroup';
import TestSwitchButton from './TestSwitchButton';

const Test: React.FC = () => {
  return (
    <Container>
      <Space direction="vertical" gap={100}>
        <TestButton />
        <TestContainer />
        <TestGrid />
        <TestIcon />
        <TestList />
        <TestMenu />
        <TestNavBar />
        <TestNotify />
        <TestSeparator />
        <TestSpace />
        <TestStatistic />
        <TestTypography />
        <TestModals />
        <TestInputs />
        <TestSwitchButton />
        <TestRadioGroup />
      </Space>
    </Container>
  );
};

export default React.memo(Test);
