import instance from '@core/api';

export type Roles = 'Admin' | 'RegularUser';

export interface OAuthResponse {
  token: string;
  roles: Roles[];
}

export interface OAuthRequest {
  token: string;
}

export async function fetchOAuth(
  payload: OAuthRequest
): Promise<OAuthResponse> {
  return await instance.post(`auth/by-google`, payload);
}
