import * as actions from './actionTypes';

export interface LinksState {
  loader: boolean;
}

const initialState: LinksState = {
  loader: false,
};

const callReducer = (
  state: LinksState = initialState,
  action: actions.LinksAction
): LinksState => {
  switch (action.type) {
    // Call Trigger
    case actions.FETCH_CALL_TRIGGER_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_CALL_TRIGGER_SUCCESS:
      return {
        ...state,
        loader: false,
      };

    case actions.FETCH_CALL_TRIGGER_FAILURE:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};

export default callReducer;
