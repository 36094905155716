import { ICallTriggerResponse, ICallTriggerRequest } from './service';

export const FETCH_CALL_TRIGGER_START =
  'linksActionTypes/FETCH_CALL_TRIGGER_START';
export interface FetchCallTriggerStart {
  type: typeof FETCH_CALL_TRIGGER_START;
  payload: ICallTriggerRequest;
  onSuccess: (response: ICallTriggerResponse) => void;
}

export const FETCH_CALL_TRIGGER_SUCCESS =
  'linksActionTypes/FETCH_CALL_TRIGGER_SUCCESS';
export interface FetchCallTriggerSuccess {
  type: typeof FETCH_CALL_TRIGGER_SUCCESS;
  payload: ICallTriggerResponse;
}

export const FETCH_CALL_TRIGGER_FAILURE =
  'linksActionTypes/FETCH_CALL_TRIGGER_FAILURE';
export interface FetchCallTriggerFailure {
  type: typeof FETCH_CALL_TRIGGER_FAILURE;
}

export type LinksAction =
  | FetchCallTriggerStart
  | FetchCallTriggerSuccess
  | FetchCallTriggerFailure;
