import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import configSelector from '@core/configSelector';
import Container from '@components/UIkit/Container';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import BackButton from '@components/BackButton';
import routing from '@routing/path';
import CollectionCardFull from '@components/CollectionCardFull';
import { fetchAdminGetTriggerByIdStart } from '@core/admin/actionCreators';
import LinkInBio from '@components/LinkInBio';
import PhoneWrapper from '@components/PhoneWrapper';
import Data from '@containers/Dashboard/components/Data';
import Loader from '@components/Loader';
import styles from '../admin.module.scss';

const TriggerById: React.FC = () => {
  const dispatch = useDispatch();
  const { triggerId = '' } = useParams();
  const { loader, triggerCurrent } = useSelector(configSelector.admin);

  console.log(triggerCurrent);

  useEffect(() => {
    if (triggerId) {
      dispatch(fetchAdminGetTriggerByIdStart({ triggerId }));
    }
  }, [dispatch, triggerId]);

  return (
    <Container>
      <BackButton
        to={`${routing.private.admin.root}/${routing.private.admin.children.triggers}`}
        text="triggers"
      />

      <Space direction="vertical" gap={40} fullWidth>
        <Headline description={`Trigger ID: ${triggerId}`} align="left" />

        <Space direction="vertical" align="middle" gap={40} fullWidth>
          {loader ? (
            <Loader />
          ) : (
            triggerCurrent && (
              <CollectionCardFull data={{ ...triggerCurrent }} />
            )
          )}

          <Space direction="horizontal" gap={40} fullWidth>
            {loader ? (
              <Loader />
            ) : (
              triggerCurrent && (
                <>
                  <Data
                    className={styles.charts}
                    interactionData={triggerCurrent.interactionData}
                    isQRCode={triggerCurrent.type === 'qr-code'}
                  />

                  {(triggerCurrent.scenario_payload.type === 'link-in-bio' ||
                    triggerCurrent.scenario_payload.type ===
                      'free-link-in-bio') && (
                    <PhoneWrapper>
                      <LinkInBio
                        layout={triggerCurrent.scenario_payload.layout || []}
                        className={styles.preview}
                      />
                    </PhoneWrapper>
                  )}
                </>
              )
            )}
          </Space>
        </Space>
      </Space>
    </Container>
  );
};

export default React.memo(TriggerById);
