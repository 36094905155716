import React, { useEffect, useState } from 'react';
import { Options } from 'qr-code-styling';
import classNames from 'classnames';
import DynamicIcons from '@assets/icons';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import InputRange from '@components/UIkit/InputRange';
import Icon from '@components/UIkit/Icon';
import { TIcons } from '@common/types';
import { convertComponentToDataURL } from '@common/helpers';
import Upload from '@components/UIkit/Upload';
import Separator from '@components/UIkit/Separator';
import styles from './image.module.scss';

const custonIcons: TIcons[] = [
  'Murl',
  'ShareTwitter',
  'ShareYoutube',
  'ShareInstagram',
  'ShareTelegram',
  'ShareTikTok',
  'ShareLinkedIn',
  'SharePinterest',
  'ShareOutlook',
  'ShareApple',
  'ShareWhatsup',
  'ShareFacebook',
];

interface IImage {
  options: Options;
  setOptions: (options: Options) => void;
}

const { Text } = Typography;

const Image: React.FC<IImage> = ({ options, setOptions }) => {
  const [uploadImage, setUploadImage] = useState<File | undefined>();
  const [activeIcon, setActiveIcon] = useState<TIcons | undefined>(undefined);

  useEffect(() => {
    if (uploadImage) {
      setActiveIcon(undefined);
    }
  }, [uploadImage]);

  useEffect(() => {
    if (activeIcon) {
      setUploadImage(undefined);
    }
  }, [activeIcon]);

  useEffect(() => {
    if (uploadImage) {
      const ImageComponent = URL.createObjectURL(uploadImage);

      setOptions({
        ...options,
        image: ImageComponent,
      });
    }

    if (activeIcon) {
      const IconComponent = activeIcon ? DynamicIcons[activeIcon] : undefined;

      setOptions({
        ...options,
        image: IconComponent
          ? convertComponentToDataURL(<IconComponent />)
          : undefined,
      });
    }
  }, [activeIcon, uploadImage]);

  const onIconClick = (name: TIcons) => {
    setActiveIcon(name);
  };

  const onRemove = () => {
    setUploadImage(undefined);
    setActiveIcon(undefined);

    setOptions({
      ...options,
      image: undefined,
    });
  };

  const onUploadImage = (file: File) => {
    setUploadImage(file);
    setActiveIcon(undefined);
  };

  return (
    <Space className={styles.image} direction="vertical" gap={20} fullWidth>
      <Space direction="vertical" gap={20} fullWidth>
        <Space align="middle" justify="between" fullWidth>
          <Text size={2}>Select Image Icons</Text>

          <Text size={2} className={styles.text}>
            Upload your own icon or use the icons below.
          </Text>
        </Space>

        <Space justify="between" align="start" fullWidth>
          <Space align="middle" wrapped gap={20} className={styles.icons}>
            {custonIcons.map((i) => (
              <Icon
                id={`icon-generator-set-image-${i}`}
                onClick={() => onIconClick(i)}
                size="huge"
                name={i}
                className={classNames(styles.icon, {
                  [styles.active]: activeIcon === i,
                })}
              />
            ))}

            <Icon
              id="icon-generator-remove-image"
              onClick={onRemove}
              size="huge"
              name="Cancel"
              className={classNames(styles.icon, styles.remove)}
            />
          </Space>

          <Upload
            id="input-generator-image-upload"
            onChange={onUploadImage}
            value={uploadImage}
          />
        </Space>
      </Space>
      <Separator />
      <Space direction="vertical" gap={20} fullWidth>
        <Space align="middle" justify="between">
          <Text size={2}>Image Size</Text>
        </Space>

        <Space
          direction="vertical"
          align="middle"
          justify="between"
          wrapped
          gap={10}
          fullWidth
        >
          <InputRange
            id="input-generator-image-size"
            min={1}
            max={5}
            step={1}
            value={(options.imageOptions?.imageSize || 0.5) * 10}
            onChange={(e) =>
              setOptions({
                ...options,
                imageOptions: {
                  ...options.imageOptions,
                  imageSize: e / 10,
                },
              })
            }
          />

          <Space align="middle" justify="between" gap={10}>
            <Text size={2} className={styles.text}>
              Coefficient of the image size.
            </Text>

            <Text size={2}>{options.imageOptions?.imageSize || 0.5}</Text>
          </Space>
        </Space>
      </Space>
      <Separator />
      <Space direction="vertical" gap={20} fullWidth>
        <Space align="middle" justify="between">
          <Text size={2}>Image Margin</Text>
        </Space>

        <Space
          direction="vertical"
          align="middle"
          justify="between"
          wrapped
          gap={10}
          fullWidth
        >
          <InputRange
            id="input-generator-image-margin"
            min={0}
            max={200}
            step={5}
            value={options.imageOptions?.margin || 0}
            onChange={(e) =>
              setOptions({
                ...options,
                imageOptions: {
                  ...options.imageOptions,
                  margin: e,
                },
              })
            }
          />

          <Space align="middle" justify="end" gap={10}>
            <Text size={2}>{options.imageOptions?.margin || 0} px</Text>
          </Space>
        </Space>
      </Space>
    </Space>
  );
};

export default Image;
