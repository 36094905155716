import React from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import Typography from '@components/UIkit/Typography';
import Grid from '@components/UIkit/Grid';
import styles from './test.module.scss';

const { Text } = Typography;

const TestGrid = () => {
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Grid" />

      <Grid
        gap={20}
        items={[
          <Text size={2}>Cell 1</Text>,
          <Text size={2}>Cell 2</Text>,
          <Text size={2}>Cell 3</Text>,
          <Text size={2}>Cell 4</Text>,
          <Text size={2}>Cell 5</Text>,
          <Text size={2}>Cell 6</Text>,
          <Text size={2}>Cell 7</Text>,
          <Text size={2}>Cell 8</Text>,
          <Text size={2}>Cell 9</Text>,
        ]}
      />
    </Space>
  );
};

export default React.memo(TestGrid);
