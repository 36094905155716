import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import configSelector from '@configSelector';
import routing from '@routing/path';
import PrivateRoute from '@HOC/PrivateRoute';
import PublicRoute from '@HOC/PublicRoute';
import { useModal } from '@hooks/useModal';
import OtherRoute from '@HOC/OtherRoute';
import HomePage from '@containers/Home';
import GeneratorPage from '@containers/Generator';
import TestPage from '@containers/Test';
import TriggerCallPage from '@containers/TriggerCall';
import TriggerCreatePage from '@containers/TriggerCreate';
import SignIn from '@containers/SignIn';
import Collection from '@containers/Collection';
import Profile from '@containers/Profile';
import Plans from '@containers/Plans';
import Dashboard from '@containers/Dashboard';
import Admin from '@containers/Admin';
import ModalRenewal from '@components/Modals/ModalRenewal';
import TriggerById from '@containers/Admin/components/TriggerById';

interface IAppProps {
  isAdmin: boolean;
}

const AppContainer: React.FC = () => {
  const { showModal } = useModal();
  const { tier, actual_to } = useSelector(configSelector.mySubscription);
  const MyTriggersLimit = useSelector(configSelector.myTriggersLimit);

  const isAuth = useSelector(configSelector.isAuth);
  const isAdmin = useSelector(configSelector.isAdmin);

  useEffect(() => {
    ReactGA.initialize('AW-586058075');
  }, []);

  useEffect(() => {
    if (isAuth && actual_to) {
      const expirationDate = new Date(actual_to * 1000);
      const today = new Date();

      if (today >= expirationDate) {
        showModal(<ModalRenewal />, 'medium');
        return;
      }

      const notificationDate = new Date(today);
      notificationDate.setDate(notificationDate.getDate() + 3);

      if (today >= notificationDate) {
        const lastNotificationDate = localStorage.getItem(
          'lastNotificationDate'
        );

        if (lastNotificationDate !== today.toDateString()) {
          localStorage.setItem('lastNotificationDate', today.toDateString());
          showModal(<ModalRenewal />, 'medium');
        }
      }
    }
  }, [showModal, MyTriggersLimit, tier, actual_to, isAuth]);

  return <App isAdmin={isAdmin} />;
};

const App: React.FC<IAppProps> = ({ isAdmin }) => {
  return (
    <Routes>
      <Route element={<OtherRoute />}>
        <Route path={routing.public.home} element={<HomePage />} />
        <Route
          path={`${routing.public.generator}/:data?`}
          element={<GeneratorPage />}
        />
        <Route
          path={`${routing.private.edit.root}/${routing.private.edit.children.link}/:id?`}
          element={<TriggerCreatePage trigger_type="ShortLink" isEdit />}
        />
        <Route
          path={`${routing.private.edit.root}/${routing.private.edit.children.qrCode}/:id?`}
          element={<TriggerCreatePage trigger_type="QrCode" isEdit />}
        />
        <Route
          path={`${routing.public.create.root}/${routing.public.create.children.link}`}
          element={<TriggerCreatePage trigger_type="ShortLink" />}
        />
        <Route
          path={`${routing.public.create.root}/${routing.public.create.children.qrCode}`}
          element={<TriggerCreatePage trigger_type="QrCode" />}
        />
        {document.location.hostname !== 'murl.gg' && (
          <Route path={routing.public.test} element={<TestPage />} />
        )}
      </Route>

      <Route element={<PublicRoute />}>
        <Route path={routing.public.signin} element={<SignIn />} />
      </Route>

      <Route element={<PrivateRoute />}>
        {isAdmin && (
          <>
            <Route
              path={`${routing.private.admin.root}/${routing.private.admin.children.users}`}
              element={<Admin table={'users'} />}
            />
            <Route
              path={`${routing.private.admin.root}/${routing.private.admin.children.triggers}`}
              element={<Admin table={'triggers'} />}
            />
            <Route
              path={`${routing.private.admin.root}/${routing.private.admin.children.trigger}/:triggerId?`}
              element={<TriggerById />}
            />
            <Route
              path={`${routing.private.admin.root}/${routing.private.admin.children.actions}`}
              element={<Admin table={'interactions'} />}
            />
            <Route
              path={`${routing.private.admin.root}/${routing.private.admin.children.plans}`}
              element={<Admin table={'plans'} />}
            />
            <Route
              path={`${routing.private.admin.root}/${routing.private.admin.children.payments}`}
              element={<Admin table={'payments'} />}
            />
          </>
        )}
        <Route element={<Profile />}>
          <Route
            path={`${routing.private.profile.root}/${routing.private.profile.children.plans}`}
            element={<Plans />}
          />
        </Route>
        <Route
          path={`${routing.private.collection.root}/${routing.private.collection.children.link}`}
          element={<Collection isQrCode={false} />}
        />
        <Route
          path={`${routing.private.collection.root}/${routing.private.collection.children.qrCode}`}
          element={<Collection isQrCode={true} />}
        />
        <Route
          path={`${routing.private.dashboard.root}/${routing.private.dashboard.children.link}/:id?`}
          element={<Dashboard isQRCode={false} />}
        />
        <Route
          path={`${routing.private.dashboard.root}/${routing.private.dashboard.children.qrCode}/:id?`}
          element={<Dashboard isQRCode={true} />}
        />
      </Route>

      <Route path="/:key?" element={<TriggerCallPage />} />
      <Route path="/qr/:key?" element={<TriggerCallPage />} />

      {/* Redirect all other paths */}
      <Route path="*" element={<Navigate to={routing.public.home} replace />} />
    </Routes>
  );
};

export default React.memo(AppContainer);
