import React from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import ModalDelete from '@components/Modals/ModalDelete';
import ModalExpiryNotification from '@components/Modals/ModalExpiryNotification';
import ModalRenewal from '@components/Modals/ModalRenewal';
import ModalSubscriptionLimitation from '@components/Modals/ModalSubscriptionLimitation';
import Button from '@components/UIkit/Button';
import { useModal } from '@hooks/useModal';
import ModalConfirm from '@components/Modals/ModalConfirm';
import styles from './test.module.scss';

const TestModals = () => {
  const { closeModal, showModal } = useModal();

  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Modals" />

      <Space className={styles.space} gap={20}>
        <Button
          id="button-show-modal-0"
          onClick={() =>
            showModal(<ModalDelete type="short-url" id="0" />, 'large')
          }
        >
          Delete Link
        </Button>

        <Button
          id="button-show-modal-1"
          onClick={() =>
            showModal(<ModalDelete type="qr-code" id="0" />, 'large')
          }
        >
          Delete QRCode
        </Button>
      </Space>

      <Space className={styles.space} gap={20}>
        <Button
          id="button-show-modal-2"
          onClick={() => showModal(<ModalExpiryNotification />, 'medium')}
        >
          ExpiryNotification
        </Button>

        <Button
          id="button-show-modal-3"
          onClick={() => showModal(<ModalRenewal />, 'medium')}
        >
          Renewal
        </Button>
      </Space>

      <Space className={styles.space} gap={20}>
        <Button
          id="button-show-modal-4"
          onClick={() => showModal(<ModalSubscriptionLimitation />, 'medium')}
        >
          SubscriptionLimitation
        </Button>

        <Button
          id="button-show-modal-5"
          onClick={() =>
            showModal(
              <ModalConfirm
                onSuccess={closeModal}
                message="Do you really want to switch to the free subscription?"
              />,
              'small'
            )
          }
        >
          Confirm
        </Button>
      </Space>
    </Space>
  );
};

export default TestModals;
