import { ReactElement } from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type TNavItem = {
  text: string;
  icon?: TIcons;
  to?: string;
};

export interface IMenuAction {
  key?: number;
  value?: string | number;
  text: string;
  icon?: TIcons;
  onClick?: (...args: any[]) => void;
  children?: IMenuAction[];
}

export type TModalSize = 'large' | 'medium' | 'small';

export type DynamicIconsColors =
  | '#424242'
  | '#BDBDBD'
  | '#00FF75'
  | '#FFFFFF'
  | '#9747FF'
  | '#FF6B00'
  | '#8F6CF3'
  | '#000000';

export type TIcons =
  | 'Cancel'
  | 'Murl'
  | 'MurlWhite'
  | 'Upload'
  | 'ShareTelegram'
  | 'ShareFacebook'
  | 'ShareWhatsup'
  | 'ShareApple'
  | 'ShareAppleWhite'
  | 'ShareOutlook'
  | 'SharePinterest'
  | 'ShareLinkedIn'
  | 'ShareTikTok'
  | 'ShareTikTokWhite'
  | 'ShareInstagram'
  | 'ShareYoutube'
  | 'ShareTwitter'
  | 'Close'
  | 'PlayCircle'
  | 'Terminal'
  | 'Puzzle'
  | 'LinkExternal'
  | 'Trash'
  | 'Copy'
  | 'More'
  | 'Link'
  | 'CreditCard'
  | 'QRCode'
  | 'Chart'
  | 'Logout'
  | 'ArrowDownSm'
  | 'ArrowUpSm'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ArrowDown'
  | 'ArrowSubDown'
  | 'LinkBreak'
  | 'Calendar'
  | 'Label'
  | 'Download'
  | 'Edit'
  | 'Eclipse'
  | 'MasterCard'
  | 'MonoPay'
  | 'VisaCard'
  | 'Annotation'
  | 'FileEye'
  | 'Plus'
  | 'Globe'
  | 'ProfileSquare'
  | 'Move';

export type TPlanName =
  | 'Free'
  | 'Standard'
  | 'RegularUser'
  | 'Professional'
  | 'WebApp'
  | 'Custom';

export interface IHiddenOn {
  hiddenOnMobile?: boolean;
  hiddenOnDesktop?: boolean;
}

export interface IPlan {
  id: TPlanName;
  name: string;
  price: string;
  description: string;
  triggers: number;
  props: string[];
  action: string;
}

export const plansData: IPlan[] = [
  {
    id: 'Free',
    name: 'Free',
    price: '0',
    description: 'Free limited subscription',
    triggers: 3,
    props: [
      `Qty of QR Codes & Links: ${3}`,
      'Short link length: 8 characters',
      'Personal page',
      'Payable Action',
      'Readable links',
    ],
    action: 'Free version',
  },
  {
    id: 'Standard',
    name: 'Starter',
    price: '5',
    description: 'For small businesses and personal use',
    triggers: 100,
    props: [
      `Qty of QR Codes & Links: ${100}`,
      'Short link length: 7 characters',
      'Personal page',
      'Payable Action',
      'Readable links',
    ],
    action: 'Buy Starter',
  },
  {
    id: 'RegularUser',
    name: 'Regular User',
    price: '10',
    description: 'For marketers and SMM specialists',
    triggers: 500,
    props: [
      `Qty of QR Codes & Links: ${500}`,
      'Short link length: 6 characters',
      'Personal page',
      'Payable Action',
      'Readable links',
    ],
    action: 'Buy Regular',
  },
  {
    id: 'Professional',
    name: 'Professional',
    price: '20',
    description: 'For advanced marketers and medium-sized businesses',
    triggers: 3000,
    props: [
      `Qty of QR Codes & Links: ${3000}`,
      'Short link length: 5 characters',
      'Personal page',
      'Payable Action',
      'Readable links',
    ],
    action: 'Buy Professional',
  },
  {
    id: 'WebApp',
    name: 'Web App',
    price: '200',
    description: 'Automated solutions for your business',
    triggers: 10000,
    props: [
      `Qty of QR Codes & Links: ${10000}`,
      'Short link length: 6 characters',
      'Personal page',
      'Payable Action',
      'Readable links',
    ],
    action: 'Buy Web App',
  },
  {
    id: 'Custom',
    name: 'Custom',
    price: '1000+',
    description: 'Personalized offer with unlimited possibilities',
    triggers: 50000,
    props: ['All Features with custom limits'],
    action: 'Buy Custom',
  },
];

export interface ResponseError {
  code: number;
  message: string;
}

export interface QrCode {
  id: string;
  name: string;
  created_at: number;
}

export interface ShortLink {
  id: string;
  name: string;
  created_at: number;
  short_url: string;
  original_url: string;
  qr_codes: QrCode[];
}

export interface LinksProps {
  data: ShortLink[];
}

export interface Subscription {
  tier: string;
  actual_to: number;
  limit: number;
}

export interface GetMyViewResponse {
  id: string;
  email: string;
  subscription: Subscription;
}

export interface IFAQ {
  title: string;
  text: ReactElement;
}
