import ReactDOMServer from 'react-dom/server';
import { toast } from 'react-toastify';
import { toastMessages } from './constants';
import { ResponseError } from './types';

export const convertComponentToDataURL = (component: React.ReactElement) => {
  const svgString = ReactDOMServer.renderToStaticMarkup(component);
  const dataURL = `data:image/svg+xml,${encodeURIComponent(svgString)}`;
  return dataURL;
};

export const formatDateTime = (
  input: number | string,
  includeTime = false
): string => {
  let date: Date;

  if (typeof input === 'number') {
    date = new Date(input * 1000);
  } else {
    date = new Date(input);
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const fullYear = date.getFullYear();
  const shortYear = String(fullYear).slice(-2);

  if (includeTime) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${shortYear} ${hours}:${minutes}`;
  } else {
    return `${day}.${month}.${fullYear}`;
  }
};

export const errorHandler = ({ message }: ResponseError) => {
  toast.error(message, {
    toastId: 'notification-error-toast',
    updateId: 'notification-error-update',
    containerId: 'notification-error-container',
    hideProgressBar: true,
    style: {
      borderRadius: '10px',
      border: '1px solid #E93940',
      background: '#000',
      color: '#E93940',
      padding: '12px 23px',
      minHeight: '0',
      lineHeight: '26px',
      fontSize: '16px',
    },
    bodyStyle: {
      padding: '0',
    },
    icon: false,
    closeButton: false,
  });
};

export const successHandler = (message: string) => {
  toast.success(message, {
    toastId: 'notification-success-toast',
    updateId: 'notification-success-update',
    containerId: 'notification-success-container',
    hideProgressBar: true,
    style: {
      borderRadius: '10px',
      border: '1px solid #00FF75',
      background: '#000',
      color: '#00FF75',
      padding: '12px 23px',
      minHeight: '0',
      lineHeight: '26px',
      fontSize: '16px',
    },
    bodyStyle: {
      padding: '0',
    },
    icon: false,
    closeButton: false,
  });
};

export const copyLinkToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    successHandler(toastMessages.link_copied);
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};
