import React, { useCallback, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import routing from '@routing/path';
import { fetchOAuthFailure } from '@core/auth/actionCreators';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import Icon from '@components/UIkit/Icon';
import { DynamicIconsColors, TIcons } from '@common/types';
import styles from './styles.module.scss';

interface Item {
  name: string;
  href?: string;
  className?: string;
  Image: TIcons;
}

const { Paragraph } = Typography;

const menuItems: Item[] = [
  {
    name: 'My Plan',
    href: `${routing.private.profile.root}/${routing.private.profile.children.plans}`,
    Image: 'CreditCard',
  },
  {
    name: 'Statistics',
    href: `${routing.private.profile.root}/${routing.private.profile.children.statistics}`,
    Image: 'Chart',
  },
  {
    name: 'My QR codes',
    href: `${routing.private.collection.root}/${routing.private.collection.children.qrCode}`,
    Image: 'QRCode',
  },
  {
    name: 'My Links',
    href: `${routing.private.collection.root}/${routing.private.collection.children.link}`,
    Image: 'Link',
  },
  {
    name: 'Sign Out',
    className: styles.signOut,
    Image: 'Logout',
  },
];

const Item: React.FC<Item> = ({ name, href, className, Image }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onSignOut = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(fetchOAuthFailure());
    return false;
  }, []);

  const itemColor = useMemo((): DynamicIconsColors => {
    if (isHovered) return '#FFFFFF';
    if (location.pathname === href) return '#00FF75';

    return '#BDBDBD';
  }, [location.pathname, href, isHovered]);

  return (
    <NavLink
      className={classNames(
        styles.item,
        {
          [styles.active]: location.pathname === href,
        },
        className
      )}
      to={href || ''}
      onClick={name === 'Sign Out' ? onSignOut : undefined}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      id="link-left-menu-sign-out"
    >
      <Space gap={10} align="middle">
        <Icon size="medium" name={Image} color={itemColor} />

        <Paragraph size={2} className={styles.text}>
          {name}
        </Paragraph>
      </Space>
    </NavLink>
  );
};

const LeftMenu: React.FC = () => {
  return (
    <div className={styles.leftMenu}>
      <Space
        direction="vertical"
        gap={20}
        className={styles.content}
        fullHeight
      >
        {menuItems.map((item: Item) => (
          <Item key={item.name} {...item} />
        ))}
      </Space>
    </div>
  );
};

export default React.memo(LeftMenu);
