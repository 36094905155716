/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import classNames from 'classnames';
import DynamicIcons from '@assets/icons';
import { convertComponentToDataURL } from '@common/helpers';
import { DynamicIconsColors, TIcons } from '@common/types';
import styles from './icon.module.scss';

interface BaseIconProps {
  name: TIcons;
  className?: string;
  size?: 'large' | 'medium' | 'small' | 'huge';
  color?: DynamicIconsColors;
  active?: boolean;
}

interface ClickableIconProps extends BaseIconProps {
  id: string;
  onClick: (...args: any[]) => void;
}

interface NonClickableIconProps extends BaseIconProps {
  id?: never;
  onClick?: never;
}

type IIcon = ClickableIconProps | NonClickableIconProps;

const Icon: React.FC<IIcon> = ({
  id,
  name,
  className,
  size = 'medium',
  onClick,
  color,
}) => {
  const IconComponent = DynamicIcons[name];

  return (
    <div
      className={classNames(
        styles.icon,
        styles[size],
        {
          [styles.hasClick]: !!onClick,
        },
        className
      )}
      onClick={onClick}
    >
      <img
        id={id}
        src={convertComponentToDataURL(
          <IconComponent color={color || '#FFFFFF'} />
        )}
        alt={name}
      />
    </div>
  );
};

export default Icon;
