import React, { ChangeEvent, useEffect, useState } from 'react';
import Space from '../Space';
import ButtonGroup from '../ButtonGroup';
import styles from './datepicker.module.scss';

interface IDateRangePicker {
  id: string;
  onChange: React.Dispatch<
    React.SetStateAction<{
      fromDate: number;
      toDate: number;
    }>
  >;
}

const DatePicker: React.FC<IDateRangePicker> = ({ id, onChange }) => {
  const [value, setValue] = useState<number | ''>(1);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue === '') {
      setValue('');
      return;
    }

    const numericValue = parseInt(inputValue, 10);

    if (!isNaN(numericValue) && numericValue >= 0) {
      setValue(numericValue);
    }
  };

  useEffect(() => {
    if (value === '') {
      onChange({
        fromDate: 0,
        toDate: Math.floor(new Date().getTime() / 1000),
      });
    } else {
      const currentDate = new Date();
      const currentTimestamp = Math.floor(currentDate.getTime() / 1000);
      const fromDate = Math.floor(
        new Date(currentDate.setDate(currentDate.getDate() - value)).getTime() /
          1000
      );

      onChange({
        fromDate: fromDate,
        toDate: currentTimestamp,
      });
    }
  }, [value, onChange]);

  return (
    <Space gap={10} fullWidth>
      <ButtonGroup
        id={`${id}-button-group`}
        buttons={[
          { text: 'Today', value: '1' },
          { text: 'Week', value: '7' },
          { text: 'Month', value: '30' },
          {
            text: 'Year',
            value: '365',
          },
        ]}
        value={`${value}`}
        onChange={(value) => setValue(value ? +value : 0)}
      />

      <input
        id={id}
        maxLength={3}
        type="text"
        value={value}
        onChange={handleChange}
        className={styles.input}
        placeholder="Days"
      />
    </Space>
  );
};

export default React.memo(DatePicker);
