import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as actions from './actionTypes';
import { Roles } from './service';

export interface AuthState {
  token: string | undefined;
  roles: Roles[] | undefined;
  loader: boolean;
}

const initialState: AuthState = {
  token: undefined,
  roles: undefined,
  loader: false,
};

const authReducer = (
  state: AuthState = initialState,
  action: actions.AuthAction
): AuthState => {
  switch (action.type) {
    case actions.FETCH_OAUTH_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_OAUTH_SUCCESS:
      return {
        ...state,
        loader: false,
        token: action.payload.token,
        roles: action.payload.roles,
      };

    case actions.FETCH_OAUTH_FAILURE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'murl_auth',
  storage,
};

export default persistReducer(persistConfig, authReducer);
