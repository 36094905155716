import instance from '@core/api';

export interface GetInteractionRequest {
  id: string;
}

export type GetInteractionResponse = {
  total: Record<number, number>;
  uniq_devices: Record<number, number>;
  uniq_ip_address: Record<number, number>;
  country: Record<number, number>;
  os_name: Record<number, number>;
  browser: Record<number, number>;
  total_interactions: number;
  total_uniq_devices: number;
  total_uniq_ip: number;
  total_uniq_countries: number;
  total_uniq_os: number;
  total_uniq_browser: number;
};

export async function fetchGetInteraction(
  request: GetInteractionRequest
): Promise<GetInteractionResponse> {
  return await instance.get(`interaction/stats/${request.id}`);
}
