import React from 'react';
import { AreaChart, Area, Tooltip, XAxis, ResponsiveContainer } from 'recharts';

interface LineChartProps {
  data: Record<string, string>[];
  tooltipName: string;
  color: '#00ff75' | '#9747ff' | '#ff6b00';
}

const LineChart: React.FC<LineChartProps> = ({
  data,
  tooltipName = 'Visits',
  color,
}) => {
  return (
    <ResponsiveContainer style={{ flex: 1 }}>
      <AreaChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey={Object.keys(data?.[0])[0]}
          fill="#bdbdbd"
          stroke="#bdbdbd"
          tick={{ fontSize: 16, fontFamily: 'Raleway' }}
          tickMargin={10}
        />
        <Tooltip
          formatter={(value) => [`${value}`, tooltipName]}
          itemStyle={{
            color: '#ffffff',
          }}
          labelStyle={{
            color: '#ffffff',
          }}
          contentStyle={{
            border: 0,
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            borderRadius: '10px',
            background: 'rgba(255, 255, 255, 0.07)',
          }}
          wrapperStyle={{
            background: 'transparent',
          }}
        />
        <Area
          type="monotone"
          dataKey={Object.keys(data?.[0])[1]}
          stackId="1"
          stroke={color}
          fill={
            color === '#00ff75'
              ? '#007737'
              : color === '#9747ff'
              ? '#54278f'
              : '#9a4202'
          }
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default React.memo(LineChart);
