import { GetInteractionRequest, GetInteractionResponse } from './service';

export const FETCH_GET_INTERACTION_START =
  'interactionActionTypes/FETCH_GET_INTERACTION_START';
export interface FetchGetInteractionStart {
  type: typeof FETCH_GET_INTERACTION_START;
  payload: GetInteractionRequest;
}

export const FETCH_GET_INTERACTION_SUCCESS =
  'interactionActionTypes/FETCH_GET_INTERACTION_SUCCESS';
export interface FetchGetInteractionSuccess {
  type: typeof FETCH_GET_INTERACTION_SUCCESS;
  payload: GetInteractionResponse;
}

export const FETCH_GET_INTERACTION_FAILURE =
  'interactionActionTypes/FETCH_GET_INTERACTION_FAILURE';
export interface FetchGetInteractionFailure {
  type: typeof FETCH_GET_INTERACTION_FAILURE;
}

export type InteractionAction =
  | FetchGetInteractionStart
  | FetchGetInteractionSuccess
  | FetchGetInteractionFailure;
