import { put, call, all, takeLatest } from 'redux-saga/effects';
import { toastMessages } from '@common/constants';
import { errorHandler, successHandler } from '@common/helpers';
import { ResponseError } from '@common/types';
import { fetchGetMyViewStart } from '@core/profile/actionCreators';
import {
  BuySubscriptionResponse,
  fetchBuyFreeSubscription,
  fetchBuySubscription,
} from './service';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

function* onFetchBuyFreeSubscriptionStart() {
  try {
    yield call(fetchBuyFreeSubscription);
    yield put(actionCreators.fetchBuyFreeSubscriptionSuccess());
    yield put(fetchGetMyViewStart());
    successHandler(toastMessages.new_subscription_successfully_processed);
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchBuyFreeSubscriptionFailure());
  }
}

function* onFetchBuySubscriptionStart({
  payload,
  onSuccess,
}: actionTypes.FetchBuySubscriptionStart) {
  try {
    const { data }: { data: BuySubscriptionResponse } = yield call(
      fetchBuySubscription,
      payload
    );
    yield put(actionCreators.fetchBuySubscriptionSuccess());
    onSuccess(data.pay_page);
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchBuySubscriptionFailure());
  }
}

export default function* paymentSaga() {
  yield all([
    takeLatest(
      actionTypes.FETCH_BUY_SUBSCRIPTION_START,
      onFetchBuySubscriptionStart
    ),
    takeLatest(
      actionTypes.FETCH_BUY_FREE_SUBSCRIPTION_START,
      onFetchBuyFreeSubscriptionStart
    ),
  ]);
}
