import React, { useState } from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import InputRange from '@components/UIkit/InputRange';
import styles from './test.module.scss';

const TestInputs = () => {
  const [range, setRange] = useState(200);

  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Inputs" />

      <Space className={styles.space} gap={10}>
        <InputRange
          id="test-input-range"
          min={200}
          max={2000}
          step={25}
          value={range}
          onChange={setRange}
        />
      </Space>
    </Space>
  );
};

export default React.memo(TestInputs);
