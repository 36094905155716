import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { ITriggerCommonRequest } from '@core/triggers/service';
import { Input } from '@components/FormControl';

const FormOpenUrl: React.FC = () => {
  const { setFieldValue } = useFormikContext<ITriggerCommonRequest>();

  useEffect(() => {
    return () => {
      setFieldValue('payload.original_url', undefined);
    };
  }, []);

  return (
    <Field
      id="input-create-trigger-form-trigger-original-url"
      name={'payload.original_url'}
      placeholder={'Example: https://www.example.com/link-to-short'}
      title={'Paste a long URL'}
      component={Input}
    />
  );
};

export default FormOpenUrl;
