import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ResponseError } from '@common/types';
import { errorHandler } from '@common/helpers';
import { OAuthResponse, fetchOAuth } from './service';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

function* onOAuthStart({ payload }: actionTypes.FetchOAuthStart) {
  try {
    const { data }: { data: OAuthResponse; headers: { role: string } } =
      yield call(fetchOAuth, payload);

    yield put(actionCreators.fetchOAuthSuccess({ ...data }));
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchOAuthFailure());
  }
}

export default function* authSaga() {
  yield all([takeLatest(actionTypes.FETCH_OAUTH_START, onOAuthStart)]);
}
