import React from 'react';
import Typography from '@components/UIkit/Typography';
import styles from './styles.module.scss';

const { Paragraph } = Typography;

const EmptyData: React.FC = () => {
  return (
    <div className={styles.empty}>
      <Paragraph size={2} className={styles.text}>
        No data
      </Paragraph>
    </div>
  );
};

export default React.memo(EmptyData);
