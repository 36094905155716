import React from 'react';
import Button from '@components/UIkit/Button';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import styles from './test.module.scss';

const TestButton = () => {
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Button" />

      <Space className={styles.space} gap={10}>
        <Button type="primary" id="button-test-1">
          Button
        </Button>
        <Button type="danger" id="button-test-2">
          Button
        </Button>
        <Button type="default" id="button-test-3">
          Button
        </Button>
      </Space>

      <Space className={styles.space} gap={10}>
        <Button size="small" type="primary" id="button-test-4">
          Button
        </Button>
        <Button size="small" type="danger" id="button-test-5">
          Button
        </Button>
        <Button size="small" type="default" id="button-test-6">
          Button
        </Button>
      </Space>
    </Space>
  );
};

export default React.memo(TestButton);
