import React from 'react';
import { Outlet } from 'react-router-dom';
import Wrapper from '@components/Wrapper';

const OtherRoute: React.FC = () => {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};

export default React.memo(OtherRoute);
