import React, { useState, useEffect, useRef } from 'react';
import QRCodeStyling, { Options } from 'qr-code-styling';
import Space from '@components/UIkit/Space';
import styles from './styles.module.scss';

interface QRCodeHookResult {
  QRCode: JSX.Element | null;
}

const useQRCode = (data: string): QRCodeHookResult => {
  const [QRCode, setQRCode] = useState<JSX.Element | null>(null);
  const qrCodeRef = useRef<QRCodeStyling | null>(null);

  useEffect(() => {
    if (!data) return;

    const qrCodeInstance = new QRCodeStyling({
      width: 1024,
      height: 1024,
      type: 'svg',
      margin: 0,
      data: `${document.location.origin}/${data}`,
      image: undefined,
      qrOptions: {
        errorCorrectionLevel: 'L',
      },
      backgroundOptions: { color: '#ffffff' },
      dotsOptions: { type: 'rounded', color: '#000000' },
      cornersSquareOptions: { type: 'extra-rounded', color: '#000000' },
      cornersDotOptions: { color: '#000000' },
    } as Options);

    qrCodeInstance
      .getRawData('svg')
      .then((blob) => {
        if (blob) {
          const dataURL = URL.createObjectURL(blob);
          setQRCode(<img src={dataURL} alt="QR Code" />);
        } else {
          console.error('Failed to generate QR code Blob');
        }
      })
      .catch((error) => {
        console.error('Error generating QR code:', error);
      });

    qrCodeRef.current = qrCodeInstance;
    return () => {
      qrCodeRef.current = null;
    };
  }, [data]);

  return {
    QRCode: (
      <Space
        align="middle"
        justify="middle"
        className={styles.QRCodeImageWrapper}
      >
        {QRCode}
      </Space>
    ),
  };
};

export default useQRCode;
