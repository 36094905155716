import React from 'react';
import { Gradient, GradientType, Options } from 'qr-code-styling';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import SwitchButton from '@components/UIkit/SwitchButton';
import InputRange from '@components/UIkit/InputRange';
import ButtonGroup from '@components/UIkit/ButtonGroup';
import styles from '../colors.module.scss';

interface IBackground {
  options: Options;
  setOptions: (options: Options) => void;
}

const { Text } = Typography;

const Background: React.FC<IBackground> = ({ options, setOptions }) => {
  return (
    <Space direction="vertical" gap={20} fullWidth>
      <Space align="middle" justify="between" fullWidth>
        <Text size={2}>Background Color</Text>

        <SwitchButton
          id="input-generator-bg-type"
          value={!!options.backgroundOptions?.gradient}
          onChange={(value) =>
            setOptions({
              ...options,
              backgroundOptions: {
                ...options.backgroundOptions,
                gradient: value
                  ? {
                      type: 'linear',
                      rotation: 0,
                      colorStops: [
                        { offset: 0, color: '#ffffff' },
                        { offset: 1, color: '#ffffff' },
                      ],
                    }
                  : undefined,
              },
            })
          }
          text="Gradient?"
        />
      </Space>

      <Space align="middle" justify="between" wrapped gap={10} fullWidth>
        {!!options.backgroundOptions?.gradient ? (
          <Space justify="between" gap={40} align="middle" fullWidth>
            <Space gap={10}>
              <input
                id="input-generator-bg-gr-color-0"
                type="color"
                onChange={(e) =>
                  setOptions({
                    ...options,
                    backgroundOptions: {
                      ...options.backgroundOptions,
                      gradient: {
                        ...(options.backgroundOptions?.gradient as Gradient),
                        colorStops: [
                          { offset: 0, color: e.currentTarget.value },
                          {
                            offset: 1,
                            color:
                              options.backgroundOptions?.gradient
                                ?.colorStops?.[1].color || '#ffffff',
                          },
                        ],
                      },
                    },
                  })
                }
                value={
                  options.backgroundOptions?.gradient.colorStops?.[0].color
                }
              />
              <input
                id="input-generator-bg-gr-color-1"
                type="color"
                onChange={(e) =>
                  setOptions({
                    ...options,
                    backgroundOptions: {
                      ...options.backgroundOptions,
                      gradient: {
                        ...(options.backgroundOptions?.gradient as Gradient),
                        colorStops: [
                          {
                            offset: 0,
                            color:
                              options.backgroundOptions?.gradient
                                ?.colorStops?.[0].color || '#ffffff',
                          },
                          {
                            offset: 1,
                            color: e.currentTarget.value,
                          },
                        ],
                      },
                    },
                  })
                }
                value={
                  options.backgroundOptions?.gradient.colorStops?.[1].color
                }
              />
            </Space>

            {options.backgroundOptions?.gradient.type === 'linear' && (
              <Space gap={10} align="middle">
                <InputRange
                  id="input-generator-bg-gr-radius"
                  min={0}
                  max={360}
                  step={5}
                  value={0}
                  onChange={(e) =>
                    setOptions({
                      ...options,
                      backgroundOptions: {
                        ...options.backgroundOptions,
                        gradient: {
                          ...(options.backgroundOptions?.gradient as Gradient),
                          rotation: (e * Math.PI) / 180,
                        },
                      },
                    })
                  }
                />

                <Text size={2} font="Inter" className={styles.rotationValue}>
                  {(
                    (options.backgroundOptions?.gradient.rotation || 0) *
                    (180 / Math.PI)
                  ).toFixed(0)}
                  °
                </Text>
              </Space>
            )}

            <ButtonGroup
              id="button-group-generator-gr-t"
              buttons={[
                { value: 'radial', text: 'Radial' },
                { value: 'linear', text: 'Linear' },
              ]}
              value={options.backgroundOptions.gradient.type}
              onChange={(value) =>
                setOptions({
                  ...options,
                  backgroundOptions: {
                    ...options.backgroundOptions,
                    gradient: {
                      ...(options.backgroundOptions?.gradient as Gradient),
                      type: value as GradientType,
                    },
                  },
                })
              }
            />
          </Space>
        ) : (
          <input
            id="input-generator-bg-color"
            type="color"
            onChange={(e) =>
              setOptions({
                ...options,
                backgroundOptions: {
                  ...options.backgroundOptions,
                  color: e.currentTarget.value,
                },
              })
            }
            value={options.backgroundOptions?.color}
          />
        )}
      </Space>
    </Space>
  );
};

export default Background;
