import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ResponseError } from '@common/types';
import { errorHandler } from '@common/helpers';
import {
  ICallTriggerResponse,
  IGetIPResponse,
  fetchCallTrigger,
  fetchGetIP,
} from './service';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

function* onCallTriggerStart({
  payload,
  onSuccess,
}: actionTypes.FetchCallTriggerStart) {
  try {
    const { data: ip_info }: { data: IGetIPResponse } = yield call(fetchGetIP);

    console.log(ip_info);

    const { data }: { data: ICallTriggerResponse } = yield call(
      fetchCallTrigger,
      {
        ...payload,
        device_info: { ...payload.device_info },
        ip_info,
      }
    );

    yield put(actionCreators.fetchCallTriggerSuccess(data));
    onSuccess(data);
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);
    yield put(actionCreators.fetchCallTriggerFailure());
  }
}

export default function* linksSaga() {
  yield all([
    takeLatest(actionTypes.FETCH_CALL_TRIGGER_START, onCallTriggerStart),
  ]);
}
