import React from 'react';
import routing from '@routing/path';
import Button from '@components/UIkit/Button';
import Typography from '@components/UIkit/Typography';
import Headline from '@components/Headline';
import Space from '@components/UIkit/Space';

const { Paragraph } = Typography;

const ModalExpiryNotification: React.FC = () => {
  return (
    <Space direction="vertical" gap={20}>
      <Paragraph>Subscription Expiry Notification</Paragraph>

      <Space direction="vertical" gap={10}>
        <Paragraph size={2}>Dear User,</Paragraph>

        <Headline
          align="left"
          description={
            'We want to inform you that your subscription to our service is about to expire. We appreciate your use of our features for creating short links and QR codes.'
          }
        />
      </Space>

      <Button
        id="button-modal-renew-subscription"
        to={`${routing.private.profile.root}/${routing.private.profile.children.plans}`}
      >
        Renew Subscription
      </Button>
    </Space>
  );
};

export default React.memo(ModalExpiryNotification);
