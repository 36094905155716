import React from 'react';
import { Options } from 'qr-code-styling';
import Space from '@components/UIkit/Space';
import Separator from '@components/UIkit/Separator';
import Dots from './components/Dots';
import Background from './components/Background';
import CornersSquare from './components/CornersSquare';
import styles from './colors.module.scss';
import CornersDot from './components/CornersDot';

interface IColors {
  options: Options;
  setOptions: (options: Options) => void;
}

const Colors: React.FC<IColors> = (props) => {
  return (
    <Space direction="vertical" gap={20} className={styles.colors} fullWidth>
      <Dots {...props} />
      <Separator />
      <Background {...props} />
      <Separator />
      <CornersSquare {...props} />
      <Separator />
      <CornersDot {...props} />
    </Space>
  );
};

export default Colors;
