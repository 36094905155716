import React from 'react';
import routing from '@routing/path';
import { useModal } from '@hooks/useModal';
import Button from '@components/UIkit/Button';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';

const { Paragraph } = Typography;

const ModalRenewal: React.FC = () => {
  const { closeModal } = useModal();

  return (
    <Space direction="vertical" gap={20}>
      <Paragraph>Subscription Expiry Notification</Paragraph>

      <Space direction="vertical" gap={10}>
        <Paragraph size={2}>Dear User,</Paragraph>

        <Headline
          align="left"
          description="Your subscription is about to expire. Renew to continue enjoying our features:"
        />
      </Space>

      <Button
        id="button-modal-renewal-renew"
        to={`${routing.private.profile.root}/${routing.private.profile.children.plans}`}
        onClick={closeModal}
      >
        Renew Subscription
      </Button>

      <Headline
        align="left"
        description="If you do not plan to renew your subscription, please manage QR codes
        and links. After managing, ensure to activate the Free plan to stay
        within the limit of 3 items."
      />

      <Button
        id="button-modal-renewal-manage-links"
        to={`${routing.private.collection.root}/${routing.private.collection.children.link}`}
        onClick={closeModal}
      >
        Manage QR Codes and Links
      </Button>

      <Headline align="left" description="Thank you for using our service." />
    </Space>
  );
};

export default React.memo(ModalRenewal);
