import { SubscribeRequest } from './service';

export const FETCH_SUBSCRIBE_START =
  'subscribeActionTypes/FETCH_SUBSCRIBE_START';
export interface FetchSubscribeStart {
  type: typeof FETCH_SUBSCRIBE_START;
  payload: SubscribeRequest;
  onSuccess: () => void;
}

export const FETCH_SUBSCRIBE_SUCCESS =
  'subscribeActionTypes/FETCH_SUBSCRIBE_SUCCESS';
export interface FetchSubscribeSuccess {
  type: typeof FETCH_SUBSCRIBE_SUCCESS;
}

export const FETCH_SUBSCRIBE_FAILURE =
  'subscribeActionTypes/FETCH_SUBSCRIBE_FAILURE';
export interface FetchSubscribeFailure {
  type: typeof FETCH_SUBSCRIBE_FAILURE;
}

export type SubscribeAction =
  | FetchSubscribeStart
  | FetchSubscribeSuccess
  | FetchSubscribeFailure;
