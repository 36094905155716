import React from 'react';
import routing from '@routing/path';
import { useModal } from '@hooks/useModal';
import Button from '@components/UIkit/Button';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';

const { Paragraph } = Typography;

const ModalLimitReached: React.FC = () => {
  const { closeModal } = useModal();

  return (
    <Space direction="vertical" gap={20}>
      <Paragraph>Limit Reached</Paragraph>

      <Headline
        align="left"
        description="Your current plan has reached its limit. Upgrade your plan to increase the limits and access all features."
      />

      <Space gap={20} justify="start">
        <Button
          id="button-modal-limit-reached-upgrade-plan"
          to={`${routing.private.profile.root}/${routing.private.profile.children.plans}`}
          onClick={closeModal}
        >
          Upgrade Your Plan
        </Button>
        <Button
          id="button-modal-limit-reached-cancel"
          onClick={closeModal}
          type="default"
        >
          Cancel
        </Button>
      </Space>
    </Space>
  );
};

export default React.memo(ModalLimitReached);
