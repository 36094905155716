import React from 'react';
import routing from '@routing/path';
import DynamicIcons from '@assets/icons';
import Typography from '@components/UIkit/Typography';
import Container from '@components/UIkit/Container';
import Separator from '@components/UIkit/Separator';
import Space from '@components/UIkit/Space';
import { convertComponentToDataURL } from '@common/helpers';
import Icon from '@components/UIkit/Icon';
import styles from './styles.module.scss';

const { Text } = Typography;

const AppFooterContainer: React.FC = () => {
  return <AppFooter />;
};

const AppFooter = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <Space justify="between" align="middle" className={styles.content}>
          <Space gap={10} align="middle">
            <Icon name="MurlWhite" size="medium" />
            <div className={styles.logotype}>Murl</div>
          </Space>

          <Space gap={40} className={styles.sp}>
            <div className={styles.image}>
              <img
                src={convertComponentToDataURL(<DynamicIcons.MasterCard />)}
                alt="MasterCard"
              />
            </div>

            <div className={styles.image}>
              <img
                src={convertComponentToDataURL(<DynamicIcons.VisaCard />)}
                alt="Visa"
              />
            </div>

            <div className={styles.image}>
              <img
                src={convertComponentToDataURL(<DynamicIcons.MonoPay />)}
                alt="MonoPay"
              />
            </div>
          </Space>
        </Space>

        <Separator className={styles.separator} />

        <Space justify="between" align="middle" className={styles.content}>
          <Text size={3} className={styles.rights}>
            © {new Date().getFullYear()} Murl. Inc. All rights reserved
          </Text>

          <Space gap={20}>
            <Text size={3} className={styles.link} to={routing.public.home}>
              Private Policy
            </Text>

            <Text size={3} className={styles.link} to={routing.public.home}>
              Tems of Conditions
            </Text>
          </Space>
        </Space>
      </Container>
    </footer>
  );
};

export default React.memo(AppFooterContainer);
