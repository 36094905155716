import React from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import Notify from '@components/UIkit/Notify';
import styles from './test.module.scss';

const TestNotify = () => {
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Notify" />

      <Notify text="Notifictation type:" value="Success" />
    </Space>
  );
};

export default React.memo(TestNotify);
