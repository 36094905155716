import React from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import Typography from '@components/UIkit/Typography';
import styles from './test.module.scss';

const { Title, Paragraph, Text } = Typography;

const TestTypography = () => {
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Typography" />

      <Space className={styles.space} gap={20}>
        <Title>Title</Title>
      </Space>

      <Space className={styles.space} gap={20}>
        <Paragraph size={1}>Paragraph 1</Paragraph>
        <Paragraph size={2}>Paragraph 2</Paragraph>
      </Space>

      <Space className={styles.space} gap={20}>
        <Text size={1}>Text 1</Text>
        <Text size={2}>Text 2</Text>
        <Text size={3}>Text 3</Text>
      </Space>
    </Space>
  );
};

export default React.memo(TestTypography);
