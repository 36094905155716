import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Table, { ITableColumns } from '@components/UIkit/Table';
import Typography from '@components/UIkit/Typography';
import { fetchAdminGetTriggersStart } from '@core/admin/actionCreators';
import configSelector from '@core/configSelector';
import { OrderDirections } from '@core/admin/service';
import routing from '@routing/path';
import { formatDateTime } from '@common/helpers';
import Filters from './Filters';
import styles from '../admin.module.scss';

const { Text } = Typography;

const formatId = (id) => {
  if (id.length > 8) {
    return `${id.slice(0, 4)}****${id.slice(-4)}`;
  }
  return id;
};

interface ITriggersProps {
  setTotal: React.Dispatch<React.SetStateAction<number>>;
}

const Triggers: React.FC<ITriggersProps> = ({ setTotal }) => {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<{ value: string; key: string }>({
    value: '',
    key: '',
  });

  const take = 25;
  const [skip, setSkip] = useState(0);
  const [sortBy, setSortBy] = useState<{
    key: string;
    direction: OrderDirections;
  }>({
    key: 'updated_at',
    direction: 'Desc',
  });

  const { loader, triggers } = useSelector(configSelector.admin);

  const data = useMemo(() => {
    if (triggers) {
      setTotal(triggers.total_count);

      return {
        items: triggers.items,
        has_next: triggers.has_next,
      };
    } else {
      return {
        items: [],
        has_next: false,
      };
    }
  }, [triggers]);

  const columns: ITableColumns[] = [
    { key: 'id', label: 'ID', align: 'left', sortable: true },
    { key: 'owner_email', label: 'Email', align: 'left', sortable: true },
    { key: 'name', label: 'Name', align: 'left', sortable: true },
    { key: 'scenario_type', label: 'Type' },
    { key: 'interaction_count', label: 'Interac.', sortable: true },
    { key: 'last_interaction_at', label: 'Last interac.', sortable: true },

    { key: 'created_at', label: 'Created', sortable: true },
    { key: 'updated_at', label: 'Updated', sortable: true },
  ];

  const onGetData = useCallback(() => {
    dispatch(
      fetchAdminGetTriggersStart(
        {
          skip,
          take,
          order_by: sortBy.key,
          order_direction: sortBy.direction,
          filter: {
            [filters.key]: filters.value,
          },
        },
        skip === 0
      )
    );
  }, [skip, take, sortBy, filters]);

  useEffect(() => {
    setSkip(0);
  }, [sortBy, filters]);

  useEffect(() => {
    onGetData();
  }, [dispatch, onGetData]);

  const renderCell = (item, columnKey) => {
    let content = item[columnKey];

    if (
      columnKey === 'created_at' ||
      columnKey === 'updated_at' ||
      columnKey === 'last_interaction_at'
    ) {
      content = formatDateTime(content, true);
    }

    if (columnKey === 'scenario_type') {
      return (
        <>
          <Text
            size={3}
            font="Inter"
            className={classNames(styles.scenario_payload)}
          >
            {item.type === 'qr-code' ? 'QRc' : 'URL'}
          </Text>{' '}
          <Text
            size={3}
            font="Inter"
            className={classNames(
              styles.scenario_payload,
              styles[item.scenario_type]
            )}
          >
            {item.scenario_type}
          </Text>
        </>
      );
    }

    if (columnKey === 'id') {
      const formattedId = formatId(content);

      return (
        <Text
          size={3}
          font="Inter"
          className={styles.clickable}
          onClick={() =>
            window.open(
              `${routing.private.admin.root}/${routing.private.admin.children.trigger}/${item.id}`,
              '_blank'
            )
          }
        >
          {formattedId}
        </Text>
      );
    }

    return (
      <Text size={3} font="Inter">
        {content}
      </Text>
    );
  };

  const onNext = useCallback(() => {
    setSkip(skip + take);
  }, [skip]);

  return (
    <>
      <Filters
        onChange={setFilters}
        filters={[
          {
            value: 'owner_email',
            text: 'Owner email',
          },
          {
            value: 'type',
            text: 'Type',
          },
          {
            value: 'scenario_type',
            text: 'Scenario type',
          },
        ]}
      />

      <Table
        loader={loader}
        hasNext={data.has_next}
        onNext={onNext}
        onSort={setSortBy}
        sortBy={sortBy}
        data={data.items}
        columns={columns}
        renderCell={renderCell}
      />
    </>
  );
};

export default React.memo(Triggers);
