import * as actions from './actionTypes';
import { ICallTriggerResponse, ICallTriggerRequest } from './service';

export function fetchCallTriggerStart(
  payload: ICallTriggerRequest,
  onSuccess: (reponse: ICallTriggerResponse) => void
): actions.FetchCallTriggerStart {
  return {
    type: actions.FETCH_CALL_TRIGGER_START,
    payload,
    onSuccess,
  };
}

export function fetchCallTriggerSuccess(
  payload: ICallTriggerResponse
): actions.FetchCallTriggerSuccess {
  return {
    type: actions.FETCH_CALL_TRIGGER_SUCCESS,
    payload,
  };
}

export function fetchCallTriggerFailure(): actions.FetchCallTriggerFailure {
  return {
    type: actions.FETCH_CALL_TRIGGER_FAILURE,
  };
}
