import React, { useCallback } from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { fetchOAuthFailure, fetchOAuthStart } from '@core/auth/actionCreators';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import LogoBounce from '@components/LogoBounce';

interface SignInProps {
  onLogin: (credentialResponse: CredentialResponse) => void;
}

const SignInContainer = () => {
  const dispatch = useDispatch();

  const onLogin = useCallback((credentialResponse: CredentialResponse) => {
    const { credential } = credentialResponse;

    if (credential) {
      dispatch(fetchOAuthStart({ token: credential }));
    } else {
      dispatch(fetchOAuthFailure());
    }
  }, []);

  return <SignIn onLogin={onLogin} />;
};

const SignIn: React.FC<SignInProps> = ({ onLogin }) => {
  return (
    <Space direction="vertical" gap={40} align="middle">
      <LogoBounce />

      <Headline
        title="You are welcome!"
        description="Sign in with your Google account"
      />

      <GoogleLogin onSuccess={onLogin} />
    </Space>
  );
};

export default React.memo(SignInContainer);
