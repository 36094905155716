import React, { ReactNode } from 'react';
import styles from './createform.module.scss';

interface ICreateForm {
  children: ReactNode;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  name: string;
}

const CreateForm: React.FC<ICreateForm> = ({
  children,
  handleSubmit,
  name,
}) => {
  return (
    <form onSubmit={handleSubmit} name={name} className={styles.createForm}>
      {children}
    </form>
  );
};

export default CreateForm;
