import React from 'react';
import Table from '@components/UIkit/Table';
import Typography from '@components/UIkit/Typography';

const { Text } = Typography;

const Payments = () => {
  const data = [
    { name: 'John Doe', age: 28, address: '123 Main St' },
    { name: 'Jane Smith', age: 32, address: '456 Elm St' },
  ];

  const columns = [
    { key: 'id', label: 'ID' },
    { key: 'email', label: 'Email' },
    { key: 'tier', label: 'Tier' },
    { key: 'datetime', label: 'Datetime' },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      renderCell={(item, columnKey) => (
        <Text size={3} font="Inter">
          {item[columnKey]}
        </Text>
      )}
    />
  );
};

export default React.memo(Payments);
