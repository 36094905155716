/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import SwitchButton from '@components/UIkit/SwitchButton';
import styles from './test.module.scss';

const TestSwitchButton = () => {
  const [active, setActive] = useState(false);

  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Switch button" />

      <SwitchButton
        id="test-switch-button"
        value={active}
        onChange={setActive}
        text={'Use gradient ?'}
      />
    </Space>
  );
};

export default React.memo(TestSwitchButton);
