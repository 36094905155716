import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import QRCodeStyling, { FileExtension, Options } from 'qr-code-styling';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import Button from '@components/UIkit/Button';
import InputRange from '@components/UIkit/InputRange';
import { decrypt } from '@core/encryptor';
import Headline from '@components/Headline';
import Container from '@components/UIkit/Container';
import QRStylingItem from '@components/QRStylingItem';
import routing from '@routing/path';
import Correction from './Correction';
import Colors from './Colors';
import Shape from './Shape';
import Image from './Image';
import styles from './styles.module.scss';

interface IGenerator {
  data: string | undefined;
  options: Options;
  setOptions: (data: Options) => void;
}

const { Text } = Typography;

const initialState = {
  width: 1000, // +
  height: 1000, // +
  type: 'svg', // +
  margin: 100, // +
  image: undefined,
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.5, // +
    margin: 25,
  },
  shape: undefined, // +
  qrOptions: {
    errorCorrectionLevel: 'L', // +
  },
  dotsOptions: {
    type: undefined, // +
    color: '#000000', // +
    gradient: undefined, // +
  },
  cornersSquareOptions: {
    type: undefined, // +
    color: '#000000', // +
    gradient: undefined, // +
  },
  cornersDotOptions: {
    type: undefined, // +
    color: '#000000', // +
    gradient: undefined, // +
  }, // +
  backgroundOptions: {
    color: '#ffffff', // +
    gradient: undefined, // +
  }, // +
} as Options;

const GeneratorContainer: React.FC = () => {
  const { data } = useParams();
  const decryptedUrl = decrypt(data as string);

  const [options, setOptions] = useState<Options>({
    ...initialState,
    data: decryptedUrl,
  });

  return <Generator data={data} options={options} setOptions={setOptions} />;
};

const Generator: React.FC<IGenerator> = ({ data, options, setOptions }) => {
  const navigate = useNavigate();
  const qrCodeRef = useRef<QRCodeStyling | null>(null);
  const preResultRef = useRef<HTMLDivElement>(null);

  const [QRCode, setQRCode] = useState<JSX.Element | null>(null);
  const [elementLeft, setElementLeft] = useState(0);

  useEffect(() => {
    const updateElementPosition = () => {
      if (preResultRef.current) {
        const rect = preResultRef.current.getBoundingClientRect();
        setElementLeft(window.scrollY > 335 ? rect.left + window.scrollX : 0);
      }
    };

    updateElementPosition();

    const handleScroll = () => {
      updateElementPosition();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [preResultRef]);

  useEffect(() => {
    if (!data)
      return navigate(
        `${routing.public.create.root}/${routing.public.create.children.qrCode}`
      );

    const qrCodeInstance = new QRCodeStyling(options);

    qrCodeInstance
      .getRawData('svg')
      .then((blob) => {
        if (blob) {
          const dataURL = URL.createObjectURL(blob);
          setQRCode(<img src={dataURL} alt="QR Code" />);
        } else {
          console.error('Failed to generate QR code Blob');
        }
      })
      .catch((error) => {
        console.error('Error generating QR code:', error);
      });

    qrCodeRef.current = qrCodeInstance;
    return () => {
      qrCodeRef.current = null;
    };
  }, [data, options]);

  const onDownload = (format: FileExtension) => {
    if (qrCodeRef.current) {
      qrCodeRef.current.download({
        extension: format,
      });
    }
  };

  return (
    <Container>
      <Space direction="vertical" gap={60} className={styles.plans} fullWidth>
        <Space direction="vertical" gap={40} align="start" fullWidth>
          <Headline
            title="QR Code Generator"
            description="If you want to receive statistics on clicks, you must log in and create a QR code in your profile."
            align="left"
          />

          <Space
            align="start"
            justify="between"
            gap={40}
            fullWidth
            wrapped
            className={styles.wrapper}
          >
            <Space
              direction="vertical"
              className={styles.body}
              gap={20}
              fullWidth
            >
              <QRStylingItem
                isActive
                title="Correction"
                node={<Correction options={options} setOptions={setOptions} />}
              />
              <QRStylingItem
                title="Colors"
                node={<Colors options={options} setOptions={setOptions} />}
              />
              <QRStylingItem
                title="Shape"
                node={<Shape options={options} setOptions={setOptions} />}
              />
              <QRStylingItem
                title="Image"
                node={<Image options={options} setOptions={setOptions} />}
              />
            </Space>

            <Space className={styles.preResult} ref={preResultRef}>
              <Space
                style={{ left: elementLeft }}
                justify="start"
                align="start"
                direction="vertical"
                gap={20}
                className={classNames(styles.preResultContent, {
                  [styles.fixed]: elementLeft,
                })}
                fullWidth
              >
                <Space
                  align="middle"
                  justify="middle"
                  className={styles.QRCodeImageWrapper}
                  fullWidth
                >
                  {QRCode}
                </Space>

                <Space direction="vertical" gap={10} align="middle" fullWidth>
                  <InputRange
                    id="input-generator-qr-exported-size"
                    min={200}
                    max={2000}
                    step={50}
                    value={options.width || 200}
                    onChange={(size) =>
                      setOptions({
                        ...options,
                        width: size,
                        height: size,
                      })
                    }
                  />

                  <Space gap={10} align="middle" justify="between">
                    <Text className={styles.rangeValue} size={2} font="Inter">
                      Exported
                    </Text>

                    <Text className={styles.rangeValue} size={2} font="Inter">
                      {options.width} x {options.width} px
                    </Text>
                  </Space>
                </Space>

                <Space direction="vertical" gap={10} align="middle" fullWidth>
                  <InputRange
                    id="input-generator-qr-margin-size"
                    min={0}
                    max={200}
                    step={10}
                    value={options.margin || 0}
                    onChange={(margin) =>
                      setOptions({
                        ...options,
                        margin,
                      })
                    }
                  />

                  <Space gap={10} align="middle" justify="between">
                    <Text className={styles.rangeValue} size={2} font="Inter">
                      Margin
                    </Text>

                    <Text className={styles.rangeValue} size={2} font="Inter">
                      {options.margin} px
                    </Text>
                  </Space>
                </Space>

                <Space gap={10} fullWidth>
                  <Button
                    id="button-generator-export-jpeg"
                    size="small"
                    type="default"
                    onClick={() => onDownload('jpeg')}
                  >
                    Jpeg
                  </Button>

                  <Button
                    id="button-generator-export-png"
                    size="small"
                    type="default"
                    onClick={() => onDownload('png')}
                  >
                    Png
                  </Button>

                  <Button
                    id="button-generator-export-svg"
                    size="small"
                    type="default"
                    onClick={() => onDownload('svg')}
                  >
                    Svg
                  </Button>

                  <Button
                    id="button-generator-export-webp"
                    size="small"
                    type="default"
                    onClick={() => onDownload('webp')}
                  >
                    Webp
                  </Button>
                </Space>
              </Space>
            </Space>
          </Space>
        </Space>
      </Space>
    </Container>
  );
};

export default React.memo(GeneratorContainer);
