import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as actions from './actionTypes';

export interface PaymentState {
  loader: boolean;
}

const initialState: PaymentState = {
  loader: false,
};

const paymentReducer = (
  state: PaymentState = initialState,
  action: actions.PaymentAction
): PaymentState => {
  switch (action.type) {
    case actions.FETCH_BUY_FREE_SUBSCRIPTION_START:
    case actions.FETCH_BUY_SUBSCRIPTION_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_BUY_FREE_SUBSCRIPTION_SUCCESS:
    case actions.FETCH_BUY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loader: false,
      };

    case actions.FETCH_BUY_FREE_SUBSCRIPTION_FAILURE:
    case actions.FETCH_BUY_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'murl_payment',
  storage,
};

export default persistReducer(persistConfig, paymentReducer);
