import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import routing from '@routing/path';
import configSelector from '@configSelector';
import { fetchGetInteractionStart } from '@core/interaction/actionCreators';
import BackButton from '@components/BackButton';
import Container from '@components/UIkit/Container';
import CollectionCardFull from '@components/CollectionCardFull';
import { fetchGetTriggerByIdStart } from '@core/triggers/actionCreators';
import Charts from './components/Data';

interface IDashboard {
  isQRCode: boolean;
}

const Dashboard: React.FC<IDashboard> = ({ isQRCode }) => {
  const { id = '' } = useParams();
  const dispatch = useDispatch();

  const { data: interactionData, loader: interactionLoader } = useSelector(
    configSelector.interaction
  );

  const { data: triggerData, loader: triggerLoader } = useSelector(
    configSelector.triggerById
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchGetTriggerByIdStart({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (triggerData?.id)
      dispatch(fetchGetInteractionStart({ id: triggerData?.id }));
  }, [dispatch, triggerData?.id]);

  return (
    <Container>
      {interactionData && triggerData ? (
        <>
          <BackButton
            text={isQRCode ? 'QR Codes' : 'Short Links'}
            to={
              isQRCode
                ? `${routing.private.collection.root}/${routing.private.collection.children.qrCode}`
                : `${routing.private.collection.root}/${routing.private.collection.children.link}`
            }
          />

          {!triggerLoader && <CollectionCardFull data={triggerData} />}

          {!interactionLoader && (
            <Charts interactionData={interactionData} isQRCode={isQRCode} />
          )}
        </>
      ) : interactionLoader || triggerLoader ? (
        'Loader'
      ) : (
        'This item is not exist'
      )}
    </Container>
  );
};

export default React.memo(Dashboard);
