import React, { useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Typography from '@components/UIkit/Typography';
import { IFAQ } from '@common/types';
import Space from '@components/UIkit/Space';
import styles from './faqitem.module.scss';

const { Text } = Typography;

const FAQItem: React.FC<{ data: IFAQ }> = ({ data }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [active, setActive] = useState(false);
  const [currentHeight, setCurrentHeight] = useState(0);

  useLayoutEffect(() => {
    if (ref.current) {
      const updateHeight = () => {
        if (ref.current) {
          setCurrentHeight(ref.current.scrollHeight + 20);
        }
      };

      requestAnimationFrame(updateHeight);
    }
  }, [active]);

  return (
    <Space direction="vertical" className={styles.item} fullWidth>
      <div className={styles.itemTitle} onClick={() => setActive(!active)}>
        <Text size={2} className={styles.itemTitleContent}>
          {data.title}
        </Text>
        <div
          className={classNames(styles.action, { [styles.active]: active })}
        />
      </div>

      <div
        className={classNames(styles.itemText, { [styles.active]: active })}
        style={{ height: active ? currentHeight : 0 }}
        ref={ref}
      >
        <Text size={2} className={styles.content}>
          {data.text}
        </Text>
      </div>
    </Space>
  );
};

export default FAQItem;
