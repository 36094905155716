import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ResponseError } from '@common/types';
import { errorHandler } from '@common/helpers';
import { ITriggerInterface } from '@core/triggers/service';
import { GetInteractionResponse } from '@core/interaction/service';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';
import {
  AdminGetTriggerAllStatsResponse,
  AdminGetTriggersResponse,
  AdminGetUsersResponse,
  fetchAdminGetTriggerAllStats,
  fetchAdminGetTriggerById,
  fetchAdminGetTriggerInteractionById,
  fetchAdminGetTriggers,
  fetchAdminGetUsers,
} from './service';

// Users

function* onAdminGetUsersStart({
  payload,
  is_first,
}: actionTypes.FetchAdminGetUsersStart) {
  try {
    const { data }: { data: AdminGetUsersResponse } = yield call(
      fetchAdminGetUsers,
      payload
    );

    yield put(actionCreators.fetchAdminGetUsersSuccess(data, is_first));
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchAdminGetUsersFailure());
  }
}

// Triggers

function* onAdminGetTriggersStart({
  payload,
  is_first,
}: actionTypes.FetchAdminGetTriggersStart) {
  try {
    const { data }: { data: AdminGetTriggersResponse } = yield call(
      fetchAdminGetTriggers,
      payload
    );

    yield put(actionCreators.fetchAdminGetTriggersSuccess(data, is_first));
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchAdminGetTriggersFailure());
  }
}

// Trigger by Id

function* onAdminGetTriggerByIdStart({
  payload,
}: actionTypes.FetchAdminGetTriggerByIdStart) {
  try {
    const [{ data }, { data: interactionData }]: [
      { data: ITriggerInterface },
      { data: GetInteractionResponse }
    ] = yield all([
      call(fetchAdminGetTriggerById, payload),
      call(fetchAdminGetTriggerInteractionById, payload),
    ]);

    yield put(
      actionCreators.fetchAdminGetTriggerByIdSuccess({
        ...data,
        interactionData,
      })
    );
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchAdminGetTriggerByIdFailure());
  }
}

// Trigger all Stats

function* onAdminGetTriggerAllStatsStart({
  payload,
}: actionTypes.FetchAdminGetTriggerAllStatsStart) {
  try {
    const { data }: { data: AdminGetTriggerAllStatsResponse } = yield call(
      fetchAdminGetTriggerAllStats,
      payload
    );

    yield put(actionCreators.fetchAdminGetTriggerAllStatsSuccess(data));
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);

    yield put(actionCreators.fetchAdminGetTriggerAllStatsFailure());
  }
}

export default function* adminSaga() {
  yield all([
    takeLatest(actionTypes.FETCH_ADMIN_GET_USERS_START, onAdminGetUsersStart),
    takeLatest(
      actionTypes.FETCH_ADMIN_GET_TRIGGERS_START,
      onAdminGetTriggersStart
    ),
    takeLatest(
      actionTypes.FETCH_ADMIN_GET_TRIGGER_BY_ID_START,
      onAdminGetTriggerByIdStart
    ),
    takeLatest(
      actionTypes.FETCH_ADMIN_GET_TRIGGER_ALL_STATS_START,
      onAdminGetTriggerAllStatsStart
    ),
  ]);
}
