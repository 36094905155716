import React, { useState, ChangeEvent } from 'react';
import styles from './inputrange.module.scss';

interface IInputRange {
  id: string;
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
}

const InputRange: React.FC<IInputRange> = ({
  id,
  min,
  max,
  step,
  value,
  onChange,
}) => {
  const [rangeValue, setRangeValue] = useState(value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setRangeValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={styles.rangeWrapper}>
      <input
        id={id}
        type="range"
        min={min}
        max={max}
        step={step}
        value={rangeValue}
        onChange={handleChange}
        className={styles.range}
      />
    </div>
  );
};

export default React.memo(InputRange);
