import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { GetMyViewResponse } from '@common/types';
import * as actions from './actionTypes';

export interface ProfileState {
  data: GetMyViewResponse;
  loader: boolean;
}

const initialState: ProfileState = {
  data: {
    id: '',
    email: '',
    subscription: {
      tier: 'Free',
      actual_to: 0,
      limit: 0,
    },
  },
  loader: false,
};

const profileReducer = (
  state: ProfileState = initialState,
  action: actions.AuthAction
): ProfileState => {
  switch (action.type) {
    case actions.FETCH_GET_MY_VIEW_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_GET_MY_VIEW_SUCCESS:
      return {
        ...state,
        loader: false,
        data: { ...action.payload },
      };

    case actions.FETCH_GET_MY_VIEW_FAILURE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'murl_profile',
  storage,
};

export default persistReducer(persistConfig, profileReducer);
