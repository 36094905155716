/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback, SetStateAction, Dispatch } from 'react';
import classNames from 'classnames';
import { OrderDirections } from '@core/admin/service';
import Loader from '@components/Loader';
import Typography from '../Typography';
import styles from './table.module.scss';
import Icon from '../Icon';

type TextAlign = 'left' | 'center' | 'right';

export interface ITableColumns {
  key: string;
  label: string;
  align?: TextAlign;
  sortable?: boolean;
}

interface ITableProps {
  loader?: boolean;
  hasNext?: boolean;
  onNext?: () => any;
  onSort?: Dispatch<
    SetStateAction<{ key: string; direction: OrderDirections }>
  >;
  sortBy?: {
    key: string;
    direction: OrderDirections;
  };
  data: any[];
  columns: ITableColumns[];
  renderCell: (item: any, columnKey: string) => React.ReactNode;
}

const { Text } = Typography;

const Table: React.FC<ITableProps> = ({
  loader,
  hasNext,
  onNext,
  onSort,
  sortBy,
  data,
  columns,
  renderCell,
}) => {
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight / 0.9;
    const documentHeight = document.documentElement.scrollHeight;

    if (
      scrollTop + windowHeight >= documentHeight &&
      hasNext &&
      onNext &&
      !loader
    ) {
      onNext();
    }
  }, [hasNext, onNext, loader]);

  useEffect(() => {
    let ticking = false;

    const throttledHandleScroll = () => {
      if (!ticking) {
        ticking = true;
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
      }
    };

    window.addEventListener('scroll', throttledHandleScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [handleScroll]);

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                className={classNames(
                  styles.tableHeaderCell,
                  styles[column.align || 'center']
                )}
              >
                <Text
                  size={3}
                  font="Inter"
                  onClick={() =>
                    column?.sortable && onSort
                      ? onSort({
                          key: column.key,
                          direction:
                            sortBy?.key === column.key
                              ? sortBy?.direction === 'Asc'
                                ? 'Desc'
                                : 'Asc'
                              : 'Desc',
                        })
                      : undefined
                  }
                  className={classNames(styles.tableHeaderCellContent, {
                    [styles.sortable]: !!column?.sortable,
                  })}
                >
                  {column?.sortable && (
                    <Icon
                      name={
                        sortBy?.key === column.key &&
                        sortBy?.direction === 'Asc'
                          ? 'ArrowUpSm'
                          : 'ArrowDownSm'
                      }
                      color={sortBy?.key === column.key ? '#00FF75' : '#424242'}
                      size="small"
                    />
                  )}

                  {column.label}
                </Text>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => (
            <tr key={index} className={styles.tableRow}>
              {columns.map((column) => (
                <td
                  key={column.key}
                  className={classNames(
                    styles.tableCell,
                    styles[column.align || 'center']
                  )}
                >
                  {renderCell(item, column.key)}
                </td>
              ))}
            </tr>
          ))}

          {loader ? (
            <tr className={classNames(styles.tableRow, styles.loading)}>
              <td colSpan={columns.length}>
                <Loader />
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(Table);
