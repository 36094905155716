/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { FieldProps } from 'formik';
import Typography from '@components/UIkit/Typography';
import Button from '@components/UIkit/Button';
import styles from './styles.module.scss';

interface FormControlFieldProps extends FieldProps {
  id: string;
  disabled?: boolean;
  title?: string;
  className: string;
  placeholder: string;
  type: 'text' | 'password' | 'email' | 'number';
  children: ReactNode;
  optional: boolean;
  suffixIcon;
  suffix: {
    type: 'button';
    node: ReactNode;
    submit?: boolean;
    disabled?: boolean;
    onClick?: (...args: any[]) => void;
  };
}

const { Paragraph } = Typography;

const FormControl: React.FC<FormControlFieldProps> = ({
  disabled = false,
  title,
  form,
  field,
  children,
  suffix,
  suffixIcon,
  className,
}) => {
  const errors = form.errors[field.name];
  const touched = form.touched[field.name];

  return (
    <div
      className={classNames(
        styles.field,
        {
          [styles.error]: errors && touched,
          [styles.hasSuffix]: suffix,
          [styles.disabled]: disabled,
          [styles.hasSuffixIcon]: suffixIcon,
        },
        className
      )}
    >
      {title && (
        <Paragraph size={2} className={styles.fieldTitle}>
          {title}
        </Paragraph>
      )}
      {children}
    </div>
  );
};

export const Input: React.FC<FormControlFieldProps> = (props) => {
  return (
    <FormControl {...props}>
      <input
        className={styles.input}
        {...props.field}
        type={props.type}
        placeholder={props.placeholder}
        id={props.id}
      />

      {props.suffixIcon && (
        <div className={styles.suffixIcon}>{props.suffixIcon}</div>
      )}

      {props.suffix && (
        <div className={styles.suffix}>
          <Button
            id={`${props.id}-button`}
            submit={props.suffix.submit}
            fullWidth
            disabled={!props.form.isValid || props.suffix.disabled}
          >
            Subscribe
          </Button>
        </div>
      )}
    </FormControl>
  );
};
