import React from 'react';
import routing from '@routing/path';
import { useModal } from '@hooks/useModal';
import Button from '@components/UIkit/Button';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';

const { Paragraph } = Typography;

const ModalSubscriptionLimitation: React.FC = () => {
  const { closeModal } = useModal();

  return (
    <Space direction="vertical" gap={20}>
      <Paragraph>Subscription Limitation</Paragraph>

      <Headline
        align="left"
        description="Apologies, but you cannot purchase this subscription at the moment. You have too many active QR codes and links."
      />

      <Headline
        align="left"
        description="Please remove the excess to comply with the limits of this subscription. Once done, you can proceed with the purchase. Thank you for your understanding!"
      />

      <Button
        id="button-modal-sub-limit-manage-links"
        to={`${routing.private.collection.root}/${routing.private.collection.children.link}`}
        onClick={closeModal}
      >
        Manage QR Codes and Links
      </Button>
    </Space>
  );
};

export default React.memo(ModalSubscriptionLimitation);
