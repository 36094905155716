import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routing from '@routing/path';
import configSelector from '@configSelector';
import Wrapper from '@components/Wrapper';

const PrivateRoute: React.FC = () => {
  const isAuth = useSelector(configSelector.isAuth);

  return isAuth ? (
    <Wrapper>
      <Outlet />
    </Wrapper>
  ) : (
    <Navigate to={routing.public.signin} replace />
  );
};

export default React.memo(PrivateRoute);
