import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as actions from './actionTypes';

export interface SubscribeState {
  loader: boolean;
}

const initialState: SubscribeState = {
  loader: false,
};

const subscribeReducer = (
  state: SubscribeState = initialState,
  action: actions.SubscribeAction
): SubscribeState => {
  switch (action.type) {
    case actions.FETCH_SUBSCRIBE_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loader: false,
      };

    case actions.FETCH_SUBSCRIBE_FAILURE:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'murl_subscribe',
  storage,
};

export default persistReducer(persistConfig, subscribeReducer);
