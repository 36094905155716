import React from 'react';
import { Field, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import configSelector from '@configSelector';
import { Input } from '@components/FormControl';
import Headline from '@components/Headline';
import { SubscribeRequest } from '@core/subscribe/service';
import { fetchSubscribeStart } from '@core/subscribe/actionCreators';
import Space from '@components/UIkit/Space';
import LogoBounce from '@components/LogoBounce';
import styles from './styles.module.scss';

interface SubscribeFormProps {
  loader: boolean;
  onSubmit: (
    values: SubscribeRequest,
    formikHelpers: FormikHelpers<SubscribeRequest>
  ) => void;
}

const SubscribeContainer: React.FC = () => {
  const { loader } = useSelector(configSelector.subscribe);
  const dispatch = useDispatch();

  const onSubmit = (
    data: SubscribeRequest,
    helpers: FormikHelpers<SubscribeRequest>
  ) => {
    dispatch(fetchSubscribeStart(data, helpers.resetForm));
  };

  return <Subscribe onSubmit={onSubmit} loader={loader} />;
};

const Subscribe: React.FC<SubscribeFormProps> = ({ loader, onSubmit }) => {
  const initialValues: SubscribeRequest = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('The "Email" field is required'),
  });

  return (
    <Space
      className={styles.subscribe}
      direction="vertical"
      align="middle"
      gap={40}
    >
      <LogoBounce />

      <Headline
        className={styles.title}
        title="Stay up to date!"
        description="Subscribe to our updates and stay informed with the latest news!"
      />

      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{ ...initialValues }}
        onSubmit={(values, formikHelpers) =>
          onSubmit(values as SubscribeRequest, formikHelpers)
        }
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            name={'subscribeForm'}
            className={styles.form}
          >
            <Field
              name={'email'}
              placeholder={'Your account email'}
              component={Input}
              id="input-subscribe-form"
              suffix={{
                disabled: loader,
              }}
            />
          </form>
        )}
      </Formik>
    </Space>
  );
};

export default React.memo(SubscribeContainer);
