import React, { useCallback } from 'react';
import { Element } from 'react-scroll';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routing from '@routing/path';
import configSelector from '@configSelector';
import { plansData } from '@common/types';
import Headline from '@components/Headline';
import Plan from '@components/Plan';
import Space from '@components/UIkit/Space';

interface PlansPropTypes {
  onPlanSelect: () => void;
}

const PlansContainer: React.FC = () => {
  const navigate = useNavigate();
  const isAuth = useSelector(configSelector.isAuth);

  const onPlanSelect = useCallback(() => {
    if (isAuth) {
      navigate(
        `${routing.private.profile.root}/${routing.private.profile.children.plans}`
      );
    } else {
      navigate(routing.public.signin);
    }
  }, [isAuth]);

  return <Plans onPlanSelect={onPlanSelect} />;
};

const Plans: React.FC<PlansPropTypes> = ({ onPlanSelect }) => {
  return (
    <Element name="homePricing">
      <Space direction="vertical" align="middle" gap={40}>
        <Headline
          title="Subscription Plans"
          description="We provide a very simple and transparent pricing model"
        />

        <Space wrapped gap={20} justify="between" align="normal">
          {plansData.map((p) => (
            <Plan key={p.name} data={p} onPlanSelect={onPlanSelect} />
          ))}
        </Space>
      </Space>
    </Element>
  );
};

export default React.memo(PlansContainer);
