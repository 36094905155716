import React from 'react';
import { NavLink } from 'react-router-dom';
import Typography from '@components/UIkit/Typography';
import Icon from '@components/UIkit/Icon';
import styles from './styles.module.scss';

interface BackButtonProps {
  text: string;
  to: string;
}

const { Text } = Typography;

const BackButtonContainer: React.FC<BackButtonProps> = ({ text, to }) => {
  return <BackButton text={text} to={to} />;
};

const BackButton: React.FC<BackButtonProps> = ({ text, to }) => {
  return (
    <NavLink className={styles.backButton} to={to} id="back-button">
      <Icon name="ArrowLeft" color="#FFFFFF" size="medium" />
      <Text size={2}>Back to {text}</Text>
    </NavLink>
  );
};

export default React.memo(BackButtonContainer);
