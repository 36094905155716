import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { TNavItem } from '@common/types';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import Icon from '@components/UIkit//Icon';
import styles from './navbar.module.scss';

const { Text } = Typography;

interface INavBar {
  id: string;
  items: TNavItem[];
  suffix?: ReactNode;
  onSelect?: (item: TNavItem) => void;
}

interface IItem {
  id: string;
  item: TNavItem;
  isActive: boolean;
  onItemChange: (item: TNavItem) => void;
}

const NavBar: React.FC<INavBar> = ({ id, items, suffix, onSelect }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const onItemChange = (item: TNavItem) => {
    if (onSelect) onSelect(item);
    if (item.to) {
      navigate(item.to);
    }
  };

  return (
    <Space className={styles.navBar} align="middle" gap={40}>
      {items.map((item) => (
        <Item
          id={id}
          key={item.to}
          item={item}
          isActive={pathname === item.to}
          onItemChange={onItemChange}
        />
      ))}

      {suffix && <div className={styles.suffix}>{suffix}</div>}
    </Space>
  );
};

const Item = ({ id, item, isActive, onItemChange }: IItem) => {
  const [isHovered, setIsHovered] = useState(false);
  const itemRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const itemElement = itemRef.current;

    if (itemElement) {
      itemElement.addEventListener('mouseenter', handleMouseEnter);
      itemElement.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        itemElement.removeEventListener('mouseenter', handleMouseEnter);
        itemElement.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  return (
    <div
      ref={itemRef}
      className={classNames(styles.navItem, {
        [styles.active]: isActive,
        [styles.hovered]: isHovered,
      })}
      onClick={() => onItemChange(item)}
      id={`${id}-button-${item.text.toLowerCase()}`}
    >
      {item.icon && (
        <Icon
          size="small"
          name={item.icon}
          color={isActive || isHovered ? '#FFFFFF' : '#BDBDBD'}
        />
      )}

      <Text size={2} className={styles.navItemText}>
        {item.text}
      </Text>
    </div>
  );
};

export default NavBar;
