// reducers.ts
import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import callReducer from './call/reducer';
import profileReducer from './profile/reducer';
import subscribeReducer from './subscribe/reducer';
import triggersReducer from './triggers/reducer';
import paymentReducer from './payment/reducer';
import interactionReducer from './interaction/reducer';
import adminReducer from './admin/reducer';

const rootReducer = combineReducers({
  admin: adminReducer,
  auth: authReducer,
  call: callReducer,
  triggers: triggersReducer,
  profile: profileReducer,
  subscribe: subscribeReducer,
  payment: paymentReducer,
  interaction: interactionReducer,
});

export default rootReducer;
