import instance from '@core/api';

export interface BuySubscriptionRequest {
  months: number;
  tier:
    | 'Free'
    | 'Standard'
    | 'RegularUser'
    | 'Professional'
    | 'WebApp'
    | 'Custom';
}

export type BuySubscriptionResponse = {
  pay_page: string;
};

export async function fetchBuyFreeSubscription(): Promise<BuySubscriptionResponse> {
  return await instance.post(`me/buy-free`);
}

export async function fetchBuySubscription(
  request: BuySubscriptionRequest
): Promise<BuySubscriptionResponse> {
  return await instance.post(`me/buy-subscription`, request);
}
