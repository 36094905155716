import * as actions from './actionTypes';
import { GetInteractionRequest, GetInteractionResponse } from './service';

export function fetchGetInteractionStart(
  payload: GetInteractionRequest
): actions.FetchGetInteractionStart {
  return {
    type: actions.FETCH_GET_INTERACTION_START,
    payload,
  };
}

export function fetchGetInteractionSuccess(
  payload: GetInteractionResponse
): actions.FetchGetInteractionSuccess {
  return {
    type: actions.FETCH_GET_INTERACTION_SUCCESS,
    payload,
  };
}

export function fetchGetInteractionFailure(): actions.FetchGetInteractionFailure {
  return {
    type: actions.FETCH_GET_INTERACTION_FAILURE,
  };
}
