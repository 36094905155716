/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { IMenuAction } from '@common/types';
import Typography from '@UIkit/Typography';
import Space from '@UIkit/Space';
import Icon from '@UIkit/Icon';
import styles from './menu.module.scss';

interface IMenu {
  isChildren?: boolean;
  isOpen: boolean;
  setIsOpen: (s: boolean) => void;
  items: IMenuAction[];
  wrapperRef?: RefObject<any>;
  forwardRef?: RefObject<any>;
}

const { Text } = Typography;

const Menu: React.FC<IMenu> = ({
  isChildren = false,
  setIsOpen,
  isOpen,
  items,
  wrapperRef = null,
  forwardRef = null,
}) => {
  useEffect(() => {
    const handleMouseEnter = () => {
      setIsOpen(true);
    };

    const handleMouseLeave = () => {
      setIsOpen(false);
    };

    const wrapper = wrapperRef?.current;

    if (wrapper) {
      wrapper.addEventListener('mouseenter', handleMouseEnter);
      wrapper.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        wrapper.removeEventListener('mouseenter', handleMouseEnter);
        wrapper.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [setIsOpen, wrapperRef]);

  return (
    <div
      className={classNames(styles.menu, {
        [styles.active]: isOpen,
        [styles.isChildren]: isChildren,
      })}
      ref={wrapperRef}
    >
      <Space
        direction="vertical"
        align="normal"
        justify="normal"
        gap={5}
        className={styles.wrapper}
        ref={forwardRef}
      >
        {[...items]
          .sort(
            (a: IMenuAction, b: IMenuAction) => (a?.key || 0) - (b?.key || 0)
          )
          .map((action) => (
            <MenuItem {...action} />
          ))}
      </Space>
    </div>
  );
};

const MenuItem: React.FC<IMenuAction> = ({ onClick, icon, text, children }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleMouseEnter = () => {
      setIsOpen(true);
    };

    const handleMouseLeave = () => {
      setIsOpen(false);
    };

    const wrapper = wrapperRef.current;

    if (wrapper) {
      wrapper.addEventListener('mouseenter', handleMouseEnter);
      wrapper.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        wrapper.removeEventListener('mouseenter', handleMouseEnter);
        wrapper.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [setIsOpen, wrapperRef]);

  return (
    <Space
      onClick={onClick}
      className={classNames(styles.button, { [styles.relative]: !!children })}
      gap={10}
      ref={wrapperRef}
      align="middle"
    >
      {icon && <Icon size="small" name={icon} />}
      <Text size={2}> {text}</Text>
      {children && (
        <Menu
          isChildren
          isOpen={isOpen}
          items={children}
          setIsOpen={setIsOpen}
          wrapperRef={wrapperRef}
        />
      )}
    </Space>
  );
};

export default Menu;
