/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './typography.module.scss';

interface ITypography {
  children: ReactNode;
  className?: string;
  onClick?: (...args: any[]) => void;
  to?: string;
  font?: 'Raleway' | 'Inter';
}

interface ITitle extends ITypography {
  size?: 1;
}

interface IParagraph extends ITypography {
  size?: 1 | 2;
}

interface IText extends ITypography {
  size?: 1 | 2 | 3;
}

const Title: React.FC<ITitle> = ({
  children,
  className,
  onClick,
  to,
  size = 1,
  font = 'Raleway',
}) => {
  if (to) {
    return (
      <NavLink
        onClick={onClick}
        className={classNames(
          styles.text,
          styles[`size_${size}`],
          styles[font],
          className
        )}
        to={to}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <h1
      className={classNames(
        styles.title,
        styles[`size_${size}`],
        styles[font],
        className
      )}
      onClick={onClick}
    >
      {children}
    </h1>
  );
};

const Paragraph: React.FC<IParagraph> = ({
  children,
  className,
  onClick,
  to,
  size = 1,
  font = 'Raleway',
}) => {
  if (to) {
    return (
      <NavLink
        onClick={onClick}
        className={classNames(
          styles.text,
          styles[`size_${size}`],
          styles[font],
          className
        )}
        to={to}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <p
      className={classNames(
        styles.paragraph,
        styles[`size_${size}`],
        styles[font],
        className
      )}
      onClick={onClick}
    >
      {children}
    </p>
  );
};

const Text: React.FC<IText> = ({
  children,
  className,
  onClick,
  to,
  size = 1,
  font = 'Raleway',
}) => {
  if (to) {
    return (
      <NavLink
        onClick={onClick}
        className={classNames(
          styles.text,
          styles[`size_${size}`],
          styles[font],
          className
        )}
        to={to}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <span
      className={classNames(
        styles.text,
        styles[`size_${size}`],
        styles[font],
        className
      )}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default { Title, Paragraph, Text };
