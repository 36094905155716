import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table, { ITableColumns } from '@components/UIkit/Table';
import Typography from '@components/UIkit/Typography';
import { fetchAdminGetUsersStart } from '@core/admin/actionCreators';
import configSelector from '@core/configSelector';
import { copyLinkToClipboard, formatDateTime } from '@common/helpers';
import { OrderDirections } from '@core/admin/service';
import styles from '../admin.module.scss';
import Filters from './Filters';

const { Text } = Typography;

const formatId = (id) => {
  if (id.length > 8) {
    return `${id.slice(0, 4)}****${id.slice(-4)}`;
  }
  return id;
};

interface IUsersProps {
  setTotal: React.Dispatch<React.SetStateAction<number>>;
}

const Users: React.FC<IUsersProps> = ({ setTotal }) => {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<{ value: string; key: string }>({
    value: '',
    key: '',
  });

  const take = 25;
  const [skip, setSkip] = useState(0);
  const [sortBy, setSortBy] = useState<{
    key: string;
    direction: OrderDirections;
  }>({
    key: 'registered_at',
    direction: 'Desc',
  });

  const { loader, users } = useSelector(configSelector.admin);

  const data = useMemo(() => {
    if (users) {
      setTotal(users.total_count);

      return {
        items: users.items,
        has_next: users.has_next,
      };
    } else {
      return {
        items: [],
        has_next: true,
      };
    }
  }, [users]);

  const columns: ITableColumns[] = [
    { key: 'id', label: 'ID', align: 'left', sortable: true },
    { key: 'email', label: 'Email', align: 'left', sortable: true },
    { key: 'trigger_count', label: 'Triggers', sortable: true },
    { key: 'interaction_count', label: 'Interactions', sortable: true },
    { key: 'successful_purchases', label: 'Purchases', sortable: true },
    { key: 'tier', label: 'Tier', sortable: true },
    { key: 'actual_to', label: 'Actual to', sortable: true },
    { key: 'registered_at', label: 'Registered', sortable: true },
  ];

  const onGetData = useCallback(() => {
    dispatch(
      fetchAdminGetUsersStart(
        {
          skip,
          take,
          order_by: sortBy.key,
          order_direction: sortBy.direction,
          filter: {
            [filters.key]: filters.value,
          },
        },
        skip === 0
      )
    );
  }, [skip, take, sortBy, filters]);

  useEffect(() => {
    setSkip(0);
  }, [sortBy, filters]);

  useEffect(() => {
    onGetData();
  }, [dispatch, onGetData]);

  const renderCell = (item, columnKey) => {
    let content = item[columnKey];

    if (columnKey === 'registered_at' || columnKey === 'actual_to') {
      if (content === '9999-12-31T23:59:59.9999999+00:00') {
        content = 'No expiration';
      } else {
        content = formatDateTime(content, true);
      }
    }

    if (columnKey === 'id') {
      const formattedId = formatId(content);

      return (
        <Text
          size={3}
          font="Inter"
          className={styles.clickable}
          onClick={() => copyLinkToClipboard(content)}
        >
          {formattedId}
        </Text>
      );
    }

    return (
      <Text size={3} font="Inter">
        {content}
      </Text>
    );
  };

  const onNext = useCallback(() => {
    setSkip(skip + take);
  }, [skip]);

  return (
    <>
      <Filters
        onChange={setFilters}
        filters={[
          {
            value: 'email',
            text: 'Email',
          },
        ]}
      />

      <Table
        loader={loader}
        hasNext={data.has_next}
        onNext={onNext}
        onSort={setSortBy}
        sortBy={sortBy}
        data={data.items}
        columns={columns}
        renderCell={renderCell}
      />
    </>
  );
};

export default React.memo(Users);
