import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientJS } from 'clientjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import routing from '@routing/path';
import configSelector from '@configSelector';
import { Redirect } from '@components/Redirect';
import { fetchCallTriggerStart } from '@core/call/actionCreators';
import { ICallTriggerResponse } from '@core/call/service';
import LinkInBio from '@components/LinkInBio';

const TriggerCall: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { key } = useParams();
  const { loader } = useSelector(configSelector.call);

  const device_info = useMemo(() => {
    const client = new ClientJS();

    return {
      browser: String(client.getBrowser()),
      os_name: String(client.getOS()),
      osVersion: String(client.getOSVersion()),
      isMobile: String(client.isMobile()),
      fingerprint: String(client.getFingerprint()),
      currentResolution: String(client.getCurrentResolution()),
      browserVersion: String(client.getBrowserVersion()),
    };
  }, [key]);

  const [linkToNavigateResponse, setLinkToNavigateResponse] =
    useState<ICallTriggerResponse>();

  useEffect(() => {
    if (location.pathname.startsWith('/app')) {
      navigate(routing.public.signin);
    } else {
      if (key) {
        dispatch(
          fetchCallTriggerStart({ key, device_info }, (response) =>
            setLinkToNavigateResponse(response)
          )
        );
      }
    }
  }, [key]);

  if (loader) {
    return <></>;
  } else if (linkToNavigateResponse) {
    if (linkToNavigateResponse.type === 'not-active') {
      return <Redirect.RedirectFailurePage />;
    }

    if (linkToNavigateResponse.type === 'open-url') {
      document.location.href =
        linkToNavigateResponse.payload.original_url || '';
    }

    if (linkToNavigateResponse.type === 'free-open-url') {
      return (
        <Redirect.RedirectSuccessPage
          linkToNavigate={linkToNavigateResponse.payload.original_url || ''}
        />
      );
    }

    if (linkToNavigateResponse.type === 'link-in-bio') {
      return <LinkInBio layout={linkToNavigateResponse.payload.layout || []} />;
    }

    if (linkToNavigateResponse.type === 'free-link-in-bio') {
      return (
        <Redirect.RedirectSuccessPage
          nodeAfterEnd={
            <LinkInBio layout={linkToNavigateResponse.payload.layout || []} />
          }
        />
      );
    }
  } else {
    return <Redirect.RedirectFailurePage />;
  }
};

export default React.memo(TriggerCall);
