import React, { ReactNode, useEffect, useMemo } from 'react';
import WebFont from 'webfontloader';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import routing from '@routing/path';
import configSelector from '@configSelector';
import AppHeader from '@components/Header';
import AppFooter from '@components/Footer';
import { fetchGetMyViewStart } from '@core/profile/actionCreators';
import '../../app.module.scss';
import 'react-toastify/dist/ReactToastify.css';

interface WrapperProps {
  children: ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuth = useSelector(configSelector.isAuth);

  const needToShowWrapper = useMemo(
    () => location.pathname !== `${routing.public.signin}`,
    [location.pathname]
  );

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Inter:700',
          'Poppins:700',
          'Raleway:100,200,300,400,500,600,700,800',
        ],
      },
    });
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchGetMyViewStart());
    }
  }, [isAuth]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID || ''}>
      <div id="root">
        <AppHeader />

        <div id="container">{children}</div>

        {needToShowWrapper && <AppFooter />}
      </div>
    </GoogleOAuthProvider>
  );
};

export default Wrapper;
