import React, { useState } from 'react';
import { errorHandler } from '@common/helpers';
import Icon from '@UIkit/Icon';
import styles from './upload.module.scss';

interface IUploadProps {
  id: string;
  onChange: (file: File) => void;
  value: File | undefined;
}

const Upload: React.FC<IUploadProps> = ({ id, onChange, value }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        setSelectedFile(file);
        onChange(file);
      } else {
        alert('Please select an image file.');
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        setSelectedFile(file);
        onChange(file);
      } else {
        errorHandler({ code: 204, message: 'Please select an image file.' });
      }
    }
  };

  return (
    <div
      className={styles.upload}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        id={id}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className={styles.uploadInput}
      />

      {selectedFile && value ? (
        <>
          <div className={styles.file}>
            <img
              className={styles.fileImage}
              src={URL.createObjectURL(selectedFile)}
              alt="Preview"
              style={{ maxWidth: '100%' }}
            />
          </div>
        </>
      ) : (
        <div className={styles.file}>
          <Icon name="Upload" size="huge" color="#FFFFFF" />
        </div>
      )}
    </div>
  );
};

export default Upload;
