import { BuySubscriptionRequest } from './service';

export const FETCH_BUY_FREE_SUBSCRIPTION_START =
  'paymentActionTypes/FETCH_BUY_FREE_SUBSCRIPTION_START';
export interface FetchBuyFreeSubscriptionStart {
  type: typeof FETCH_BUY_FREE_SUBSCRIPTION_START;
}

export const FETCH_BUY_FREE_SUBSCRIPTION_SUCCESS =
  'paymentActionTypes/FETCH_BUY_FREE_SUBSCRIPTION_SUCCESS';
export interface FetchBuyFreeSubscriptionSuccess {
  type: typeof FETCH_BUY_FREE_SUBSCRIPTION_SUCCESS;
}

export const FETCH_BUY_FREE_SUBSCRIPTION_FAILURE =
  'paymentActionTypes/FETCH_BUY_FREE_SUBSCRIPTION_FAILURE';
export interface FetchBuyFreeSubscriptionFailure {
  type: typeof FETCH_BUY_FREE_SUBSCRIPTION_FAILURE;
}

export const FETCH_BUY_SUBSCRIPTION_START =
  'paymentActionTypes/FETCH_BUY_SUBSCRIPTION_START';
export interface FetchBuySubscriptionStart {
  type: typeof FETCH_BUY_SUBSCRIPTION_START;
  payload: BuySubscriptionRequest;
  onSuccess: (pay_page: string) => void;
}

export const FETCH_BUY_SUBSCRIPTION_SUCCESS =
  'paymentActionTypes/FETCH_BUY_SUBSCRIPTION_SUCCESS';
export interface FetchBuySubscriptionSuccess {
  type: typeof FETCH_BUY_SUBSCRIPTION_SUCCESS;
}

export const FETCH_BUY_SUBSCRIPTION_FAILURE =
  'paymentActionTypes/FETCH_BUY_SUBSCRIPTION_FAILURE';
export interface FetchBuySubscriptionFailure {
  type: typeof FETCH_BUY_SUBSCRIPTION_FAILURE;
}

export type PaymentAction =
  | FetchBuyFreeSubscriptionStart
  | FetchBuyFreeSubscriptionSuccess
  | FetchBuyFreeSubscriptionFailure
  | FetchBuySubscriptionStart
  | FetchBuySubscriptionSuccess
  | FetchBuySubscriptionFailure;
