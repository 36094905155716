import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { appVersion } from '@common/constants';
import rootReducer from './rootReducer';
import rootSaga from './rootSagaWatcher';

function clearBrowserCache() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
  }

  if ('caches' in window) {
    caches.keys().then(function (names) {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }

  window.location.reload();
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'call',
    'triggers',
    'profile',
    'subscribe',
    'payment',
    'interaction',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    sagaMiddleware,
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});

const persistor = persistStore(store, null, () => {
  let savedVersion;

  if (typeof localStorage !== 'undefined' && localStorage !== null) {
    savedVersion = localStorage.getItem('appVersion');
  } else {
    console.warn('localStorage is not available');
    savedVersion = null;
  }

  if (savedVersion !== appVersion) {
    if (appVersion) {
      if (typeof localStorage !== 'undefined' && localStorage !== null) {
        localStorage.clear();
        localStorage.setItem('appVersion', appVersion);
        clearBrowserCache();
      }
    }
  }
});

sagaMiddleware.run(rootSaga);

export { store, persistor };
