import { GetMyViewResponse } from '@common/types';

export const FETCH_GET_MY_VIEW_START =
  'profileActionTypes/FETCH_GET_MY_VIEW_START';
export interface FetchGetMyViewStart {
  type: typeof FETCH_GET_MY_VIEW_START;
}

export const FETCH_GET_MY_VIEW_SUCCESS =
  'profileActionTypes/FETCH_GET_MY_VIEW_SUCCESS';
export interface FetchGetMyViewSuccess {
  type: typeof FETCH_GET_MY_VIEW_SUCCESS;
  payload: GetMyViewResponse;
}

export const FETCH_GET_MY_VIEW_FAILURE =
  'profileActionTypes/FETCH_GET_MY_VIEW_FAILURE';
export interface FetchGetMyViewFailure {
  type: typeof FETCH_GET_MY_VIEW_FAILURE;
}

export type AuthAction =
  | FetchGetMyViewStart
  | FetchGetMyViewSuccess
  | FetchGetMyViewFailure;
