import React from 'react';
import { Outlet } from 'react-router-dom';
import LeftMenu from '@components/LeftMenu';
import Container from '@components/UIkit/Container';
import Space from '@components/UIkit/Space';
import styles from './styles.module.scss';

const ProfileContainer: React.FC = () => {
  return (
    <Container className={styles.container}>
      <Space gap={40}>
        <LeftMenu />

        <div className={styles.profile_content}>
          <Outlet />
        </div>
      </Space>
    </Container>
  );
};

export default React.memo(ProfileContainer);
