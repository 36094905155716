import React from 'react';
import classNames from 'classnames';
import styles from './separator.module.scss';

interface ISeparator {
  className?: string;
  padding?: 0 | 10 | 20 | 40;
}

const Separator: React.FC<ISeparator> = ({ className, padding = 0 }) => {
  return (
    <div
      className={classNames(
        styles.separator,
        className,
        styles[`padding_${padding}`]
      )}
    />
  );
};

export default React.memo(Separator);
