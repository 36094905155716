import React, { useState } from 'react';
import routing from '@routing/path';
import { TNavItem } from '@common/types';
import Headline from '@components/Headline';
import { NavbarProvider, useNavbar } from '@hooks/useNavBar';
import Container from '@components/UIkit/Container';
import Space from '@components/UIkit/Space';
import Users from './components/Users';
import Triggers from './components/Triggers';
import Interactions from './components/Interactions';
import Payments from './components/Payments';

interface IAdminContainerProps {
  table: 'users' | 'triggers' | 'interactions' | 'plans' | 'payments';
}

type IAdminProps = IAdminContainerProps;

const AdminContainer: React.FC<IAdminContainerProps> = ({ table }) => {
  const navItems: TNavItem[] = [
    {
      icon: 'ProfileSquare',
      text: 'Users',
      to: `${routing.private.admin.root}/${routing.private.admin.children.users}`,
    },
    {
      icon: 'QRCode',
      text: 'Triggers',
      to: `${routing.private.admin.root}/${routing.private.admin.children.triggers}`,
    },
    {
      icon: 'PlayCircle',
      text: 'Interactions',
      to: `${routing.private.admin.root}/${routing.private.admin.children.actions}`,
    },
    {
      icon: 'CreditCard',
      text: 'Payments',
      to: `${routing.private.admin.root}/${routing.private.admin.children.payments}`,
    },
  ];

  return (
    <NavbarProvider id="navbar-admin" items={navItems}>
      <Admin table={table} />
    </NavbarProvider>
  );
};

const Admin: React.FC<IAdminProps> = ({ table }) => {
  const [total, setTotal] = useState(0);
  const { NavBarNode } = useNavbar();

  return (
    <Container>
      <Space direction="vertical" gap={40} fullWidth>
        <Space direction="vertical" gap={20} fullWidth>
          <Headline
            title={`Admin: ${table} data`}
            description={`Total rows: ${total}`}
            align="left"
          />
        </Space>

        <Space direction="vertical" align="normal" fullWidth>
          {NavBarNode}

          <Space direction="vertical" gap={20} align="normal">
            {table === 'users' && <Users setTotal={setTotal} />}
            {table === 'triggers' && <Triggers setTotal={setTotal} />}
            {table === 'interactions' && <Interactions />}
            {table === 'payments' && <Payments />}
          </Space>
        </Space>
      </Space>
    </Container>
  );
};

export default React.memo(AdminContainer);
