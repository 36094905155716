import React from 'react';
import { Element } from 'react-scroll';
import Container from '@components/UIkit/Container';
import Grid from '@components/UIkit/Grid';
import Statistic from '@components/UIkit/Statistic';
import { TIcons } from '@common/types';

interface IAction {
  icon: TIcons;
  title: string;
  value: string;
}

interface IActionsPropTypes {
  actions: IAction[];
}

const ActionsContainer: React.FC = () => {
  const actions: IAction[] = [
    {
      icon: 'LinkBreak',
      title: 'Short URL',
      value: 'Shorten a long link in one click.',
    },
    {
      icon: 'QRCode',
      title: 'Dynamic QR-code',
      value: 'Modify the content without altering the QR code itself',
    },
    {
      icon: 'Terminal',
      title: 'My Page',
      value: 'Create a personalized profile with custom content',
    },
    {
      icon: 'PlayCircle',
      title: 'Video Player',
      value: 'Share the video for viewing',
    },
    {
      icon: 'Link',
      title: 'File Share',
      value: 'Share the file for download',
    },
    {
      icon: 'Puzzle',
      title: 'Payable Action',
      value: 'Paid interaction with your page',
    },
  ];

  return <Actions actions={actions} />;
};

const Actions: React.FC<IActionsPropTypes> = ({ actions }) => {
  return (
    <Element name="homeProducts">
      <Container size="medium">
        <Grid
          gap={40}
          items={actions.map((i, index) => (
            <Statistic key={index} {...i} />
          ))}
        />
      </Container>
    </Element>
  );
};

export default React.memo(ActionsContainer);
