import React from 'react';
import Space from '@components/UIkit/Space';
import { TIcons } from '@common/types';
import Icon from '@components/UIkit/Icon';
import styles from './styles.module.scss';

const custonIcons: TIcons[] = [
  'MurlWhite',
  'ShareTwitter',
  'ShareYoutube',
  'ShareInstagram',
  'ShareTelegram',
  'ShareTikTokWhite',
  'ShareLinkedIn',
  'SharePinterest',
  'ShareOutlook',
  'ShareAppleWhite',
  'ShareWhatsup',
  'ShareFacebook',
];

interface ISocialList {
  onSelect: (url: string) => void;
}

const SocialList: React.FC<ISocialList> = ({ onSelect }) => {
  return (
    <Space align="middle" wrapped gap={10} className={styles.icons} fullWidth>
      {custonIcons.map((i) => (
        <Icon
          id={`icon-social-list-select-image-${i}`}
          onClick={() => onSelect(`${window.location.origin}/icons/${i}.png`)}
          size="huge"
          name={i}
          className={styles.icon}
        />
      ))}
    </Space>
  );
};

export default SocialList;
