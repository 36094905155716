import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Space from '@components/UIkit/Space';
import Select from '@components/UIkit/Select';
import { IMenuAction } from '@common/types';
import styles from '../admin.module.scss';

interface IFilters {
  filters: IMenuAction[];
  onChange: Dispatch<SetStateAction<{ value: string; key: string | number }>>;
}

const Filters: React.FC<IFilters> = ({ filters, onChange }) => {
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string | number>(filters[0].value || '');

  const debounceTimeout = 300;

  useEffect(() => {
    const handler = setTimeout(() => {
      onChange({ value: search, key: filter });
    }, debounceTimeout);

    return () => {
      clearTimeout(handler);
    };
  }, [search, filter, onChange]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value.trim());
  };

  return (
    <Space className={styles.filters} fullWidth>
      <input
        id="input-admin-filters"
        className={styles.input}
        placeholder="Search..."
        value={search}
        onChange={onSearchChange}
      />

      <div className={styles.select_wrapper}>
        <Select
          id="select-admin-filters"
          className={styles.select}
          values={filters}
          defaultValue={filter}
          onChange={(value) => setFilter(value)}
        />
      </div>
    </Space>
  );
};

export default Filters;
