import React from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import Container from '@components/UIkit/Container';
import Typography from '@components/UIkit/Typography';
import styles from './test.module.scss';

const { Text } = Typography;

const TestContainer = () => {
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Container" />

      <Space className={styles.space} gap={20} direction="vertical">
        <Container className={styles.container} size="large">
          <Space justify="between">
            <Text size={2}>Large container</Text>
          </Space>
        </Container>

        <Container className={styles.container} size="medium">
          <Space justify="between">
            <Text size={2}>Medium container</Text>
          </Space>
        </Container>

        <Container className={styles.container} size="small">
          <Space justify="between">
            <Text size={2}>Small container</Text>
          </Space>
        </Container>
      </Space>
    </Space>
  );
};

export default React.memo(TestContainer);
