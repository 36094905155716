import React from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import Icon from '@components/UIkit/Icon';
import styles from './test.module.scss';

const TestIcon = () => {
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Icon" />

      <Space className={styles.space} gap={20} direction="vertical">
        <Space className={styles.space} gap={20} wrapped>
          <Icon name="Close" />
          <Icon name="PlayCircle" />
          <Icon name="Terminal" />
          <Icon name="Puzzle" />
          <Icon name="ArrowLeft" />
          <Icon name="ArrowRight" />
          <Icon name="ArrowSubDown" />
          <Icon name="Calendar" />
          <Icon name="Chart" />
          <Icon name="Copy" />
          <Icon name="CreditCard" />
          <Icon name="Download" />
          <Icon name="Eclipse" />
          <Icon name="Edit" />
          <Icon name="LinkExternal" />
          <Icon name="Label" />
          <Icon name="Link" />
          <Icon name="LinkBreak" />
          <Icon name="Logout" />
          <Icon name="MasterCard" />
          <Icon name="MonoPay" />
          <Icon name="More" />
          <Icon name="QRCode" />
          <Icon name="Annotation" />
          <Icon name="Trash" />
          <Icon name="VisaCard" />
          <Icon name="Upload" />
          <Icon name="Plus" />
          <Icon name="Globe" />
          <Icon name="ProfileSquare" />
          <Icon name="Move" />
        </Space>
      </Space>
    </Space>
  );
};

export default React.memo(TestIcon);
