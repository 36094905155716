import { OAuthRequest, OAuthResponse } from './service';

export const FETCH_OAUTH_START = 'authActionTypes/FETCH_OAUTH_START';
export interface FetchOAuthStart {
  type: typeof FETCH_OAUTH_START;
  payload: OAuthRequest;
}

export const FETCH_OAUTH_SUCCESS = 'authActionTypes/FETCH_OAUTH_SUCCESS';
export interface FetchOAuthSuccess {
  type: typeof FETCH_OAUTH_SUCCESS;
  payload: OAuthResponse;
}

export const FETCH_OAUTH_FAILURE = 'authActionTypes/FETCH_OAUTH_FAILURE';
export interface FetchOAuthFailure {
  type: typeof FETCH_OAUTH_FAILURE;
}

export type AuthAction =
  | FetchOAuthStart
  | FetchOAuthSuccess
  | FetchOAuthFailure;
