import { GetMyViewResponse } from '@common/types';
import * as actions from './actionTypes';

export function fetchGetMyViewStart(): actions.FetchGetMyViewStart {
  return {
    type: actions.FETCH_GET_MY_VIEW_START,
  };
}

export function fetchGetMyViewSuccess(
  payload: GetMyViewResponse
): actions.FetchGetMyViewSuccess {
  return {
    type: actions.FETCH_GET_MY_VIEW_SUCCESS,
    payload,
  };
}

export function fetchGetMyViewFailure(): actions.FetchGetMyViewFailure {
  return {
    type: actions.FETCH_GET_MY_VIEW_FAILURE,
  };
}
