import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './container.module.scss';

interface IContainer {
  children: ReactNode;
  className?: string;
  size?: 'large' | 'medium' | 'small';
}

const Container: React.FC<IContainer> = ({
  children,
  className,
  size = 'large',
}) => {
  return (
    <div className={classNames(styles.container, styles[size], className)}>
      {children}
    </div>
  );
};

export default Container;
