import React, { useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

interface DataItem {
  name: string;
  value: number;
}

const COLORS = ['#00ff75', '#9747ff', '#ff6b00', '#e93940', '#bdbdbd'];

interface DonutChartProps {
  data: DataItem[];
}

const DonutChart: React.FC<DonutChartProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onPieEnter = (_, index: number) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(null);
  };

  const maxDataItem = data.reduce(
    (max, item) => (item.value > max.value ? item : max),
    data[0]
  );

  const activeDataItem = activeIndex !== null ? data[activeIndex] : maxDataItem;

  return (
    <ResponsiveContainer style={{ flex: 1 }}>
      <PieChart>
        <Pie
          data={data}
          innerRadius={90}
          outerRadius={120}
          fill="#8884d8"
          paddingAngle={4}
          dataKey="value"
          onMouseEnter={onPieEnter}
          onMouseLeave={onPieLeave}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          content={() => (
            <div style={{ textAlign: 'center' }}>
              <span
                style={{ fontSize: 16, fontFamily: 'Inter', color: 'white' }}
              >
                {activeDataItem.name}: {activeDataItem.value}
              </span>
            </div>
          )}
          align="center"
          verticalAlign="middle"
          wrapperStyle={{
            fontSize: 16,
            fontFamily: 'Raleway',
            marginBottom: -8,
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default React.memo(DonutChart);
