import React from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import Typography from '@components/UIkit/Typography';
import List from '@components/UIkit/List';
import styles from './test.module.scss';

const { Text } = Typography;

const TestList = () => {
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="List" />

      <List
        icon="Eclipse"
        items={[
          <Text size={2}>Item 1</Text>,
          <Text size={2}>Item 2</Text>,
          <Text size={2}>Item 3</Text>,
        ]}
      />
    </Space>
  );
};

export default React.memo(TestList);
