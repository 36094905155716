import instance from '@core/api';
import { TPlanName } from '@common/types';
import { ITriggerInterface, TScenarioType } from '@core/triggers/service';
import { GetInteractionResponse } from '@core/interaction/service';

export type OrderDirections = 'Asc' | 'Desc';
export type Roles = 'Admin' | 'RegularUser';

// Users

export interface AdminGetUsersResponse {
  items: {
    id: string;
    email: string;
    registered_at: string;
    tier: TPlanName;
    actual_to: string;
    successful_purchases: number;
    trigger_count: number;
    interaction_count: number;
  }[];
  has_next: boolean;
  total_count: number;
}

export interface AdminGetUsersRequest {
  skip: number;
  take: number;
  order_by: string;
  order_direction: OrderDirections;
  filter?: { [x: string]: string };
}

export async function fetchAdminGetUsers(
  payload: AdminGetUsersRequest
): Promise<AdminGetUsersResponse> {
  return await instance.post(`admin/users`, payload);
}

// Triggers

export interface AdminGetTriggersResponse {
  items: {
    id: string;
    name: string;
    owner_email: string;
    created_at: string;
    updated_at: string;
    type: string;
    scenario_type: TScenarioType;
    last_interaction_at: string;
    interaction_count: number;
  }[];
  has_next: boolean;
  total_count: 0;
}

export interface AdminGetTriggersRequest {
  skip: number;
  take: number;
  order_by: string;
  order_direction: OrderDirections;
  filter?: { [x: string]: string };
}

export async function fetchAdminGetTriggers(
  payload: AdminGetTriggersRequest
): Promise<AdminGetTriggersResponse> {
  return await instance.post(`admin/triggers`, payload);
}

// Trigger by Id

export interface AdminGetTriggerByIdResponse extends ITriggerInterface {
  interactionData: GetInteractionResponse;
}

export interface AdminGetTriggerByIdRequest {
  triggerId: string;
}

export async function fetchAdminGetTriggerById(
  payload: AdminGetTriggerByIdRequest
): Promise<AdminGetTriggerByIdResponse> {
  return await instance.get(`admin/trigger/${payload.triggerId}`);
}

export async function fetchAdminGetTriggerInteractionById(
  payload: AdminGetTriggerByIdRequest
): Promise<GetInteractionResponse> {
  return await instance.get(`admin/trigger/${payload.triggerId}/stats`);
}

// Trigger all Stats

export type AdminGetTriggerAllStatsResponse = GetInteractionResponse;

export interface AdminGetTriggerAllStatsRequest {
  fromDate: number;
  toDate: number;
}

export async function fetchAdminGetTriggerAllStats(
  payload: AdminGetTriggerAllStatsRequest
): Promise<AdminGetTriggerAllStatsResponse> {
  return await instance.get(`admin/trigger/stats`, { params: payload });
}
