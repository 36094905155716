import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Typography from '@UIkit/Typography';
import { DynamicIconsColors, TIcons } from '@common/types';
import Icon from '../Icon';
import styles from './list.module.scss';

interface IList {
  items: ReactNode[];
  icon?: TIcons;
  iconColor?: DynamicIconsColors;
  wrapperClassName?: string;
}

const { Text } = Typography;

const List: React.FC<IList> = ({ items, icon, wrapperClassName }) => {
  return (
    <ul className={classNames(styles.list, wrapperClassName)}>
      {items.map((i, index) => (
        <li
          key={index}
          className={classNames(styles.item, { [styles.hasIcon]: !!icon })}
        >
          {icon && <Icon size="small" name={icon} className={styles.icon} />}

          <Text className={styles.value} size={2}>
            {i}
          </Text>
        </li>
      ))}
    </ul>
  );
};

export default List;
