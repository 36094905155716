import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as actions from './actionTypes';
import { GetInteractionResponse } from './service';

export interface InteractionState {
  loader: boolean;
  data: GetInteractionResponse | undefined;
}

const initialState: InteractionState = {
  loader: false,
  data: undefined,
};

const interactionReducer = (
  state: InteractionState = initialState,
  action: actions.InteractionAction
): InteractionState => {
  switch (action.type) {
    case actions.FETCH_GET_INTERACTION_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_GET_INTERACTION_SUCCESS:
      return {
        ...state,
        loader: false,
        data: {
          ...action.payload,
        },
      };

    case actions.FETCH_GET_INTERACTION_FAILURE:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'murl_interaction',
  storage,
};

export default persistReducer(persistConfig, interactionReducer);
