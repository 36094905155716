/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import Headline from '@components/Headline';
import { useModal } from '@hooks/useModal';
import Button from '@components/UIkit/Button';

interface IModalComfirm {
  message: string;
  onSuccess: (...args: any[]) => void;
}

const { Paragraph } = Typography;

const ModalConfirm: React.FC<IModalComfirm> = ({ message, onSuccess }) => {
  const { closeModal } = useModal();

  const handleSuccess = () => {
    onSuccess();
    closeModal();
  };

  return (
    <Space direction="vertical" gap={20}>
      <Paragraph>Confirm</Paragraph>

      <Headline align="left" description={message} />

      <Space gap={20} justify="start">
        <Button id="button-modal-confirm-success" onClick={handleSuccess}>
          Confirm
        </Button>

        <Button
          id="button-modal-confirm-cancel"
          onClick={closeModal}
          type="danger"
        >
          Cancel
        </Button>
      </Space>
    </Space>
  );
};

export default React.memo(ModalConfirm);
