const routing = {
  public: {
    home: '/',
    test: '/app/test',
    generator: '/app/generator',

    signin: '/app/signin',

    create: {
      root: '/app/create',

      children: {
        link: 'link',
        qrCode: 'qrcode',
      },
    },
  },

  private: {
    admin: {
      root: '/app/admin',

      children: {
        users: 'users',
        triggers: 'triggers',
        trigger: 'trigger',
        actions: 'actions',
        plans: 'plans',
        payments: 'payments',
      },
    },

    edit: {
      root: '/app/edit',

      children: {
        link: 'link',
        qrCode: 'qrcode',
      },
    },

    dashboard: {
      root: '/app/dashboard',
      children: {
        link: 'link',
        qrCode: 'qrcode',
      },
    },

    profile: {
      root: '/app/profile',
      children: {
        plans: 'plans',
        statistics: 'statistics',
      },
    },

    collection: {
      root: '/app/collection',
      children: {
        link: 'link',
        qrCode: 'qrcode',
      },
    },
  },
};

export default routing;
