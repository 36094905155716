/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { Input } from '@components/FormControl';
import { LayoutItem } from '@core/triggers/service';
import Space from '@components/UIkit/Space';
import Icon from '@components/UIkit/Icon';
import { errorHandler } from '@common/helpers';
import { fetchUploadFileStart } from '@core/triggers/actionCreators';
import SocialList from '@components/SocialList';
import configSelector from '@core/configSelector';
import styles from '../../styles.module.scss';

interface ICustomFieldProps {
  field: LayoutItem;
  index: number;
  onRemove: () => void;
}

const UploadField: React.FC<{
  id: string;
  onSuccess: (url: string) => void;
}> = ({ id, onSuccess }) => {
  const { loader } = useSelector(configSelector.triggers);
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (file) {
      dispatch(
        fetchUploadFileStart({ fileType: 'images', file }, (data) => {
          onSuccess(
            `${document.location.origin}/storage/images/${data.fileId}`
          );
        })
      );
    }
  }, [file]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      const isImage = selectedFile.type.startsWith('image/');
      const isLessThan5MB = selectedFile.size < 5 * 1024 * 1024; // 5MB in bytes

      if (!isImage) {
        errorHandler({ code: 204, message: 'Please select an image file.' });
        setFile(null);
      } else if (!isLessThan5MB) {
        errorHandler({
          code: 204,
          message: 'Please select an image file less than 5MB.',
        });
        setFile(null);
      } else {
        setFile(selectedFile);
      }
    }
  };
  return (
    <div
      className={classNames(styles.uploadField, { [styles.disabled]: loader })}
    >
      <input
        id={id}
        type="file"
        className={styles.input}
        onChange={handleFileChange}
      />
      <Icon
        color="#000000"
        name="Upload"
        size="medium"
        className={styles.icon}
      />
    </div>
  );
};

const CustomField: React.FC<ICustomFieldProps> = ({
  field,
  index,
  onRemove,
}) => {
  const { setFieldValue } = useFormikContext();

  let FieldNode;

  if (field.type === 'header') {
    FieldNode = (
      <Field
        id={`input-link-in-bio-header-${index}`}
        name={`payload.layout[${index}].text`}
        placeholder="Example: @username"
        title="Header"
        component={Input}
      />
    );
  }

  if (field.type === 'text') {
    FieldNode = (
      <Field
        id={`input-link-in-bio-text-${index}`}
        name={`payload.layout[${index}].text`}
        placeholder="Example: description"
        title="Text"
        component={Input}
      />
    );
  }

  if (field.type === 'link-button') {
    FieldNode = (
      <>
        <Space gap={20} fullWidth direction="vertical">
          <Space gap={20} fullWidth align="end" className={styles.wrappedField}>
            <Field
              id={`input-link-in-bio-btn-text-${index}`}
              name={`payload.layout[${index}].text`}
              placeholder="Example: Click me!"
              title="Button text"
              component={Input}
            />

            <Field
              id={`input-link-in-bio-btn-url-${index}`}
              name={`payload.layout[${index}].url`}
              placeholder="Example: https://murl.gg..."
              title="Url"
              component={Input}
            />
          </Space>

          <Space gap={20} fullWidth align="end">
            <Field
              id={`input-link-in-bio-btn-image-url-${index}`}
              name={`payload.layout[${index}].image_url`}
              placeholder="Upload image or Insert link"
              title="Image url"
              component={Input}
              suffixIcon={
                <UploadField
                  id={`input-link-in-bio-btn-image-file-${index}`}
                  onSuccess={(data) =>
                    setFieldValue(`payload.layout[${index}].image_url`, data)
                  }
                />
              }
            />
          </Space>

          <SocialList
            onSelect={(url) =>
              setFieldValue(`payload.layout[${index}].image_url`, url)
            }
          />
        </Space>
      </>
    );
  }

  if (field.type === 'image') {
    FieldNode = (
      <>
        <Field
          id={`input-create-trigger-form-link-in-bio-image-url-${index}`}
          name={`payload.layout[${index}].image_url`}
          placeholder="Upload image or Insert link"
          title="Image url"
          component={Input}
          suffixIcon={
            <UploadField
              id={`input-link-in-bio-image-file-${index}`}
              onSuccess={(data) =>
                setFieldValue(`payload.layout[${index}].image_url`, data)
              }
            />
          }
        />
      </>
    );
  }

  return (
    <Space className={styles.card} fullWidth gap={20} align="end">
      {FieldNode}

      <Icon
        id={`icon-link-in-bio-image-file-${index}-remove`}
        color="#FFFFFF"
        name="Close"
        onClick={onRemove}
        className={styles.closeIcon}
      />
    </Space>
  );
};

export default CustomField;
