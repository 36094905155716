import { fork } from 'redux-saga/effects';
import authSaga from './auth/saga';
import callSaga from './call/saga';
import profileSaga from './profile/saga';
import subscribeSaga from './subscribe/saga';
import collectionSaga from './triggers/saga';
import paymentSaga from './payment/saga';
import interactionSaga from './interaction/saga';
import adminSaga from './admin/saga';

export default function* rootSaga() {
  yield fork(adminSaga);
  yield fork(authSaga);
  yield fork(callSaga);
  yield fork(profileSaga);
  yield fork(subscribeSaga);
  yield fork(collectionSaga);
  yield fork(paymentSaga);
  yield fork(interactionSaga);
}
