import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TNavItem } from '@common/types';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import NavBar from '@components/UIkit/NavBar';
import configSelector from '@core/configSelector';
import routing from '@routing/path';
import styles from './test.module.scss';

const TestNavBar = () => {
  const [activeItem, setActiveItem] = useState(false);
  const isAuth = useSelector(configSelector.isAuth);

  const navItems: TNavItem[] = [
    {
      icon: 'Link',
      text: 'Short link',
      to: isAuth
        ? `${routing.public.create.root}/${routing.public.create.children.link}`
        : `${routing.private.collection.root}/${routing.private.collection.children.link}`,
    },
    {
      icon: 'QRCode',
      text: 'QR Code',
      to: isAuth
        ? `${routing.public.create.root}/${routing.public.create.children.qrCode}`
        : `${routing.private.collection.root}/${routing.private.collection.children.qrCode}`,
    },
  ];

  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="NavBar" />

      <NavBar
        id="navbar-test"
        items={navItems}
        onSelect={() => setActiveItem(!activeItem)}
      />
    </Space>
  );
};

export default React.memo(TestNavBar);
