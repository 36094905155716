import React from 'react';
import routing from '@routing/path';
import Headline from '@components/Headline';
import Button from '@components/UIkit/Button';
import Container from '@components/UIkit/Container';
import Space from '@components/UIkit/Space';
import Actions from './components/Actions';
import Plans from './components/Plans';
import FAQ from './components/FAQ';
import Subscribe from './components/Subscribe';

const HomeContainer: React.FC = () => {
  return <Home />;
};

const Home: React.FC = () => {
  return (
    <Container>
      <Space direction="vertical" align="middle" gap={140}>
        <Space direction="vertical" align="middle" gap={100}>
          <Headline
            title="Your short links, QR codes, and analytics. Simple and convenient."
            description="Scenarios and Triggers. Take control of your content."
          />

          <Button
            id="button-home-create-link"
            submit
            type="primary"
            to={`${routing.public.create.root}/${routing.public.create.children.link}`}
          >
            Create short link
          </Button>
        </Space>

        <Actions />
        <Plans />
        <FAQ />
        <Subscribe />
      </Space>
    </Container>
  );
};

export default React.memo(HomeContainer);
