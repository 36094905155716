import React from 'react';
import MurlSuccess from '@assets/images/RedirectPages/MurlSuccess.svg';
import styles from './styles.module.scss';

const Loader: React.FC = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.image}>
        <img src={MurlSuccess} alt="Loader" />
      </div>
    </div>
  );
};

export default React.memo(Loader);
