import React from 'react';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import Headline from '@components/Headline';
import Button from '@components/UIkit/Button';
import routing from '@routing/path';
import { useModal } from '@hooks/useModal';

const { Paragraph } = Typography;

const ModalAccessToProfile: React.FC = () => {
  const { closeModal } = useModal();

  return (
    <Space direction="vertical" gap={20}>
      <Paragraph>Access to Profile</Paragraph>

      <Headline
        align="left"
        description="Find out which QR code and Shortened Link are most popular, and discover where your most valuable audience is coming from."
      />

      <Space gap={20} justify="start">
        <Button
          id="button-modal-access-to-profile-sign-in"
          to={routing.public.signin}
          onClick={closeModal}
        >
          Sign In
        </Button>
      </Space>
    </Space>
  );
};

export default React.memo(ModalAccessToProfile);
