import React from 'react';
import { TIcons } from '@common/types';
import Typography from '../Typography';
import Icon from '../Icon';
import styles from './statistic.module.scss';

interface IStatistic {
  icon?: TIcons;
  title: string;
  value: string;
}

const { Paragraph, Text } = Typography;

const Statistic: React.FC<IStatistic> = ({ icon, title, value }) => {
  return (
    <div className={styles.statistic}>
      {icon && <Icon className={styles.icon} name={icon} size="large" />}

      <Paragraph size={2} className={styles.title}>
        {title}
      </Paragraph>

      <Text size={2} className={styles.value}>
        {value}
      </Text>
    </div>
  );
};

export default Statistic;
