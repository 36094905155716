import {
  AdminGetTriggerAllStatsRequest,
  AdminGetTriggerAllStatsResponse,
  AdminGetTriggerByIdRequest,
  AdminGetTriggerByIdResponse,
  AdminGetTriggersRequest,
  AdminGetTriggersResponse,
  AdminGetUsersRequest,
  AdminGetUsersResponse,
} from './service';

// Users

export const FETCH_ADMIN_GET_USERS_START =
  'adminActionTypes/FETCH_ADMIN_GET_USERS_START';
export interface FetchAdminGetUsersStart {
  type: typeof FETCH_ADMIN_GET_USERS_START;
  payload: AdminGetUsersRequest;
  is_first: boolean;
}

export const FETCH_ADMIN_GET_USERS_SUCCESS =
  'adminActionTypes/FETCH_ADMIN_GET_USERS_SUCCESS';
export interface FetchAdminGetUsersSuccess {
  type: typeof FETCH_ADMIN_GET_USERS_SUCCESS;
  payload: AdminGetUsersResponse;
  is_first: boolean;
}

export const FETCH_ADMIN_GET_USERS_FAILURE =
  'adminActionTypes/FETCH_ADMIN_GET_USERS_FAILURE';
export interface FetchAdminGetUsersFailure {
  type: typeof FETCH_ADMIN_GET_USERS_FAILURE;
}

// Triggers

export const FETCH_ADMIN_GET_TRIGGERS_START =
  'adminActionTypes/FETCH_ADMIN_GET_TRIGGERS_START';
export interface FetchAdminGetTriggersStart {
  type: typeof FETCH_ADMIN_GET_TRIGGERS_START;
  payload: AdminGetTriggersRequest;
  is_first: boolean;
}

export const FETCH_ADMIN_GET_TRIGGERS_SUCCESS =
  'adminActionTypes/FETCH_ADMIN_GET_TRIGGERS_SUCCESS';
export interface FetchAdminGetTriggersSuccess {
  type: typeof FETCH_ADMIN_GET_TRIGGERS_SUCCESS;
  payload: AdminGetTriggersResponse;
  is_first: boolean;
}

export const FETCH_ADMIN_GET_TRIGGERS_FAILURE =
  'adminActionTypes/FETCH_ADMIN_GET_TRIGGERS_FAILURE';
export interface FetchAdminGetTriggersFailure {
  type: typeof FETCH_ADMIN_GET_TRIGGERS_FAILURE;
}

// Trigger by Id

export const FETCH_ADMIN_GET_TRIGGER_BY_ID_START =
  'adminActionTypes/FETCH_ADMIN_GET_TRIGGER_BY_ID_START';
export interface FetchAdminGetTriggerByIdStart {
  type: typeof FETCH_ADMIN_GET_TRIGGER_BY_ID_START;
  payload: AdminGetTriggerByIdRequest;
}

export const FETCH_ADMIN_GET_TRIGGER_BY_ID_SUCCESS =
  'adminActionTypes/FETCH_ADMIN_GET_TRIGGER_BY_ID_SUCCESS';
export interface FetchAdminGetTriggerByIdSuccess {
  type: typeof FETCH_ADMIN_GET_TRIGGER_BY_ID_SUCCESS;
  payload: AdminGetTriggerByIdResponse;
}

export const FETCH_ADMIN_GET_TRIGGER_BY_ID_FAILURE =
  'adminActionTypes/FETCH_ADMIN_GET_TRIGGER_BY_ID_FAILURE';
export interface FetchAdminGetTriggerByIdFailure {
  type: typeof FETCH_ADMIN_GET_TRIGGER_BY_ID_FAILURE;
}

// Trigger all Stats

export const FETCH_ADMIN_GET_TRIGGER_ALL_STATS_START =
  'adminActionTypes/FETCH_ADMIN_GET_TRIGGER_ALL_STATS_START';
export interface FetchAdminGetTriggerAllStatsStart {
  type: typeof FETCH_ADMIN_GET_TRIGGER_ALL_STATS_START;
  payload: AdminGetTriggerAllStatsRequest;
}

export const FETCH_ADMIN_GET_TRIGGER_ALL_STATS_SUCCESS =
  'adminActionTypes/FETCH_ADMIN_GET_TRIGGER_ALL_STATS_SUCCESS';
export interface FetchAdminGetTriggerAllStatsSuccess {
  type: typeof FETCH_ADMIN_GET_TRIGGER_ALL_STATS_SUCCESS;
  payload: AdminGetTriggerAllStatsResponse;
}

export const FETCH_ADMIN_GET_TRIGGER_ALL_STATS_FAILURE =
  'adminActionTypes/FETCH_ADMIN_GET_TRIGGER_ALL_STATS_FAILURE';
export interface FetchAdminGetTriggerAllStatsFailure {
  type: typeof FETCH_ADMIN_GET_TRIGGER_ALL_STATS_FAILURE;
}

export type AdminAction =
  // Users
  | FetchAdminGetUsersStart
  | FetchAdminGetUsersSuccess
  | FetchAdminGetUsersFailure
  // Triggers
  | FetchAdminGetTriggersStart
  | FetchAdminGetTriggersSuccess
  | FetchAdminGetTriggersFailure
  // Trigger by Id
  | FetchAdminGetTriggerByIdStart
  | FetchAdminGetTriggerByIdSuccess
  | FetchAdminGetTriggerByIdFailure
  // Trigger all Stats
  | FetchAdminGetTriggerAllStatsStart
  | FetchAdminGetTriggerAllStatsSuccess
  | FetchAdminGetTriggerAllStatsFailure;
