import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Typography from '@components/UIkit/Typography';
import styles from './styles.module.scss';

interface IHeadline {
  title?: string;
  description?: string | ReactNode;
  align?: 'middle' | 'left' | 'right';
  className?: string;
}

const Headline: React.FC<IHeadline> = ({
  title,
  description,
  align = 'middle',
  className,
}) => {
  return (
    <div className={classNames(styles.headline, styles?.[align], className)}>
      {title && (
        <Typography.Title className={styles.title}>{title}</Typography.Title>
      )}

      {description && (
        <Typography.Text size={2} className={styles.description}>
          {description}
        </Typography.Text>
      )}
    </div>
  );
};

export default React.memo(Headline);
