import React from 'react';
import classNames from 'classnames';
import Icon from '@components/UIkit/Icon';
import { TIcons } from '@common/types';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import styles from './styles.module.scss';

interface NotifyProps {
  text: string;
  value?: string;
  className?: string;
  icon?: TIcons;
}

const { Text } = Typography;

const Notify: React.FC<NotifyProps> = ({ text, value, className, icon }) => {
  return (
    <Space
      gap={10}
      align="middle"
      className={classNames(styles.notify, className)}
    >
      {icon && <Icon className={styles.icon} name={icon} color="#00FF75" />}

      <Text size={2} className={styles.text}>
        {text}
      </Text>

      {value && (
        <Text size={2} font="Inter" className={styles.text}>
          {value}
        </Text>
      )}
    </Space>
  );
};

export default React.memo(Notify);
