import React from 'react';
import { ErrorCorrectionLevel, Options } from 'qr-code-styling';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import ButtonGroup from '@components/UIkit/ButtonGroup';
import styles from './correction.module.scss';

interface ICorrection {
  options: Options;
  setOptions: (options: Options) => void;
}

const { Text } = Typography;

const Correction: React.FC<ICorrection> = ({ options, setOptions }) => {
  return (
    <Space
      className={styles.correction}
      direction="vertical"
      gap={20}
      fullWidth
    >
      <Space align="middle" justify="between" fullWidth>
        <Text size={2}>Error Correction Level</Text>
      </Space>

      <Space align="middle" justify="between" wrapped gap={10} fullWidth>
        <ButtonGroup
          id="button-group-generator-cl-t"
          buttons={[
            { text: 'Low', value: 'L' },
            { text: 'Medium', value: 'M' },
            { text: 'Quartile', value: 'Q' },
            { text: 'High', value: 'H' },
          ]}
          value={options.qrOptions?.errorCorrectionLevel || 'H'}
          onChange={(value) =>
            setOptions({
              ...options,
              qrOptions: {
                ...options.qrOptions,
                errorCorrectionLevel: value as ErrorCorrectionLevel,
              },
            })
          }
        />
      </Space>

      <Space align="middle" justify="between" wrapped gap={10} fullWidth>
        <Text size={2} className={styles.text}>
          Increasing the level can enhance the code's resilience to damage, but
          it may also increase the code size and complexity of reading.
        </Text>
      </Space>
    </Space>
  );
};

export default Correction;
