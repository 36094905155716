import instance from '@core/api';

export interface SubscribeRequest {
  email: string;
}

export type SubscribeResponse = '';

export async function fetchSubscribe(
  request: SubscribeRequest
): Promise<SubscribeResponse> {
  return await instance.post(`me/subscribe`, request);
}
