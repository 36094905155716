import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routing from '@routing/path';
import configSelector from '@configSelector';
import Wrapper from '@components/Wrapper';

const PublicRoute: React.FC = () => {
  const token = useSelector(configSelector.authToken);

  return token ? (
    <Navigate
      to={`${routing.private.profile.root}/${routing.private.profile.children.plans}`}
      replace
    />
  ) : (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};

export default React.memo(PublicRoute);
