import { IPlan, plansData } from '@common/types';
import { store } from './store';

const AppStore = store.getState();

export type StateType = typeof AppStore;

const configSelector = {
  authToken: (state: StateType) => state.auth.token,
  authRoles: (state: StateType) => state.auth.roles,

  admin: (state: StateType) => state.admin,

  call: (state: StateType) => state.call,
  subscribe: (state: StateType) => state.subscribe,

  profile: (state: StateType) => state.profile,

  myTriggersLimit: (state: StateType) => state.profile.data.subscription.limit,
  myTriggersLimitLeft: (state: StateType) => {
    const myTriggersLimit = configSelector.myTriggersLimit(state);
    const { tier } = configSelector.mySubscription(state);
    const MyTier: IPlan | undefined = plansData.find((p) => p.id === tier);

    if (MyTier?.triggers) {
      return MyTier?.triggers - myTriggersLimit;
    }

    return 0;
  },

  mySubscription: (state: StateType) => state.profile.data.subscription,

  triggers: (state: StateType) => state.triggers,
  triggerById: (state: StateType) => state.triggers.current,

  interaction: (state: StateType) => state.interaction,

  uploadFile: (state: StateType) => state.triggers,

  // helpers

  isAuth: (state: StateType) => !!state.auth.token,
  isAdmin: (state: StateType) =>
    state.auth.roles ? state.auth.roles.includes('Admin') : false,
};

export default configSelector;
