import React, { useState } from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import RadioGroup from '@components/UIkit/RadioGroup';
import styles from './test.module.scss';

const TestRadioGroup = () => {
  const [selected, setSelected] = useState('H');

  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Radio Group" />

      <Space className={styles.space} gap={10}>
        <RadioGroup
          selectedOption={selected}
          options={[
            { label: 'Low', id: 'L' },
            { label: 'Medium', id: 'M' },
            { label: 'Quartile', id: 'Q' },
            { label: 'High', id: 'H' },
          ]}
          onChange={setSelected}
        />
      </Space>
    </Space>
  );
};

export default React.memo(TestRadioGroup);
