import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { scroller, animateScroll } from 'react-scroll';
import Space from '@components/UIkit/Space';
import routing from '@routing/path';
import Typography from '@components/UIkit/Typography';
import configSelector from '@core/configSelector';
import Button from '@components/UIkit/Button';
import { fetchOAuthFailure } from '@core/auth/actionCreators';
import styles from './styles.module.scss';

const { Text } = Typography;

const MobileMenu = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(configSelector.isAuth);
  const [menuOpen, setMenuOpen] = useState(false);

  const onSignOut = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(fetchOAuthFailure());
    return false;
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (menuOpen && window.innerWidth < 768) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen]);

  return (
    <Space
      align="middle"
      className={classNames(styles.mobileMenu, { [styles.active]: menuOpen })}
    >
      <div className={styles.button} onClick={toggleMenu}>
        <div className={styles.icon} id="button-mobile-menu-toggle"></div>
      </div>

      <div className={styles.content}>
        <Space gap={40} direction="vertical" className={styles.contentWrapper}>
          {document.location.hostname !== 'murl.gg' && (
            <Text className={styles.link} size={2} to={routing.public.test}>
              Test
            </Text>
          )}

          {isAuth ? (
            <>
              <Text
                className={styles.link}
                size={2}
                to={routing.public.home}
                onClick={() => {
                  toggleMenu();
                  setTimeout(() => animateScroll.scrollTo(0), 0);
                }}
              >
                Home
              </Text>

              <Text
                className={styles.link}
                size={2}
                onClick={toggleMenu}
                to={`${routing.private.collection.root}/${routing.private.collection.children.link}`}
              >
                Links
              </Text>

              <Text
                className={styles.link}
                size={2}
                onClick={toggleMenu}
                to={`${routing.private.collection.root}/${routing.private.collection.children.qrCode}`}
              >
                QR Codes
              </Text>

              <Text
                className={styles.link}
                size={2}
                onClick={toggleMenu}
                to={`${routing.private.profile.root}/${routing.private.profile.children.plans}`}
              >
                Profile
              </Text>
            </>
          ) : (
            <>
              <Text
                className={styles.link}
                size={2}
                to={routing.public.home}
                onClick={() => {
                  toggleMenu();
                  setTimeout(() => animateScroll.scrollTo(0), 0);
                }}
              >
                Home
              </Text>
              <Text
                className={styles.link}
                size={2}
                to={routing.public.home}
                onClick={() => {
                  toggleMenu();
                  setTimeout(
                    () =>
                      scroller.scrollTo('homeProducts', {
                        duration: 1000,
                        smooth: true,
                        offset: -50,
                      }),
                    0
                  );
                }}
              >
                Products
              </Text>
              <Text
                className={styles.link}
                size={2}
                to={routing.public.home}
                onClick={() => {
                  toggleMenu();
                  setTimeout(
                    () =>
                      scroller.scrollTo('homePricing', {
                        duration: 1000,
                        smooth: true,
                        offset: -50,
                      }),
                    0
                  );
                }}
              >
                Pricing
              </Text>
              <Space>
                <Text
                  className={styles.link}
                  size={2}
                  to={routing.public.home}
                  onClick={() => {
                    toggleMenu();
                    setTimeout(
                      () =>
                        scroller.scrollTo('homeFAQ', {
                          duration: 1000,
                          smooth: true,
                          offset: -50,
                        }),
                      100
                    );
                  }}
                >
                  Whats
                </Text>

                <Text size={3} className={styles.newNotify}>
                  new
                </Text>
              </Space>
            </>
          )}

          {isAuth ? (
            <Button
              id="button-mobile-menu-sign-out"
              onClick={onSignOut}
              className={styles.signout}
              type="danger"
            >
              Sign Out
            </Button>
          ) : (
            <Button
              id="button-mobile-menu-sign-in"
              className={styles.signin}
              onClick={toggleMenu}
              to={routing.public.signin}
            >
              Sign In
            </Button>
          )}
        </Space>
      </div>
    </Space>
  );
};

export default React.memo(MobileMenu);
