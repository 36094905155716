/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, RefObject, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { IHiddenOn } from '@common/types';
import styles from './button.module.scss';

type TButtonTypes = 'default' | 'primary' | 'danger';
type TButtonSizes = 'large' | 'medium' | 'small';

interface IButton extends IHiddenOn {
  id: string;
  target?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
  type?: TButtonTypes;
  size?: TButtonSizes;
  to?: string;
  onClick?: (...args: any[]) => void;
  className?: string;
  submit?: boolean;
  disabled?: boolean;
  active?: boolean;
  fullWidth?: boolean;
  forwardRef?: RefObject<any>;
}

const Button: React.FC<IButton> = ({
  id,
  target,
  icon,
  children,
  type = 'primary',
  size = 'medium',
  to,
  onClick,
  className,
  submit = false,
  disabled = false,
  active = false,
  fullWidth = false,
  hiddenOnDesktop = false,
  hiddenOnMobile = false,
  forwardRef,
}) => {
  const buttonClasses = useMemo(
    () =>
      classNames(
        styles.button,
        styles[type],
        styles[size],
        {
          [styles.hiddenOnMobile]: hiddenOnMobile,
          [styles.hiddenOnDesktop]: hiddenOnDesktop,
          [styles.iconButton]: !!icon,
          [styles.disabled]: disabled,
          [styles.active]: active,
          [styles.fullWidth]: fullWidth,
        },
        className
      ),
    [type, className, disabled, active, fullWidth]
  );

  if (to) {
    if (target) {
      return (
        <a
          id={id}
          translate="no"
          href={to}
          className={buttonClasses}
          ref={forwardRef}
          onClick={onClick}
          target="_blank"
        >
          {children}
          {icon}
        </a>
      );
    } else {
      return (
        <NavLink
          id={id}
          translate="no"
          onClick={onClick}
          to={to}
          className={buttonClasses}
          ref={forwardRef}
        >
          {children}
          {icon}
        </NavLink>
      );
    }
  }

  return (
    <button
      id={id}
      translate="no"
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      className={buttonClasses}
      ref={forwardRef}
    >
      {children}
      {icon}
    </button>
  );
};

export default Button;
