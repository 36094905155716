import React, { useState } from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import Menu from '@components/UIkit/Menu';
import Button from '@components/UIkit/Button';
import styles from './test.module.scss';

const TestMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Menu" />

      <Button
        id="button-show-menu"
        className={styles.relative}
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isOpen ? 'Open' : 'Close'} menu
        <Menu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          items={[
            {
              key: 1,
              text: 'Option 1',
            },
            {
              key: 2,
              text: 'Option 2',
              children: [
                {
                  key: 1,
                  text: 'Option 2.1',
                },
                {
                  key: 2,
                  text: 'Option 2.2',
                },
              ],
            },
            {
              key: 4,
              text: 'Option 3',
            },
          ]}
        />
      </Button>
    </Space>
  );
};

export default React.memo(TestMenu);
