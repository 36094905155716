import React, {
  ReactNode,
  useCallback,
  useContext,
  useState,
  createContext,
} from 'react';
import ModalPortal from '@components/ModalPortal';
import { TModalSize } from '@common/types';

interface ModalContextProps {
  showModal: (Modal: ReactNode, size: TModalSize) => void;
  closeModal: () => void;
}

interface NavbarProviderProps {
  children: ReactNode;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<NavbarProviderProps> = ({ children }) => {
  const [modalSize, setModalSize] = useState<TModalSize>('large');
  const [modalNode, setModalNode] = useState<ReactNode>(null);

  const onShowModal = useCallback((Modal: ReactNode, size: TModalSize) => {
    setModalNode(Modal);
    setModalSize(size);
  }, []);

  const onCloseModal = useCallback(() => setModalNode(null), []);

  return (
    <ModalContext.Provider
      value={{ showModal: onShowModal, closeModal: onCloseModal }}
    >
      {children}

      {modalNode && (
        <ModalPortal size={modalSize} onClose={onCloseModal}>
          {modalNode}
        </ModalPortal>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within ModalProvider');
  }

  return context;
};
