import * as actions from './actionTypes';
import { BuySubscriptionRequest } from './service';

export function fetchBuyFreeSubscriptionStart(): actions.FetchBuyFreeSubscriptionStart {
  return {
    type: actions.FETCH_BUY_FREE_SUBSCRIPTION_START,
  };
}

export function fetchBuyFreeSubscriptionSuccess(): actions.FetchBuyFreeSubscriptionSuccess {
  return {
    type: actions.FETCH_BUY_FREE_SUBSCRIPTION_SUCCESS,
  };
}

export function fetchBuyFreeSubscriptionFailure(): actions.FetchBuyFreeSubscriptionFailure {
  return {
    type: actions.FETCH_BUY_FREE_SUBSCRIPTION_FAILURE,
  };
}

export function fetchBuySubscriptionStart(
  payload: BuySubscriptionRequest,
  onSuccess: (pay_page: string) => void
): actions.FetchBuySubscriptionStart {
  return {
    type: actions.FETCH_BUY_SUBSCRIPTION_START,
    payload,
    onSuccess,
  };
}

export function fetchBuySubscriptionSuccess(): actions.FetchBuySubscriptionSuccess {
  return {
    type: actions.FETCH_BUY_SUBSCRIPTION_SUCCESS,
  };
}

export function fetchBuySubscriptionFailure(): actions.FetchBuySubscriptionFailure {
  return {
    type: actions.FETCH_BUY_SUBSCRIPTION_FAILURE,
  };
}
