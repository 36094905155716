import { put, call, all, takeLatest } from 'redux-saga/effects';
import { errorHandler, successHandler } from '@common/helpers';
import { toastMessages } from '@common/constants';
import { ResponseError } from '@common/types';
import { fetchGetMyViewStart } from '@core/profile/actionCreators';
import {
  fetchCreateTrigger,
  fetchDeleteTrigger,
  fetchGetTriggerById,
  fetchGetTriggersAll,
  fetchUpdateTrigger,
  fetchUploadFile,
  ICreateTriggerResponse,
  IDeleteTriggerResponse,
  IFetchGetTriggerByIdResponse,
  IFetchGetTriggersAllResponse,
  IUpdateTriggerResponse,
  IUploadFileResponse,
} from './service';
import * as actionCreators from './actionCreators';
import * as actionTypes from './actionTypes';

// Upload file

function* onUploadFileStart({
  payload,
  onSuccess,
}: actionTypes.FetchUploadFileStart) {
  try {
    const data: IUploadFileResponse = yield call(fetchUploadFile, payload);

    yield put(actionCreators.fetchUploadFileSuccess(data));

    successHandler(toastMessages.file_uploaded_successfully);

    if (onSuccess) {
      onSuccess(data);
    }
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);
    yield put(actionCreators.fetchUploadFileFailure());
  }
}

// Create Trigger

function* onCreateTriggerStart({
  payload,
  onSuccess,
  onFailure,
}: actionTypes.FetchCreateTriggerStart) {
  try {
    const { data }: { data: ICreateTriggerResponse } = yield call(
      fetchCreateTrigger,
      payload
    );

    yield put(actionCreators.fetchCreateTriggerSuccess(data));

    successHandler(toastMessages.link_created_successfully);

    if (onSuccess) {
      onSuccess(data);
    }
  } catch (error) {
    const typedError = error as ResponseError;
    yield put(actionCreators.fetchCreateTriggerFailure());

    if (typedError.message === 'limit reached' && onFailure) {
      onFailure();
    } else {
      errorHandler(typedError);
    }
  }
}

// Update Trigger

function* onUpdateTriggerStart({
  payload,
  onSuccess,
}: actionTypes.FetchUpdateTriggerStart) {
  try {
    const { data }: { data: IUpdateTriggerResponse } = yield call(
      fetchUpdateTrigger,
      payload
    );

    yield put(actionCreators.fetchUpdateTriggerSuccess(data));
    yield put(actionCreators.fetchGetTriggersAllStart());

    onSuccess();
    successHandler(toastMessages.link_updated_successfully);
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);
    yield put(actionCreators.fetchUpdateTriggerFailure());
  }
}

// Delete Trigger

function* onDeleteTriggerStart({
  payload,
  onSuccess,
}: actionTypes.FetchDeleteTriggerStart) {
  try {
    const { data }: { data: IDeleteTriggerResponse } = yield call(
      fetchDeleteTrigger,
      payload
    );

    yield put(actionCreators.fetchDeleteTriggerSuccess(data));
    yield put(actionCreators.fetchGetTriggersAllStart());
    yield put(fetchGetMyViewStart());

    onSuccess();
    successHandler(toastMessages.link_deleted_successfully);
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);
    yield put(actionCreators.fetchDeleteTriggerFailure());
  }
}

// Get Triggers All

function* onGetTriggersAllStart() {
  try {
    const { data }: { data: IFetchGetTriggersAllResponse } = yield call(
      fetchGetTriggersAll
    );

    yield put(actionCreators.fetchGetTriggersAllSuccess(data));
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);
    yield put(actionCreators.fetchGetTriggersAllFailure());
  }
}

// Get Trigger by Id

function* onGetTriggerByIdStart({
  payload,
}: actionTypes.FetchGetTriggerByIdStart) {
  try {
    const { data }: { data: IFetchGetTriggerByIdResponse } = yield call(
      fetchGetTriggerById,
      payload
    );

    yield put(actionCreators.fetchGetTriggerByIdSuccess(data));
  } catch (error) {
    const typedError = error as ResponseError;
    errorHandler(typedError);
    yield put(actionCreators.fetchGetTriggerByIdFailure());
  }
}

export default function* collectionSaga() {
  yield all([
    takeLatest(actionTypes.FETCH_UPLOAD_FILE_START, onUploadFileStart),
    //
    takeLatest(actionTypes.FETCH_CREATE_TRIGGER_START, onCreateTriggerStart),
    takeLatest(actionTypes.FETCH_UPDATE_TRIGGER_START, onUpdateTriggerStart),
    takeLatest(actionTypes.FETCH_DELETE_TRIGGER_START, onDeleteTriggerStart),
    //
    takeLatest(actionTypes.FETCH_GET_TRIGGERS_ALL_START, onGetTriggersAllStart),
    //
    takeLatest(
      actionTypes.FETCH_GET_TRIGGER_BY_ID_START,
      onGetTriggerByIdStart
    ),
  ]);
}
