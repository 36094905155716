import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import configSelector from '@configSelector';
import { useModal } from '@hooks/useModal';
import { fetchDeleteTriggerStart } from '@core/triggers/actionCreators';
import Button from '@components/UIkit/Button';
import Headline from '@components/Headline';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';

interface ModalDeleteProps {
  type: 'qr-code' | 'short-url';
  id: string;
}

const { Paragraph } = Typography;

const ModalDelete: React.FC<ModalDeleteProps> = ({ type, id }) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const { loader } = useSelector(configSelector.triggers);

  const onDelete = useCallback(() => {
    dispatch(fetchDeleteTriggerStart({ id }, closeModal));
  }, [dispatch]);

  return (
    <Space direction="vertical" gap={20}>
      <Paragraph>Delete {type === 'short-url' ? 'link' : 'QR code'}</Paragraph>

      <Space direction="vertical" gap={20}>
        <Headline
          align="left"
          description={
            <>
              Deleting the {type === 'short-url' ? 'Short link' : 'QR code'} may
              affect all associated data and links.
              <br />
              Please confirm your choice.
            </>
          }
        />

        <Space gap={20} justify="start">
          <Button
            id="button-modal-delete-success"
            onClick={onDelete}
            disabled={loader}
            type="danger"
          >
            Delete
          </Button>
          <Button
            id="button-modal-delete-cancel"
            onClick={closeModal}
            type="default"
          >
            Cancel
          </Button>
        </Space>
      </Space>
    </Space>
  );
};

export default React.memo(ModalDelete);
