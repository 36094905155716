import * as actions from './actionTypes';
import { ITriggerInterface } from './service';

export interface ITriggersState {
  data: ITriggerInterface[];
  loader: boolean;
  current: {
    data: ITriggerInterface | null;
    loader: boolean;
  };
}

const initialState: ITriggersState = {
  data: [],
  loader: false,
  current: {
    data: null,
    loader: false,
  },
};

const triggersReducer = (
  state: ITriggersState = initialState,
  action: actions.LinksAction
): ITriggersState => {
  switch (action.type) {
    // Upload file

    case actions.FETCH_UPLOAD_FILE_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loader: false,
      };

    case actions.FETCH_UPLOAD_FILE_FAILURE:
      return {
        ...state,
        loader: false,
      };

    // Create Trigger

    case actions.FETCH_CREATE_TRIGGER_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_CREATE_TRIGGER_SUCCESS:
      return {
        ...state,
        loader: false,
      };

    case actions.FETCH_CREATE_TRIGGER_FAILURE:
      return {
        ...state,
        loader: false,
      };

    // Update Trigger

    case actions.FETCH_UPDATE_TRIGGER_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_UPDATE_TRIGGER_SUCCESS:
      return {
        ...state,
        loader: false,
      };

    case actions.FETCH_UPDATE_TRIGGER_FAILURE:
      return {
        ...state,
        loader: false,
      };

    // Delete Link

    case actions.FETCH_DELETE_TRIGGER_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_DELETE_TRIGGER_SUCCESS:
      return {
        ...state,
        loader: false,
      };

    case actions.FETCH_DELETE_TRIGGER_FAILURE:
      return {
        ...state,
        loader: false,
      };

    // Get Triggers All

    case actions.FETCH_GET_TRIGGERS_ALL_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_GET_TRIGGERS_ALL_SUCCESS:
      return {
        ...state,
        data: [...action.payload.sort((d, l) => l.created_at - d.created_at)],
        loader: false,
      };

    case actions.FETCH_GET_TRIGGERS_ALL_FAILURE:
      return {
        ...state,
        loader: false,
      };

    // Get Trigger by Id

    case actions.FETCH_GET_TRIGGER_BY_ID_START:
      return {
        ...state,
        current: {
          ...state.current,
          loader: true,
        },
      };

    case actions.FETCH_GET_TRIGGER_BY_ID_SUCCESS:
      return {
        ...state,
        current: {
          data: {
            ...action.payload,
          },
          loader: false,
        },
      };

    case actions.FETCH_GET_TRIGGER_BY_ID_FAILURE:
      return {
        ...state,
        current: {
          ...initialState.current,
          loader: false,
        },
      };

    default:
      return state;
  }
};

export default triggersReducer;
