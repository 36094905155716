import React from 'react';
import {
  CornerDotType,
  CornerSquareType,
  DotType,
  Options,
  ShapeType,
} from 'qr-code-styling';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import ButtonGroup from '@components/UIkit/ButtonGroup';
import Separator from '@components/UIkit/Separator';
import styles from './shape.module.scss';

interface IShape {
  options: Options;
  setOptions: (options: Options) => void;
}

const { Text } = Typography;

const Shape: React.FC<IShape> = ({ options, setOptions }) => {
  return (
    <Space direction="vertical" gap={20} className={styles.colors} fullWidth>
      <Space direction="vertical" gap={20}>
        <Space align="middle" justify="between">
          <Text size={2}>Shape</Text>
        </Space>

        <Space align="middle" justify="between" wrapped gap={10}>
          <ButtonGroup
            id="button-group-generator-s-type"
            wrapped
            buttons={[
              { text: 'Square', value: 'square' },
              { text: 'Circle', value: 'circle' },
            ]}
            value={options.shape || 'square'}
            onChange={(value) =>
              setOptions({
                ...options,
                shape: value as ShapeType,
              })
            }
          />
        </Space>
      </Space>
      <Separator />
      <Space direction="vertical" gap={20}>
        <Space align="middle" justify="between">
          <Text size={2}>Dots Style</Text>
        </Space>

        <Space align="middle" justify="between" wrapped gap={10}>
          <ButtonGroup
            id="button-group-generator-ds-type"
            wrapped
            buttons={[
              { text: 'Square', value: 'square' },
              { text: 'Dots', value: 'dots' },
              { text: 'Classy', value: 'classy' },
              { text: 'Classy-rounded', value: 'classy-rounded' },
              { text: 'Rounded', value: 'rounded' },
              { text: 'Extra-rounded', value: 'extra-rounded' },
            ]}
            value={options.dotsOptions?.type || 'square'}
            onChange={(value) =>
              setOptions({
                ...options,
                dotsOptions: {
                  ...options.dotsOptions,
                  type: value as DotType,
                },
              })
            }
          />
        </Space>
      </Space>
      <Separator />
      <Space direction="vertical" gap={20}>
        <Space align="middle" justify="between">
          <Text size={2}>Corners Square Style</Text>
        </Space>

        <Space align="middle" justify="between" wrapped gap={10}>
          <ButtonGroup
            id="button-group-generator-cs-type"
            wrapped
            buttons={[
              { text: 'Square', value: 'square' },
              { text: 'Dot', value: 'dot' },
              { text: 'Extra-rounded', value: 'extra-rounded' },
            ]}
            value={options.cornersSquareOptions?.type || 'square'}
            onChange={(value) =>
              setOptions({
                ...options,
                cornersSquareOptions: {
                  ...options.cornersSquareOptions,
                  type: value as CornerSquareType,
                },
              })
            }
          />
        </Space>
      </Space>
      <Separator />
      <Space direction="vertical" gap={20}>
        <Space align="middle" justify="between">
          <Text size={2}>Corners Dot Style</Text>
        </Space>

        <Space align="middle" justify="between" wrapped gap={10}>
          <ButtonGroup
            id="button-group-generator-cd-type"
            wrapped
            buttons={[
              { text: 'None', value: undefined },
              { text: 'Dot', value: 'dot' },
              { text: 'Square', value: 'square' },
            ]}
            value={options.cornersDotOptions?.type || undefined}
            onChange={(value) =>
              setOptions({
                ...options,
                cornersDotOptions: {
                  ...options.cornersDotOptions,
                  type: value as CornerDotType,
                },
              })
            }
          />
        </Space>
      </Space>
    </Space>
  );
};

export default React.memo(Shape);
