import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { IPlan } from '@common/types';
import Button from '@components/UIkit/Button';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import List from '@components/UIkit/List';
import styles from './plan.module.scss';

interface IPlanProps {
  data: IPlan;
  onPlanSelect: () => void;
}

const { Paragraph, Text } = Typography;

const Plan: React.FC<IPlanProps> = ({ data, onPlanSelect }) => {
  const [active, setActive] = useState(false);
  const childRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = () => {
    setActive(true);
  };

  const handleMouseLeave = () => {
    setActive(false);
  };

  useEffect(() => {
    const element = childRef.current;

    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  return (
    <Space
      className={classNames(styles.plan, {
        [styles.active]: active,
      })}
      ref={childRef}
      direction="vertical"
    >
      <Space direction="vertical" gap={20} fullHeight>
        <Text size={2}>{data.name}</Text>

        <Space align="start" gap={10} className={styles.price}>
          <Paragraph size={2} className={styles.currency}>
            $
          </Paragraph>
          <Text font="Inter">{data.price}</Text>
        </Space>

        <Text size={2} className={styles.description}>
          {data.description}
        </Text>

        <List items={data.props} icon="Eclipse" iconColor="#BDBDBD" />
      </Space>

      <Button
        id={`button-plan-${data.name.toLowerCase()}-select`}
        type="default"
        onClick={onPlanSelect}
        fullWidth
        active={active}
      >
        {data.action}
      </Button>
    </Space>
  );
};

export default Plan;
