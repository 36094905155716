import React from 'react';
import MurlSuccess from '@assets/images/RedirectPages/MurlSuccess.svg';
import MurlFailure from '@assets/images/RedirectPages/MurlFailure.svg';
import styles from './logobounce.module.scss';

interface ILogoBounce {
  type?: 'success' | 'failure';
}

const LogoBounce: React.FC<ILogoBounce> = ({ type = 'success' }) => {
  return (
    <div className={styles.image}>
      {type === 'success' ? (
        <img src={MurlSuccess} alt="Success" />
      ) : (
        <img src={MurlFailure} alt="Failure" />
      )}
    </div>
  );
};

export default LogoBounce;
