import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Data from '@containers/Dashboard/components/Data';
import { fetchAdminGetTriggerAllStatsStart } from '@core/admin/actionCreators';
import configSelector from '@core/configSelector';
import Space from '@components/UIkit/Space';
import DateRangePicker from '@components/UIkit/DatePicker';
import Loader from '@components/Loader';
import styles from '../admin.module.scss';

const Interactions = () => {
  const dispatch = useDispatch();

  const { loader, interactions } = useSelector(configSelector.admin);
  const [dates, setDates] = useState({
    fromDate: 0,
    toDate: 0,
  });

  useEffect(() => {
    if (dates.fromDate && dates.toDate)
      dispatch(
        fetchAdminGetTriggerAllStatsStart({
          fromDate: dates.fromDate,
          toDate: dates.toDate,
        })
      );
  }, [dates]);

  return (
    <>
      <DateRangePicker id="input-interactions-date-range" onChange={setDates} />
      {!loader && interactions.data ? (
        <Space direction="vertical" gap={20} fullWidth>
          <Data
            className={styles.charts}
            interactionData={interactions.data}
            isQRCode={true}
          />
        </Space>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default React.memo(Interactions);
