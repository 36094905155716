import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { IMenuAction } from '@common/types';
import Menu from '../Menu';
import Space from '../Space';
import Icon from '../Icon';
import styles from './select.module.scss';

interface ISelect {
  id: string;
  className?: string;
  onChange: (value: string | number) => void;
  values: IMenuAction[];
  defaultValue?: string | number;
}

const Select: React.FC<ISelect> = ({
  id,
  className,
  onChange,
  values,
  defaultValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(defaultValue || values[0].value);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      onChange(value);
    }
  }, [onChange, value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const items: IMenuAction[] = values.map((v) => {
    return { ...v, onClick: () => setValue(v.value || '') };
  });

  return (
    <Space
      className={classNames(styles.select, className)}
      onClick={() => setIsOpen(!isOpen)}
      ref={wrapperRef}
    >
      <div className={styles.value} id={id}>
        {items.find((v) => v.value === value)?.text || defaultValue}

        <Icon name="ArrowDown" color="#000000" />
      </div>

      <Menu isOpen={isOpen} setIsOpen={setIsOpen} items={items} />
    </Space>
  );
};

export default Select;
