import * as actions from './actionTypes';
import { SubscribeRequest } from './service';

export function fetchSubscribeStart(
  payload: SubscribeRequest,
  onSuccess: () => void
): actions.FetchSubscribeStart {
  return {
    type: actions.FETCH_SUBSCRIBE_START,
    payload,
    onSuccess,
  };
}

export function fetchSubsrcibeSuccess(): actions.FetchSubscribeSuccess {
  return {
    type: actions.FETCH_SUBSCRIBE_SUCCESS,
  };
}

export function fetchSubscribeFailure(): actions.FetchSubscribeFailure {
  return {
    type: actions.FETCH_SUBSCRIBE_FAILURE,
  };
}
