import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ModalProvider } from '@hooks/useModal';
import { store, persistor } from '@core/store';
import { isProd } from '@common/constants';
import App from './App';

Sentry.init({
  environment: isProd ? 'production' : 'develop',
  dsn: 'https://a5b880c2bbde5c140612641e7b3e6d0d@o4507690921492480.ingest.de.sentry.io/4507691356782672',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/(dev-)?api\.murl\.gg/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const app = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

app.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <ModalProvider>
          <App />
        </ModalProvider>
      </Router>
    </PersistGate>

    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="colored"
    />
  </Provider>
);
