import React from 'react';
import classNames from 'classnames';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import Typography from '@components/UIkit/Typography';
import styles from './test.module.scss';

const { Text } = Typography;

const TestSpace = () => {
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Space" />

      <Space className={styles.space} gap={20} direction="vertical">
        <Space className={classNames(styles.space, styles.container)} gap={20}>
          <Text size={2}>Horizontal</Text>
          <Text size={2}>Justify start</Text>
          <Text size={2}>Alignt start</Text>
        </Space>

        <Space
          className={classNames(styles.space, styles.container)}
          justify="middle"
          gap={20}
        >
          <Text size={2}>Horizontal</Text>
          <Text size={2}>Justify middle</Text>
          <Text size={2}>Alignt start</Text>
        </Space>

        <Space
          className={classNames(styles.space, styles.container)}
          justify="end"
          gap={20}
        >
          <Text size={2}>Horizontal</Text>
          <Text size={2}>Justify end</Text>
          <Text size={2}>Alignt start</Text>
        </Space>

        <Space
          className={classNames(styles.space, styles.container)}
          justify="around"
          gap={20}
        >
          <Text size={2}>Horizontal</Text>
          <Text size={2}>Justify around</Text>
          <Text size={2}>Alignt start</Text>
        </Space>

        <Space
          className={classNames(styles.space, styles.container)}
          justify="between"
          gap={20}
        >
          <Text size={2}>Horizontal</Text>
          <Text size={2}>Justify between</Text>
          <Text size={2}>Alignt start</Text>
        </Space>
      </Space>
    </Space>
  );
};

export default React.memo(TestSpace);
