import React from 'react';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import Statistic from '@components/UIkit/Statistic';
import styles from './test.module.scss';

const TestStatistic = () => {
  return (
    <Space className={styles.space} gap={20} direction="vertical">
      <Headline title="Statistic" />

      <Statistic icon="ArrowSubDown" title="Card title" value="Card value" />
    </Space>
  );
};

export default React.memo(TestStatistic);
