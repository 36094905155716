import React from 'react';
import Typography from '@components/UIkit/Typography';
import styles from './nodata.module.scss';

const { Text } = Typography;

const Nodata = () => {
  return (
    <div className={styles.nodata}>
      <Text size={2} className={styles.nodata_text}>
        Insufficient data to display
      </Text>
    </div>
  );
};

export default Nodata;
