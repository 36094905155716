import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import styles from './qrstylingitem.module.scss';

interface IQRStylingItem {
  isActive?: boolean;
  title: string;
  node: ReactNode;
}

const { Text } = Typography;

const QRStylingItem: React.FC<IQRStylingItem> = ({
  isActive = false,
  title,
  node,
}) => {
  const [active, setActive] = useState(isActive);

  const toggleActive = () => {
    setActive(!active);
  };

  return (
    <Space direction="vertical" className={styles.item} fullWidth>
      <Text size={2} className={styles.itemTitle} onClick={toggleActive}>
        {title}
        <div
          className={classNames(styles.action, { [styles.active]: active })}
        />
      </Text>

      <div className={classNames(styles.itemNode, { [styles.active]: active })}>
        <div className={styles.content}>{node}</div>
      </div>
    </Space>
  );
};

export default QRStylingItem;
