import React from 'react';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import Headline from '@components/Headline';
import Button from '@components/UIkit/Button';
import routing from '@routing/path';
import { useModal } from '@hooks/useModal';
import ScreenCapturePng from '@assets/images/common/screenCapture.png';
import styles from './modalauthorizationprompt.module.scss';

const { Paragraph } = Typography;

interface IModalAuthorizationPrompt {
  onNext: () => void;
}

const ModalAuthorizationPrompt: React.FC<IModalAuthorizationPrompt> = ({
  onNext,
}) => {
  const { closeModal } = useModal();

  return (
    <Space direction="vertical" gap={20}>
      <Paragraph>Would you like to sign in?</Paragraph>

      <Headline
        align="left"
        description="Sign in to access statistics on link clicks and QR code scans, and discover where your audience is coming from."
      />

      <div className={styles.ScreenCapture}>
        <img src={ScreenCapturePng} alt="Screen Capture" />
      </div>

      <Space gap={20} justify="start">
        <Button
          id="button-modal-auth-prompt-continue"
          onClick={onNext}
          type="default"
        >
          Continue as guest
        </Button>

        <Button
          id="button-modal-auth-prompt-sign-in"
          to={routing.public.signin}
          onClick={closeModal}
        >
          Sign In
        </Button>
      </Space>
    </Space>
  );
};

export default React.memo(ModalAuthorizationPrompt);
