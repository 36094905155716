import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useQRCode from '@components/QRCode';
import Icon from '@components/UIkit/Icon';
import { copyLinkToClipboard } from '@common/helpers';
import routing from '@routing/path';
import Space from '@components/UIkit/Space';
import Typography from '@components/UIkit/Typography';
import { encrypt } from '@core/encryptor';
import Button from '@components/UIkit/Button';
import { ICreateTriggerResponse, TTriggerType } from '@core/triggers/service';
import styles from '../../styles.module.scss';

interface ITriggerCreated {
  trigger_type: TTriggerType;
  data: ICreateTriggerResponse;
}

const { Paragraph } = Typography;

const TriggerCreated: React.FC<ITriggerCreated> = ({ trigger_type, data }) => {
  const navigate = useNavigate();

  const { QRCode } = useQRCode(data.payload.qr_code_data || '');

  const onDownload = useCallback(() => {
    navigate(
      `${routing.public.generator}/${encrypt(
        `${document.location.origin}/${data.payload.qr_code_data}` || ''
      )}`
    );
  }, [navigate, data.payload.qr_code_data]);

  return (
    <div>
      {trigger_type === 'QrCode' ? (
        <Space gap={20}>
          <div className={styles.qrCode}>{QRCode}</div>

          <Space direction="vertical" gap={20}>
            <Space direction="vertical" gap={10}>
              <Paragraph className={styles.qrCodeName}>No name</Paragraph>

              <Button
                id="button-created-trigger-download"
                onClick={onDownload}
                size="small"
              >
                Download
              </Button>
            </Space>
          </Space>
        </Space>
      ) : (
        <Space className={styles.result} gap={20} align="middle">
          <Icon size="small" name="ArrowSubDown" />

          <Paragraph size={2} className={styles.link}>
            {`${document.location.origin}/${data.payload.short_link}`}
          </Paragraph>

          <Icon
            id="icon-created-trigger-copy"
            size="small"
            name="Copy"
            color="#00FF75"
            onClick={() =>
              copyLinkToClipboard(
                `${document.location.origin}/${data.payload.short_link}` || ''
              )
            }
          />
        </Space>
      )}
    </div>
  );
};

export default TriggerCreated;
