import React, { ReactNode, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import routing from '@routing/path';
import Typography from '@components/UIkit/Typography';
import Space from '@components/UIkit/Space';
import Headline from '@components/Headline';
import LogoBounce from '@components/LogoBounce';
import styles from './styles.module.scss';

const { Text } = Typography;

const RedirectSuccess: React.FC<{
  nodeAfterEnd?: ReactNode;
  linkToNavigate?: string;
}> = ({ nodeAfterEnd, linkToNavigate }) => {
  const [countdown, setCountdown] = useState<number>(5);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCount) => (prevCount > 0 ? prevCount - 1 : prevCount));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (countdown === 0 && linkToNavigate) {
      document.location.href = linkToNavigate;
    }
  }, [countdown, linkToNavigate]);

  return countdown ? (
    <Space
      direction="vertical"
      align="middle"
      justify="middle"
      gap={40}
      className={classNames(styles.redirect, styles.success)}
    >
      <LogoBounce />

      <Headline
        title="Powered by murl"
        description="King of Internet interactions. analytics. statistics."
      />

      <Text size={2}>
        You will be transferred to our site automatically in {countdown} seconds
      </Text>
    </Space>
  ) : (
    nodeAfterEnd
  );
};

const RedirectFailure: React.FC = () => {
  return (
    <Space
      direction="vertical"
      align="middle"
      justify="middle"
      gap={40}
      className={classNames(styles.redirect, styles.failure)}
    >
      <LogoBounce type="failure" />

      <Headline
        title="Powered by murl"
        description="This page has been deactivated for some reason"
      />

      <Text size={2}>
        If you are the owner of this page.{' '}
        <NavLink
          to={routing.public.home}
          className={styles.link}
          id="link-redirect-sign-in"
        >
          Sign In
        </NavLink>{' '}
        to fix it.
      </Text>
    </Space>
  );
};

const MemoizedRedirectSuccess = React.memo(RedirectSuccess);
const MemoizedRedirectFailure = React.memo(RedirectFailure);

export const Redirect = {
  RedirectSuccessPage: MemoizedRedirectSuccess,
  RedirectFailurePage: MemoizedRedirectFailure,
};
