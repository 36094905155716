import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import routing from '@routing/path';
import configSelector from '@configSelector';
import { IPlan, TNavItem, plansData } from '@common/types';
import Headline from '@components/Headline';
import EmptyData from '@components/EmptyData';
import Loader from '@components/Loader';
import { NavbarProvider, useNavbar } from '@hooks/useNavBar';
import { ITriggerInterface } from '@core/triggers/service';
import { fetchGetTriggersAllStart } from '@core/triggers/actionCreators';
import Container from '@components/UIkit/Container';
import Space from '@components/UIkit/Space';
import Notify from '@components/UIkit/Notify';
import Button from '@components/UIkit/Button';
import Icon from '@components/UIkit/Icon';
import CollectionCard from '../../components/CollectionCard';

interface CollectionContainerProps {
  isQrCode: boolean;
}
interface CollectionWrapperProps {
  isQrCode: boolean;
  data: ITriggerInterface[];
  loader: boolean;
}

const CollectionContainer: React.FC<CollectionContainerProps> = ({
  isQrCode,
}) => {
  const dispatch = useDispatch();
  const isAuth = useSelector(configSelector.isAuth);

  const navItems: TNavItem[] = [
    {
      icon: 'Link',
      text: 'Short link',
      to: !isAuth
        ? `${routing.public.create.root}/${routing.public.create.children.link}`
        : `${routing.private.collection.root}/${routing.private.collection.children.link}`,
    },
    {
      icon: 'QRCode',
      text: 'QR Code',
      to: !isAuth
        ? `${routing.public.create.root}/${routing.public.create.children.qrCode}`
        : `${routing.private.collection.root}/${routing.private.collection.children.qrCode}`,
    },
  ];

  const { data, loader } = useSelector(configSelector.triggers);

  useEffect(() => {
    dispatch(fetchGetTriggersAllStart());
  }, [isQrCode]);

  return (
    <NavbarProvider
      id="navbar-collection"
      items={navItems}
      suffix={
        <>
          <Button
            id="button-collection-create-new"
            hiddenOnMobile
            to={`${routing.public.create.root}/${
              isQrCode
                ? routing.public.create.children.qrCode
                : routing.public.create.children.link
            }`}
          >
            Create new
          </Button>

          <Button
            id="button-collection-create-new"
            size="small"
            hiddenOnDesktop
            to={`${routing.public.create.root}/${
              isQrCode
                ? routing.public.create.children.qrCode
                : routing.public.create.children.link
            }`}
            icon={<Icon name="Plus" color="#000000" />}
          />
        </>
      }
    >
      <CollectionWrapper isQrCode={isQrCode} data={data} loader={loader} />
    </NavbarProvider>
  );
};

const CollectionWrapper: React.FC<CollectionWrapperProps> = ({
  isQrCode,
  data,
  loader,
}) => {
  const sortedData = data.filter((d) =>
    isQrCode ? d.type === 'qr-code' : d.type === 'short-url'
  );
  const { NavBarNode } = useNavbar();
  const { tier } = useSelector(configSelector.mySubscription);
  const MyTriggersLimit = useSelector(configSelector.myTriggersLimit);
  const MyTier: IPlan | undefined = plansData.find((p) => p.id === tier);

  return (
    <Container>
      <Space direction="vertical" gap={40} fullWidth>
        <Space direction="vertical" gap={20} fullWidth>
          <Headline
            title={`My ${isQrCode ? 'QR codes' : 'links'}`}
            align="left"
          />

          <Space gap={20} fullWidth>
            <Notify text="Tier:" value={MyTier?.name} />
            <Notify
              text="Items:"
              value={`${MyTriggersLimit}/${MyTier?.triggers}`}
            />
          </Space>
        </Space>

        <Space direction="vertical" align="normal" fullWidth>
          {NavBarNode}

          <Space direction="vertical" gap={20} align="normal">
            {loader ? (
              <Loader />
            ) : !sortedData.length ? (
              <EmptyData />
            ) : (
              sortedData.map((d) => <CollectionCard key={d.id} data={d} />)
            )}
          </Space>
        </Space>
      </Space>
    </Container>
  );
};

export default React.memo(CollectionContainer);
