import * as actions from './actionTypes';
import { OAuthRequest, OAuthResponse } from './service';

export function fetchOAuthStart(
  payload: OAuthRequest
): actions.FetchOAuthStart {
  return {
    type: actions.FETCH_OAUTH_START,
    payload,
  };
}
export function fetchOAuthSuccess(
  payload: OAuthResponse
): actions.FetchOAuthSuccess {
  return {
    type: actions.FETCH_OAUTH_SUCCESS,
    payload,
  };
}

export function fetchOAuthFailure(): actions.FetchOAuthFailure {
  return {
    type: actions.FETCH_OAUTH_FAILURE,
  };
}
