import * as actions from './actionTypes';
import {
  AdminGetTriggerAllStatsRequest,
  AdminGetTriggerAllStatsResponse,
  AdminGetTriggerByIdRequest,
  AdminGetTriggerByIdResponse,
  AdminGetTriggersRequest,
  AdminGetTriggersResponse,
  AdminGetUsersRequest,
  AdminGetUsersResponse,
} from './service';

// Users

export function fetchAdminGetUsersStart(
  payload: AdminGetUsersRequest,
  is_first
): actions.FetchAdminGetUsersStart {
  return {
    type: actions.FETCH_ADMIN_GET_USERS_START,
    payload,
    is_first,
  };
}

export function fetchAdminGetUsersSuccess(
  payload: AdminGetUsersResponse,
  is_first: boolean
): actions.FetchAdminGetUsersSuccess {
  return {
    type: actions.FETCH_ADMIN_GET_USERS_SUCCESS,
    payload,
    is_first,
  };
}

export function fetchAdminGetUsersFailure(): actions.FetchAdminGetUsersFailure {
  return {
    type: actions.FETCH_ADMIN_GET_USERS_FAILURE,
  };
}

// Triggers

export function fetchAdminGetTriggersStart(
  payload: AdminGetTriggersRequest,
  is_first
): actions.FetchAdminGetTriggersStart {
  return {
    type: actions.FETCH_ADMIN_GET_TRIGGERS_START,
    payload,
    is_first,
  };
}

export function fetchAdminGetTriggersSuccess(
  payload: AdminGetTriggersResponse,
  is_first: boolean
): actions.FetchAdminGetTriggersSuccess {
  return {
    type: actions.FETCH_ADMIN_GET_TRIGGERS_SUCCESS,
    payload,
    is_first,
  };
}

export function fetchAdminGetTriggersFailure(): actions.FetchAdminGetTriggersFailure {
  return {
    type: actions.FETCH_ADMIN_GET_TRIGGERS_FAILURE,
  };
}

// Trigger by ID

export function fetchAdminGetTriggerByIdStart(
  payload: AdminGetTriggerByIdRequest
): actions.FetchAdminGetTriggerByIdStart {
  return {
    type: actions.FETCH_ADMIN_GET_TRIGGER_BY_ID_START,
    payload,
  };
}

export function fetchAdminGetTriggerByIdSuccess(
  payload: AdminGetTriggerByIdResponse
): actions.FetchAdminGetTriggerByIdSuccess {
  return {
    type: actions.FETCH_ADMIN_GET_TRIGGER_BY_ID_SUCCESS,
    payload,
  };
}

export function fetchAdminGetTriggerByIdFailure(): actions.FetchAdminGetTriggerByIdFailure {
  return {
    type: actions.FETCH_ADMIN_GET_TRIGGER_BY_ID_FAILURE,
  };
}

// Trigger all Stats

export function fetchAdminGetTriggerAllStatsStart(
  payload: AdminGetTriggerAllStatsRequest
): actions.FetchAdminGetTriggerAllStatsStart {
  return {
    type: actions.FETCH_ADMIN_GET_TRIGGER_ALL_STATS_START,
    payload,
  };
}

export function fetchAdminGetTriggerAllStatsSuccess(
  payload: AdminGetTriggerAllStatsResponse
): actions.FetchAdminGetTriggerAllStatsSuccess {
  return {
    type: actions.FETCH_ADMIN_GET_TRIGGER_ALL_STATS_SUCCESS,
    payload,
  };
}

export function fetchAdminGetTriggerAllStatsFailure(): actions.FetchAdminGetTriggerAllStatsFailure {
  return {
    type: actions.FETCH_ADMIN_GET_TRIGGER_ALL_STATS_FAILURE,
  };
}
