/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, ReactNode, forwardRef } from 'react';
import classNames from 'classnames';
import { IHiddenOn } from '@common/types';
import styles from './space.module.scss';

interface ISpace extends IHiddenOn {
  style?: CSSProperties;
  children: ReactNode;
  className?: string;
  onClick?: (...args: any[]) => void;
  direction?: 'horizontal' | 'vertical';
  align?: 'start' | 'end' | 'middle' | 'normal';
  justify?: 'start' | 'end' | 'middle' | 'between' | 'around' | 'normal';
  gap?: 0 | 5 | 10 | 20 | 40 | 60 | 80 | 100 | 120 | 140;
  wrapped?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const Space = forwardRef<HTMLDivElement, ISpace>(
  (
    {
      hiddenOnMobile,
      hiddenOnDesktop,
      style,
      children,
      className,
      onClick,
      direction = 'horizontal',
      align = 'start',
      justify = 'start',
      gap = 0,
      wrapped = false,
      fullWidth = false,
      fullHeight = false,
    },
    ref
  ) => {
    return (
      <div
        style={style}
        onClick={onClick}
        ref={ref}
        className={classNames(
          styles.space,
          styles[direction],
          styles[`align_${align}`],
          styles[`justify_${justify}`],
          styles[`gap_${gap}`],
          {
            [styles.hiddenOnMobile]: hiddenOnMobile,
            [styles.hiddenOnDesktop]: hiddenOnDesktop,
            [styles.wrapped]: wrapped,
            [styles.fullWidth]: fullWidth,
            [styles.fullHeight]: fullHeight,
            [styles.hasClick]: !!onClick,
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);

export default Space;
