import * as actions from './actionTypes';
import {
  AdminGetTriggerAllStatsResponse,
  AdminGetTriggerByIdResponse,
  AdminGetTriggersResponse,
  AdminGetUsersResponse,
} from './service';

export interface ReducerState {
  users: AdminGetUsersResponse;
  userCurrent: {
    data: null;
  };

  triggers: AdminGetTriggersResponse;
  triggerCurrent: AdminGetTriggerByIdResponse | null;

  interactions: {
    data: AdminGetTriggerAllStatsResponse | null;
  };

  payments: [];
  loader: boolean;
}

const initialState: ReducerState = {
  users: {
    items: [],
    has_next: false,
    total_count: 0,
  },

  userCurrent: {
    data: null,
  },

  triggers: {
    items: [],
    has_next: false,
    total_count: 0,
  },

  triggerCurrent: null,

  interactions: {
    data: null,
  },

  payments: [],
  loader: false,
};

const adminReducer = (
  state: ReducerState = initialState,
  action: actions.AdminAction
): ReducerState => {
  switch (action.type) {
    // Users
    case actions.FETCH_ADMIN_GET_USERS_START:
      return {
        ...state,
        users: {
          ...state.users,
          items: action.is_first ? [] : [...state.users.items],
        },
        loader: true,
      };

    case actions.FETCH_ADMIN_GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          items: [...state.users.items, ...action.payload.items],
          has_next: action.payload.has_next,
          total_count: action.payload.total_count,
        },
        loader: false,
      };

    case actions.FETCH_ADMIN_GET_USERS_FAILURE:
      return {
        ...initialState,
      };

    // Triggers

    case actions.FETCH_ADMIN_GET_TRIGGERS_START:
      return {
        ...state,
        triggers: {
          ...state.triggers,
          items: action.is_first ? [] : [...state.triggers.items],
        },
        loader: true,
      };

    case actions.FETCH_ADMIN_GET_TRIGGERS_SUCCESS:
      return {
        ...state,
        triggers: {
          items: [...state.triggers.items, ...action.payload.items],
          has_next: action.payload.has_next,
          total_count: action.payload.total_count,
        },
        loader: false,
      };

    case actions.FETCH_ADMIN_GET_TRIGGERS_FAILURE:
      return {
        ...initialState,
      };

    // Trigger by Id

    case actions.FETCH_ADMIN_GET_TRIGGER_BY_ID_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_ADMIN_GET_TRIGGER_BY_ID_SUCCESS:
      return {
        ...state,
        triggerCurrent: {
          ...action.payload,
        },
        loader: false,
      };

    case actions.FETCH_ADMIN_GET_TRIGGER_BY_ID_FAILURE:
      return {
        ...initialState,
      };

    // Trigger all Stats

    case actions.FETCH_ADMIN_GET_TRIGGER_ALL_STATS_START:
      return {
        ...state,
        loader: true,
      };

    case actions.FETCH_ADMIN_GET_TRIGGER_ALL_STATS_SUCCESS:
      return {
        ...state,
        interactions: {
          data: {
            ...action.payload,
          },
        },
        loader: false,
      };

    case actions.FETCH_ADMIN_GET_TRIGGER_ALL_STATS_FAILURE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default adminReducer;
