import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './grid.module.scss';

interface IGrid {
  items: ReactNode[];
  gap?: 0 | 5 | 10 | 20 | 40 | 60 | 80 | 100 | 120 | 140;
}

const Grid: React.FC<IGrid> = ({ items, gap = 0 }) => {
  return (
    <div className={classNames(styles.grid, styles[`gap_${gap}`])}>{items}</div>
  );
};

export default Grid;
