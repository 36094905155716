import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { isProd } from '@common/constants';
import { store } from './store';
import { fetchOAuthFailure } from './auth/actionCreators';

export const getMurlEntryPoint = () => {
  return isProd
    ? process.env.REACT_APP_AXIOS_BASE_URL
    : process.env.REACT_APP_AXIOS_BASE_DEV_URL;
};

const instance: AxiosInstance = axios.create({
  withCredentials: false,
  baseURL: getMurlEntryPoint(),
  timeout: 5000,
});

instance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch(fetchOAuthFailure());
        return Promise.reject(error.response.data);
      }

      console.error('Server Error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      console.error('Request Error:', error.request);
      return Promise.reject('Request Error');
    } else {
      console.error('Error:', error.message);
      return Promise.reject(error.message);
    }
  }
);

export default instance;
