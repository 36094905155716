import React from 'react';
import Space from '@UIkit/Space';
import Button from '@UIkit/Button';

interface IButtonGroup {
  id: string;
  wrapped?: boolean;
  value: string | undefined;
  buttons: { text: string; value: string | undefined }[];
  onChange: (value: string | undefined) => void;
}

const ButtonGroup: React.FC<IButtonGroup> = ({
  id,
  wrapped,
  value,
  buttons,
  onChange,
}) => {
  return (
    <Space gap={10} wrapped={wrapped}>
      {buttons.map((b) => (
        <Button
          id={`${id}-${b.value}`}
          size="small"
          type="default"
          onClick={() => onChange(b.value)}
          active={b.value === value}
        >
          {b.text}
        </Button>
      ))}
    </Space>
  );
};

export default ButtonGroup;
