import React, { useEffect, useRef, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ITriggerCommonRequest } from '@core/triggers/service';
import Space from '@components/UIkit/Space';
import Menu from '@components/UIkit/Menu';
import Button from '@components/UIkit/Button';
import LinkInBio from '@components/LinkInBio';
import PhoneWrapper from '@components/PhoneWrapper';
import Icon from '@components/UIkit/Icon';
import CustomField from './CustomField';
import styles from '../../styles.module.scss';

const FormLinkInBio: React.FC = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { setFieldValue } = useFormikContext<ITriggerCommonRequest>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      setFieldValue('payload.layout', undefined);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        menuRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onDragEnd = (result, provided, form) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(form.values.payload.layout);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFieldValue('payload.layout', items);
  };

  return (
    <FieldArray name="payload.layout">
      {({ push, remove, form }) => (
        <DragDropContext
          onDragEnd={(result, provided) => onDragEnd(result, provided, form)}
        >
          <Space
            gap={40}
            fullWidth
            align="start"
            className={styles.link_in_bio_form}
          >
            <Droppable droppableId="droppable" className={styles.droppable}>
              {(provided) => (
                <div
                  className={styles.droppable}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {form.values.payload.layout &&
                    form.values.payload.layout.map((field, index) => (
                      <Draggable
                        key={index}
                        draggableId={`item-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <CustomField
                              key={index}
                              field={field}
                              index={index}
                              onRemove={() => remove(index)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}

                  <Button
                    id="button-link-in-bio-add-component"
                    className={styles.relative}
                    onClick={() => setMenuIsOpen(!menuIsOpen)}
                    forwardRef={buttonRef}
                  >
                    <Icon name="Move" color="#000000" />
                    Add component
                    <Menu
                      isOpen={menuIsOpen}
                      setIsOpen={setMenuIsOpen}
                      items={[
                        {
                          key: 1,
                          text: 'Image',
                          onClick: () => push({ type: 'image', image_url: '' }),
                        },
                        {
                          key: 2,
                          text: 'Header',
                          onClick: () => push({ type: 'header', text: '' }),
                        },
                        {
                          key: 3,
                          text: 'Text',
                          onClick: () => push({ type: 'text', text: '' }),
                        },
                        {
                          key: 4,
                          text: 'Link Button',
                          onClick: () =>
                            push({
                              type: 'link-button',
                              text: '',
                              url: '',
                              image_url: '',
                            }),
                        },
                      ]}
                      forwardRef={menuRef}
                    />
                  </Button>
                </div>
              )}
            </Droppable>

            <Space direction="vertical" gap={20}>
              <PhoneWrapper>
                <LinkInBio
                  layout={form.values.payload.layout}
                  className={styles.preview}
                />
              </PhoneWrapper>
            </Space>
          </Space>
        </DragDropContext>
      )}
    </FieldArray>
  );
};

export default FormLinkInBio;
