import axios, { AxiosInstance } from 'axios';
import instance from '@core/api';
import { ITriggerCommonPayload, TScenarioType } from '@core/triggers/service';

// Get IP

export interface IGetIPResponse {
  ip_address: string;
  sign_time: number;
  signature: string;
}

export async function fetchGetIP(): Promise<IGetIPResponse> {
  const instance: AxiosInstance = axios.create({
    withCredentials: false,
    baseURL: 'https://sign-ip-o3x4332eqq-lm.a.run.app',
    timeout: 5000,
  });

  return await instance.get('/');
}

// Call trigger

export interface ICallTriggerResponse {
  type: TScenarioType;
  payload: ITriggerCommonPayload;
}

export interface ICallTriggerRequest {
  key: string;
  device_info: {
    browser: string;
    os_name: string;
    osVersion: string;
    browserVersion: string;
    isMobile: string;
    fingerprint: string;
    currentResolution: string;
  };
  ip_info?: IGetIPResponse;
}

export async function fetchCallTrigger(
  request: ICallTriggerRequest
): Promise<ICallTriggerResponse> {
  return await instance.post(`trigger/call/${request.key}`, {
    ...request.device_info,
    ip_info: {
      ...request.ip_info,
    },
  });
}
